import { useEffect, useState } from 'react';
import { CardListItem, RfidCardListController } from 'fragments/rfidcards/fragments/rfid-card-list/interfaces';
import { RfidCardListDto } from 'services/rfidcards/rfidcards.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import { PaginationRequest, Sort } from 'global/interfaces';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDowFunctions } from 'tools/dow-functions';
import { OrderOption } from 'components/list-header-search-and-sort/list-header-search-and-sort.component';
import { useLockAdministrationContext } from 'fragments/locks/fragments/context/lock-administration.context';
import { onAddCardButtonPressed, onViewCardDetail } from 'mobile/rfid/rfid-functions';

let searchStr = '';

export const useRfidCardListController = (
    rfidCardService = useAPIRfidcard(),
): /* <--Dependency Injections  like services hooks */
RfidCardListController => {
    const { translate } = useTranslator();
    const { lockId: ctxLockId, accessToken: ctxAccessToken } = useLockAdministrationContext();
    const { dowChallenge } = useDowFunctions();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    /* State */
    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);

    const [rfidCards, setRfidCards] = useState<RfidCardListDto[]>([]);
    const [totalRfidCards, setTotalRfidCards] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>({ orderBy: 'date', order: 'descend' });
    const [sortFocused, setSortFocused] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const reactNavigation = location.pathname === '/mobile/locks/administration';
    const accessToken = !reactNavigation ? searchParams.get('access_token') : ctxAccessToken;
    const deviceId = !reactNavigation ? searchParams.get('device_id')?.toLowerCase() : ctxLockId;
    const [isFullSize, setIsFullSize] = useState<boolean>(!reactNavigation);

    const orderOptions: OrderOption[] = [
        {
            id: 0,
            value: translate({ key: 'general.later' }),
            sort: {
                order: 'ascend',
                orderBy: 'date',
            },
        },
        {
            id: 1,
            value: translate({ key: 'general.A-Z' }),
            sort: {
                order: 'ascend',
                orderBy: 'label',
            },
        },
        {
            id: 2,
            value: translate({ key: 'general.Z-A' }),
            sort: {
                order: 'descend',
                orderBy: 'label',
            },
        },
    ];

    /* Listeners */
    useEffect(() => {
        if (accessToken && deviceId) {
            getRfidCardsList(accessToken, deviceId, { page: 0, pageSize }, sort, search);
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, deviceId]);

    /* View Events */
    const onSortSelect = (sortSelected: Sort) => {
        if (!accessToken || !deviceId) return;

        setSort(sortSelected);
        getRfidCardsList(accessToken, deviceId, { page: 0, pageSize }, sortSelected, search);
    };

    const onSearch = (value: string) => {
        if (!accessToken || !deviceId) return;

        setSearch(value);
        searchStr = value;
        getRfidCardsList(accessToken, deviceId, { page: 0, pageSize }, sort, value);
    };

    const giveSortFocus = (value: boolean) => {
        setSortFocused(value);
    };

    const changeLoadingList = (value: boolean) => {
        setIsLoadingList(value);
    };

    const onGenerateCardButtonPressed = () => {
        if (reactNavigation) {
            navigate(`/mobile/rfid-cards/creation/introduction?device_id=${deviceId}&access_token=${accessToken}`);
        } else {
            onAddCardButtonPressed({ v: 3, data: { device_id: deviceId || '' } });
        }
    };

    const onViewRfidCardDetail = (id: string) => {
        if (!sortFocused) {
            if (reactNavigation) {
                navigate(`/mobile/rfid-cards/detail`, {
                    state: {
                        accessToken,
                        lock_id: deviceId,
                        rfid_card_id: id,
                    },
                });
            } else {
                onViewCardDetail({ v: 3, data: { card_id: id } });
            }
        }
    };

    const loadMoreData = () => {
        if (isLoadingList) {
            return;
        }
        if (accessToken && deviceId) {
            getRfidCardsList(
                accessToken,
                deviceId,
                { page: currentPage, pageSize },
                {
                    order: sort.order,
                    orderBy: sort.orderBy,
                },
                search,
            );
        }
    };

    /* Private Methods */
    const getRfidCardsList = (
        accessToken: string,
        deviceId: string,
        pagination: PaginationRequest,
        sort: Sort,
        srch: string,
    ) => {
        setIsLoadingList(true);
        rfidCardService
            .listRfidCards({ accessToken, pagination, sort, search: { deviceId, input: srch } })
            .then((data) => {
                if (data.search === searchStr) {
                    if (pagination.page === 0) {
                        setCurrentPage(1);
                        setRfidCards(data.rfid_cards);
                        setTotalRfidCards(data.total);
                    } else {
                        const newData = rfidCards.concat(data.rfid_cards);
                        setRfidCards(newData);
                        setCurrentPage(currentPage + 1);
                        setTotalRfidCards(data.total);
                    }
                    setIsLoadingList(false);
                }
            })
            .catch((error) => {
                // messenger.showErrorMessage({ key: 'passcodes.list-error' });
                console.log('rfid-cards-list-error', error);
            })
            .finally(() => {
                setIsLoadingList(false);
            });
    };

    const getLabelAndColorByState = (state: number): { id: number; label: string; color: string } => {
        switch (state) {
            case 1:
                return {
                    id: 1,
                    label: translate({ key: 'rfid-card.label.pending' }),
                    color: 'rgba(147, 94, 224, 0.6)',
                };
            case 2:
                return { id: 2, label: translate({ key: 'rfid-card.label.valid' }), color: 'rgba(109, 206, 201, 0.8)' };
            case 3:
                return { id: 3, label: translate({ key: 'rfid-card.label.deleted' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 4:
                return {
                    id: 4,
                    label: translate({ key: 'rfid-card.label.overwritten' }),
                    color: 'rgba(242, 61, 79, 0.6)',
                };
            case 5:
                return { id: 5, label: translate({ key: 'rfid-card.label.expired' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 6:
                return {
                    id: 6,
                    label: translate({ key: 'rfid-card.label.deleting' }),
                    color: 'rgba(147, 94, 224, 0.6)',
                };

            default:
                return { id: 0, label: 'invalid', color: 'rgba(242, 61, 79, 0.6)' };
        }
    };

    const mapDtoToListItem = (dto: RfidCardListDto): CardListItem => {
        const viewModelObject = {
            id: dto.id,
            state: getLabelAndColorByState(dto.state),
            label: capitalize(dto.label),
            typeId: dto.type_id,
            deviceId: dto.device_id,
            validity: {
                daysTag: dowChallenge(dto.dow),
                from: dayjs.utc(dto.nbf_date).format('DD/MM/AAAA'),
                to: dayjs.utc(dto.expires_at_date).format('DD/MM/AAAA'),
                fromHour: dayjs.utc(dto.nbf_time).format('HH:mm'),
                toHour: dayjs.utc(dto.expires_at_time).format('HH:mm'),
                timeZone: dto.time_zone,
            },
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        isLoadingList,
        rfidCardList: rfidCards.map(mapDtoToListItem),
        totalRfidCards,
        orderOptions,
        isFullSize,
        onSearch,
        onSortSelect,
        onGenerateCardButtonPressed,
        onViewRfidCardDetail,
        giveSortFocus,
        changeLoadingList,
        loadMoreData,
    };
};
