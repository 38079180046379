import { RfidCardCreationResultInfoController } from 'fragments/rfidcards/fragments/rfid-card-creation-result-info/interfaces';
import { onConfirmCardRetry } from 'mobile/rfid/rfid-functions';

export const useRfidCardCreationResultInfoController = (): /* <--Dependency Injections  like services hooks */
RfidCardCreationResultInfoController => {
    /* State */

    /* Listeners */

    /* View Events */

    const onRetryButtonPressed = () => {
        onConfirmCardRetry({ v: 3, data: {} });
    };

    /* Private Methods */

    // Return state and events
    return { onRetryButtonPressed };
};
