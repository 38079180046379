import React, { useState } from 'react';
import 'components/page-update-app/page-update-app.scss';
import { Button, Result } from 'antd';
import UpdateAppSvg from 'assets/svg/update-app-illustration-svg';

const PageUpdateApp: React.FC = () => {
    // State
    return (
        <div className={'page-update-app'}>
            <Result
                icon={<UpdateAppSvg style={{ width: '100%' }} />}
                title="Tu aplicacion esta desactualizada"
                subTitle="Actualiza la app para usar este modulo."
                // extra={
                //     <a href="https://play.google.com/store/apps/details?id=io.accefy.accefy">
                //         <Button type="primary">Actualizar</Button>
                //     </a>
                // }
            />
        </div>
    );
};

export default PageUpdateApp;
