import { MobileDataInterface } from 'mobile';

export const onViewKeyDetailFromList = (object: MobileDataInterface<{ key_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onViewKeyDetailFromList.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onViewKeyDetailFromList(JSON.stringify(object));
};

export const onGenerateKeyFromList = (object: MobileDataInterface<{ lock_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onGenerateKeyFromList.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onGenerateKeyFromList(JSON.stringify(object));
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const onDeleteSmarttokenrecord = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onDeleteSmarttokenrecord.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onDeleteSmarttokenrecord(JSON.stringify(object));
};
