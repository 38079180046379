import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-creation-introduction/rfid-card-creation-introduction.scss';
import { RfidCardCreationIntroductionFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-creation-introduction/interfaces';
import { useRfidCardCreationIntroductionController } from 'fragments/rfidcards/fragments/rfid-card-creation-introduction/rfid-card-creation-introduction.controller';
import { WifiOutlined, LockOutlined } from '@ant-design/icons';
import { Button, List, Result, Typography } from 'antd';
import RfidIcon from 'assets/icons/rfid-icon';
import BluetoothIcon from 'assets/icons/bluetooth-icon';
import InfoIcon from 'assets/icons/info-icon';
import { start } from 'repl';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;

export const RfidCardCreationIntroductionFragment: React.FC<RfidCardCreationIntroductionFragmentProps> = (props) => {
    const { useController = useRfidCardCreationIntroductionController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'rfid-card-creation-introduction'}>
            <Result
                icon={<InfoIcon style={{ width: '80px', height: '80px' }} />}
                title={
                    <Title className={'rfid-card-creation-introduction-title'}>
                        {translate({ key: 'rfid-card.introduction-title' })}
                    </Title>
                }
                subTitle={
                    <Text className={'rfid-card-creation-introduction-text'}>
                        {translate({ key: 'rfid-card.introduction-subtitle' })}
                    </Text>
                }
                extra={
                    <>
                        <List style={{ textAlign: 'left' }}>
                            <List.Item.Meta
                                avatar={<WifiOutlined className={'rfid-card-creation-introduction-icon'} />}
                                title={
                                    <Text className={'rfid-card-creation-introduction-text'}>
                                        {translate({ key: 'rfid-card.introduction-list-item-1' })}
                                    </Text>
                                }
                            />
                            <List.Item.Meta
                                avatar={<BluetoothIcon className={'rfid-card-creation-introduction-icon'} />}
                                title={
                                    <Text className={'rfid-card-creation-introduction-text'}>
                                        {translate({ key: 'rfid-card.introduction-list-item-2' })}
                                    </Text>
                                }
                            />
                            <List.Item.Meta
                                avatar={<RfidIcon className={'rfid-card-creation-introduction-icon'} />}
                                title={
                                    <Text className={'rfid-card-creation-introduction-text'}>
                                        {translate({ key: 'rfid-card.introduction-list-item-3' })}
                                    </Text>
                                }
                            />
                            <List.Item.Meta
                                avatar={<LockOutlined className={'rfid-card-creation-introduction-icon'} />}
                                title={
                                    <Text className={'rfid-card-creation-introduction-text'}>
                                        {translate({ key: 'rfid-card.introduction-list-item-4' })}
                                    </Text>
                                }
                            />
                        </List>
                        <Button
                            className={'rfid-card-creation-introduction-btn'}
                            type="default"
                            onClick={controller.onAgreePressed}
                        >
                            {translate({ key: 'general.agreed' })}
                        </Button>
                    </>
                }
            />
        </div>
    );
};
