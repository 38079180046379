import React, { useState } from 'react';
import 'components/page-invalid-session/page-invalid-session.scss';
import { Button, Result } from 'antd';
import { onInvalidSession } from 'mobile/common-functions';

// export interface PageInvalidSessionProps {}

const PageInvalidSession: React.FC = () => {
    // State
    const onButtonPressed = () => {
        onInvalidSession({ v: 1, data: {} });
    };
    return (
        <div className={'page-invalid-session'}>
            <Result
                status="403"
                title="Tu sesion ya no es valida."
                subTitle="Inicia sesion nuevamente para poder utilizar la aplicacion."
                extra={
                    <Button type="primary" onClick={onButtonPressed}>
                        Iniciar Sesion
                    </Button>
                }
            />
        </div>
    );
};

export default PageInvalidSession;
