import { useEffect, useState } from 'react';
import { LockDtoSimple, LockList, LockListController } from 'fragments/sites/fragments/lock-list/interfaces';
import { useNavigate } from 'react-router-dom';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDtoMinimal } from 'services/locks/locks.service';
import { useSitesContext } from 'fragments/sites/context/sites.context';
import { onOpenLockAdministration } from 'mobile/locks/locks-functions';

const emptyList = {
    list: [],
    totalItems: 0,
    pageSize: 15,
    page: 0,
};

export const useLockListController = (lockService = useAPILocks()): LockListController => {
    const navigate = useNavigate();
    const { selectedSite, accessToken } = useSitesContext();

    /* State */
    const [isLoadingLocks, setIsLoadingLocks] = useState<boolean>(false);
    const [locks, setLocks] = useState<LockList>(emptyList);

    const siteId = selectedSite.id;
    const siteName = selectedSite.name;

    /* Listeners */
    useEffect(() => {
        if (accessToken && siteId) {
            loadMoreData();
        } else {
            navigate('/mobile/404');
        }
    }, []);

    /* View Events */
    const loadMoreData = () => {
        if (isLoadingLocks) return;
        if (accessToken && siteId) {
            getLocksList(accessToken, siteId);
        }
    };

    const onLockItemPressed = (id: string) => {
        onOpenLockAdministration({ v: 1, data: { lock_id: id } });
    };

    /* Private Methods */
    const getLocksList = (accessToken: string, siteId: string) => {
        setIsLoadingLocks(true);
        lockService
            .listLocksBySite(accessToken, siteId, { page: locks.page, pageSize: locks.pageSize })
            .then((data) => {
                const newLocks = data.locks.map(mapLockToResult);
                const newList = {
                    list: locks.page === 0 ? newLocks : [...locks.list, ...newLocks],
                    page: locks.page === 0 ? 1 : locks.page + 1,
                    totalItems: data.total,
                    pageSize: locks.pageSize,
                };
                setLocks(newList);
                setIsLoadingLocks(false);
            })
            .catch((error) => {
                console.log('lock-list-error', error);
            });
    };

    const mapLockToResult = (lock: LockDtoMinimal): LockDtoSimple => {
        return {
            lockName: lock.lock_name,
            siteName: lock.site_name,
            lockId: lock.id,
        };
    };

    // Return state and events
    return {
        locks,
        siteName,
        isLoadingLocks,
        onLockItemPressed,
        loadMoreData,
    };
};
