import { useEffect, useState } from 'react';
import {
    RfidCardCreatedData,
    RfidCardCreationResultSuccessController,
} from 'fragments/rfidcards/fragments/rfid-card-creation-result-success/interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KeyScheduleOption } from 'services/keys/keys.service';
import { onAddAnotherCard, onAddCardReady } from 'mobile/rfid/rfid-functions';
import { useDowFunctions } from 'tools/dow-functions';

export const useRfidCardCreationResultSuccessController = (): /* <--Dependency Injections  like services hooks */
RfidCardCreationResultSuccessController => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getKeySchedules } = useDowFunctions();

    /* State */
    const [keySchedules, setKeySchedules] = useState<KeyScheduleOption[]>([]);

    const lock_name = searchParams.get('lock_name') || '';
    const label = searchParams.get('label') || '';
    const nbf_date = searchParams.get('nbf_date') || '';
    const expires_at_date = searchParams.get('expires_at_date') || '';
    const cyclic_rfid_config_enabled = searchParams.get('cyclic_rfid_config_enabled') || '';
    const nbf_time = searchParams.get('nbf_time') || '';
    const expires_at_time = searchParams.get('expires_at_time') || '';
    const dow = searchParams.get('dow');

    const data: RfidCardCreatedData = {
        lock_name,
        label,
        nbf_date,
        expires_at_date,
        cyclic_rfid_config_enabled,
        nbf_time,
        expires_at_time,
        dow: dow ? Number(dow) : undefined,
    };

    /* Listeners */
    useEffect(() => {
        if (!Object.keys(data).length) {
            navigate('/mobile/404');
        }
        data.dow && setKeySchedules(getKeySchedules(data.dow));
    }, []);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onReadyButtonPressed = () => {
        onAddCardReady({ v: 3, data: {} });
    };

    const onAssociateAnotherPressed = () => {
        onAddAnotherCard({ v: 3, data: {} });
    };

    /* Private Methods */

    // Return state and events
    return { data, keySchedules, onReadyButtonPressed, onAssociateAnotherPressed };
};
