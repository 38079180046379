import { useState, useEffect } from 'react';
import { MobileKeyCreationController } from 'fragments/keys/fragments/mobile-key-creation/interfaces';
import { BtKeysDeviceDto, CreateKeyInput, KeyCreationResponse } from 'services/keys/keys.service';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useKeysCreationContext } from './context/keys-creation.context';

export const useMobileKeyCreationController = (
    keyService = useAPIKeys(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
MobileKeyCreationController => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { translate } = useTranslator();
    const [form] = useForm();
    const [isDatesVisible, setIsDatesVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dowBytewise, setDowBytewise] = useState<number>(127);
    const [selectedLock, setSelectedLock] = useState<BtKeysDeviceDto>();
    const [locks, setLocks] = useState<BtKeysDeviceDto[]>([]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({ from: '00:00', to: '23:59' });
    const [assigneeEmail, setAssigneeEmail] = useState<string>('');
    const [isLockListingLoading, setIsLockListingLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);

    const {
        isCreateKeySummaryModalVisible,
        setIsCreateKeySummaryModalVisible,
        setKeyCreationResponse,
        accessToken,
        setAccessToken,
    } = useKeysCreationContext();

    /* Listeners */
    useEffect(() => {
        let lock_id = searchParams.get('lock_id') || '';
        const access_token = searchParams.get('access_token') || '';
        setAccessToken(access_token);
        lock_id = lock_id.toLowerCase();
        if (!access_token) {
            navigate('/mobile/404');
        }

        setIsLockListingLoading(true);
        keyService
            .listDevicesCreateBtKeyView(access_token as string)
            .then((locksResponse: BtKeysDeviceDto[]) => {
                setLocks(locksResponse.filter((lock) => lock.id !== 'e9636d9c-a535-404d-a575-450b4e352d31' && lock));
                const lock = locksResponse.find((lock) => lock.id === lock_id);
                setSelectedLock(lock || locksResponse[0]);
                setIsReady(true);
            })
            .catch((e) => {
                if (e.status_code == 401) {
                    //TEST
                    console.log('need to refresh access_token');
                    return;
                }
                // messenger.showErrorMessage({ key: 'key.load-locks' });
            })
            .finally(() => {
                setIsLockListingLoading(false);
            });
    }, []);

    /* View Events */
    const onCancelButtonPressed = () => {
        setSelectedLock(undefined);
        setDowBytewise(127);
        setAssigneeEmail('');
        setDateRange({ from: '', to: '' });
        setTimeRange({ from: '00:00', to: '23:59' });
        form.resetFields();
    };

    const onCreateKeyFormSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        createKey();
    };

    const onClientEmailAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAssigneeEmail(e.target.value);
    };

    const onLockSelect = (value: string) => {
        const newSelectedLock: BtKeysDeviceDto | null = getLock(value);
        if (newSelectedLock != null) {
            setSelectedLock(newSelectedLock!);
        }
    };

    const onLockSearch = (value: string) => {
        console.log('lock search: ' + value);
    };

    /* Private Methods */
    const getLock = (id: string): BtKeysDeviceDto | null => {
        const localLocks: BtKeysDeviceDto[] = locks;
        for (let i = 0; i < localLocks.length; i++) {
            const localLock = localLocks[i];
            if (localLock.id == id) {
                return localLock;
            }
        }
        return null;
    };
    const createKey = () => {
        setIsLoading(true);
        const input: CreateKeyInput = {
            lock_id: selectedLock?.id || '',
            guest: {
                value: assigneeEmail,
                type_id: 1,
            },
            since_date: dateRange.from,
            until_date: dateRange.to,
            since_time: timeRange.from,
            until_time: timeRange.to,
            dow: dowBytewise,
        };
        keyService
            .createKey(accessToken, input)
            .then((r: KeyCreationResponse) => {
                setKeyCreationResponse(r);
                setSelectedLock(undefined);
                setDowBytewise(127);
                setAssigneeEmail('');
                setDateRange({ from: '', to: '' });
                setTimeRange({ from: '00:00', to: '23:59' });
                setIsCreateKeySummaryModalVisible(true);
            })
            .catch((errorMsg: ErrorDto) => {
                const status: number = errorMsg.response.data.code;
                switch (errorMsg.response.data.code) {
                    case 401:
                        messenger.showErrorMessage({ key: 'key.no-access-token' });
                        break;
                    case 409:
                        setKeyCreationResponse({
                            status,
                            data: `${errorMsg.response.data.msg} ${assigneeEmail}`,
                        });
                        setIsCreateKeySummaryModalVisible(true);
                        break;
                    default:
                        messenger.showErrorMessage({ key: 'key.create-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
                form.resetFields();
            });
    };

    // Return state and events
    return {
        isDatesVisible,
        isLockListingLoading,
        isLoading,
        dateRange,
        timeRange,
        locks,
        assigneeEmail,
        dowBytewise,
        selectedLock,
        form,
        isCreateKeySummaryModalVisible,
        isReady,
        setDateRange,
        setTimeRange,
        setDowBytewise,
        onCancelButtonPressed,
        onCreateKeyFormSubmit,
        onClientEmailAdd,
        onLockSelect,
        onLockSearch,
    };
};
