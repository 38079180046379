import React from 'react';
import 'fragments/tools/index-pages/index-pages.scss';
import { IndexPagesFragmentProps } from 'fragments/tools/index-pages/interfaces';
import { useIndexPagesController } from 'fragments/tools/index-pages/index-pages.controller';
import { Button, Input, List } from 'antd';
import CustomHeader from 'components/custom-header/custom-header.component';
const { Search } = Input;

export const IndexPagesFragment: React.FC<IndexPagesFragmentProps> = (props) => {
    const { useController = useIndexPagesController } = props;
    const controller = useController();

    // Render
    return (
        <>
            <CustomHeader>Indice de Web views</CustomHeader>
            <div className={'index-pages'}>
                <Search placeholder="Web View..." onChange={controller.onSearch} />
                <List
                    itemLayout="horizontal"
                    dataSource={controller.routes}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key="ir"
                                    onClick={() => {
                                        controller.onGoPressed(item.url);
                                    }}
                                >
                                    Ir
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta title={item.pageName} />
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
};
