import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-list/rfid-card-list.scss';
import { CardListItem, RfidCardListFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-list/interfaces';
import { useRfidCardListController } from 'fragments/rfidcards/fragments/rfid-card-list/rfid-card-list.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Divider, List, Skeleton, Tag, Tooltip, Typography } from 'antd';
import {
    PlusCircleOutlined,
    InfoCircleFilled,
    CloseCircleFilled,
    CheckCircleFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import RfidIcon from 'assets/icons/rfid-icon';
import ListHeaderSearchAndSort from 'components/list-header-search-and-sort/list-header-search-and-sort.component';
import { useSearchParams } from 'react-router-dom';

const { Text } = Typography;

export const RfidCardListFragment: React.FC<RfidCardListFragmentProps> = (props) => {
    const { useController = useRfidCardListController } = props;
    const { translate } = useTranslator();
    const controller = useController();
    const [searchParams] = useSearchParams();

    const accessToken = searchParams.get('access_token') || '';

    // Render
    return (
        <div className={'rfid-card-list'} style={{ minHeight: controller.isFullSize ? '100vh' : undefined }}>
            <ListHeaderSearchAndSort
                onSearchChange={controller.onSearch}
                onSort={controller.onSortSelect}
                onLoaderSearch={controller.changeLoadingList}
                onAddButtonPressed={controller.onGenerateCardButtonPressed}
                giveSortFocus={controller.giveSortFocus}
                orderOptions={controller.orderOptions}
                icon={<PlusCircleOutlined />}
            />
            <div
                id="scrollableDiv"
                className={'rfid-card-list-scrollcontainer'}
                style={{
                    height: controller.isFullSize ? 'calc(100vh - 65px)' : 'calc(100vh - 193px)',
                }}
            >
                <InfiniteScroll
                    dataLength={controller.rfidCardList.length}
                    next={controller.loadMoreData}
                    hasMore={controller.rfidCardList.length < controller.totalRfidCards}
                    loader={<Skeleton paragraph={{ rows: 1 }} active />}
                    // endMessage={<Divider plain>Fin</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        loading={controller.isLoadingList}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={controller.rfidCardList}
                        renderItem={(item: CardListItem) => (
                            <List.Item
                                onClick={() => controller.onViewRfidCardDetail(item.id)}
                                actions={[
                                    <div key="status" style={{ display: 'flex', justifyContent: 'center' }}>
                                        {item.state.id === 3 || item.state.id === 4 || item.state.id === 5 ? (
                                            <CloseCircleFilled
                                                key="status"
                                                style={{
                                                    color: item.state.color,
                                                    fontSize: '25px',
                                                }}
                                            />
                                        ) : item.state.id === 2 ? (
                                            <CheckCircleFilled
                                                key="status"
                                                style={{
                                                    color: item.state.color,
                                                    fontSize: '25px',
                                                }}
                                            />
                                        ) : item.state.id === 6 ? (
                                            <LoadingOutlined
                                                key="status"
                                                style={{
                                                    color: item.state.color,
                                                    fontSize: '25px',
                                                }}
                                            />
                                        ) : (
                                            <InfoCircleFilled
                                                key="status"
                                                style={{
                                                    color: item.state.color,
                                                    fontSize: '25px',
                                                }}
                                            />
                                        )}
                                    </div>,
                                ]}
                            >
                                {/* <Skeleton title={false} loading={controller.isLoadingList} active> */}
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    avatar={
                                        <RfidIcon
                                            style={{
                                                marginLeft: '3px',
                                                width: '20px',
                                                height: '20px',
                                                color: '#888',
                                            }}
                                        />
                                    }
                                    title={
                                        <Text strong style={{ fontSize: '1rem' }}>
                                            {item.label}
                                        </Text>
                                    }
                                    description={
                                        <div style={{ width: 'fit-content' }}>
                                            {(() => {
                                                switch (item.typeId) {
                                                    case 1:
                                                        return (
                                                            <Tag className={'rfid-card-list-scrollcontainer-tag'}>
                                                                {translate({ key: 'general.full-time' })}
                                                            </Tag>
                                                        );
                                                    case 2:
                                                        return (
                                                            <>
                                                                <Tag className={'rfid-card-list-scrollcontainer-tag'}>
                                                                    {item.validity.daysTag}
                                                                </Tag>
                                                                <Tag className={'rfid-card-list-scrollcontainer-tag'}>
                                                                    {`${item.validity.fromHour} - ${item.validity.toHour} hs (${item.validity.timeZone})`}
                                                                </Tag>
                                                            </>
                                                        );
                                                    case 3:
                                                    default:
                                                        return (
                                                            <Tag className={'rfid-card-list-scrollcontainer-tag'}>
                                                                {translate({ key: 'general.permanent' })}
                                                            </Tag>
                                                        );
                                                }
                                            })()}
                                            {/* <Tag className={'rfid-card-list-scrollcontainer-tag'}>
                                                {`Venc ${item.validity.to}`}
                                            </Tag> */}
                                        </div>
                                    }
                                />
                                {/* </Skeleton> */}
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
