import { useEffect, useState } from 'react';
import { PasscodeListController, PasscodeListItem } from 'fragments/passcodes/fragments/passcode-list/interfaces';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAPIPasscodes } from 'services/passcodes/api-pascodes.service';
import { PasscodesDto } from 'services/passcodes/passcodes.service';
import { PaginationRequest, Sort } from 'global/interfaces';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { onGeneratePasscodeFromList, onViewPasscodeDetailFromList } from 'mobile/passcodes/passcode-functions';
import { useLockAdministrationContext } from 'fragments/locks/fragments/context/lock-administration.context';
import { OrderOption } from 'components/list-header-search-and-sort/list-header-search-and-sort.component';
dayjs.extend(utc);

let searchStr = '';

export const usePasscodeListController = (): /* <--Dependency Injections  like services hooks */
PasscodeListController => {
    const { translate } = useTranslator();
    const { lockId: ctxLockId, accessToken: ctxAccessToken } = useLockAdministrationContext();
    const [searchParams] = useSearchParams();
    const passcodesService = useAPIPasscodes();
    const navigate = useNavigate();
    const location = useLocation();

    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [passcodes, setPasscodes] = useState<PasscodesDto[]>([]);
    const [totalPasscodes, setTotalPasscodes] = useState<number>(0);
    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
    const [sortFocused, setSortFocused] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState<Sort>({ orderBy: 'date', order: 'descend' });

    const reactNavigation = location.pathname === '/mobile/locks/administration';
    const accessToken = !reactNavigation ? searchParams.get('access_token') : ctxAccessToken;
    const lockId = !reactNavigation ? searchParams.get('lock_id')?.toLowerCase() : ctxLockId;
    const [isFullSize, setIsFullSize] = useState<boolean>(!reactNavigation);

    const orderOptions: OrderOption[] = [
        {
            id: 0,
            value: translate({ key: 'general.later' }),
            sort: {
                order: 'ascend',
                orderBy: 'date',
            },
        },
        {
            id: 1,
            value: translate({ key: 'general.A-Z' }),
            sort: {
                order: 'ascend',
                orderBy: 'label',
            },
        },
        {
            id: 2,
            value: translate({ key: 'general.Z-A' }),
            sort: {
                order: 'descend',
                orderBy: 'label',
            },
        },
    ];

    /* Listeners */
    useEffect(() => {
        if (accessToken && lockId) {
            getPasscodeList(accessToken, lockId, { page: 0, pageSize }, sort, search);
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, lockId]);

    /* View Events */
    const loadMoreData = () => {
        if (isLoadingList) {
            return;
        }
        if (accessToken && lockId) {
            getPasscodeList(
                accessToken,
                lockId,
                { page: currentPage, pageSize },
                {
                    order: sort.order,
                    orderBy: sort.orderBy,
                },
                search,
            );
        }
    };

    const onSortSelect = (sortSelected: Sort) => {
        if (!accessToken || !lockId) return;

        setSort(sortSelected);
        getPasscodeList(accessToken, lockId, { page: 0, pageSize }, sortSelected, search);
    };

    const onSearch = (value: string) => {
        if (!accessToken || !lockId) return;

        setSearch(value);
        searchStr = value;
        getPasscodeList(accessToken, lockId, { page: 0, pageSize }, sort, value);
    };

    const giveSortFocus = (value: boolean) => {
        setSortFocused(value);
    };

    const changeLoadingList = (value: boolean) => {
        setIsLoadingList(value);
    };

    const onGeneratePasscodeButtonPressed = () => {
        if (reactNavigation) {
            navigate(`/mobile/passcodes/creation?lock_id=${lockId}&access_token=${accessToken}`);
        } else {
            onGeneratePasscodeFromList({ v: 1, data: { lock_id: lockId || '' } });
        }
    };

    const onViewPasscodeDetail = (id: string) => {
        if (!sortFocused) {
            if (reactNavigation) {
                navigate(`/mobile/passcodes/detail`, {
                    state: {
                        accessToken,
                        passcodeId: id,
                    },
                });
            } else {
                onViewPasscodeDetailFromList({ v: 1, data: { passcode_id: id } });
            }
        }
    };

    /* Private Methods */
    const getPasscodeList = (
        accessToken: string,
        lockId: string,
        pagination: PaginationRequest,
        sort: Sort,
        srch: string,
    ) => {
        setIsLoadingList(true);
        passcodesService
            .listPasscodes({ accessToken, pagination, sort, search: { lockId, input: srch } })
            .then((data) => {
                if (data.search === searchStr) {
                    if (pagination.page === 0) {
                        setCurrentPage(1);
                        setPasscodes(data.passcodes);
                        setTotalPasscodes(data.total);
                    } else {
                        const newData = passcodes.concat(data.passcodes);
                        setPasscodes(newData);
                        setCurrentPage(currentPage + 1);
                        setTotalPasscodes(data.total);
                    }
                    setIsLoadingList(false);
                }
            })
            .catch((error) => {
                console.log('passcode-list-error', error);
            });
    };

    const getLabelAndColorByState = (state: number): { label: string; color: string } => {
        switch (state) {
            case 0:
                return { label: translate({ key: 'general.invalid' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 1:
                return { label: translate({ key: 'general.valid' }), color: 'rgba(109, 206, 201, 0.8)' };
            case 2:
                return { label: translate({ key: 'general.inactive' }), color: 'rgba(147, 94, 224, 0.6)' };

            default:
                return { label: 'invalid', color: 'rgba(242, 61, 79, 0.6)' };
        }
    };

    const mapDtoToListItem = (dto: PasscodesDto): PasscodeListItem => {
        const viewModelObject = {
            id: dto.id,
            state: getLabelAndColorByState(dto.state),
            type: getTypeById(dto.type),
            label: capitalize(dto.label),
            validity: {
                from: dayjs.utc(dto.since).format('DD/MM'),
                to: dayjs.utc(dto.until).format('DD/MM'),
                fromHour: dayjs.utc(dto.since).format('HH'),
                toHour: dayjs.utc(dto.until).format('HH'),
                timeZone: dto.time_zone,
            },
        };
        return viewModelObject;
    };

    const getTypeById = (type: number) => {
        switch (type) {
            case 1:
                return translate({ key: 'passcodes.single-use' });
            case 2:
                return translate({ key: 'passcodes.temporary' });
            default:
                return '';
        }
    };

    // Return state and events
    return {
        passcodeList: passcodes.map(mapDtoToListItem),
        isLoadingList,
        totalPasscodes,
        orderOptions,
        isFullSize,
        loadMoreData,
        onSortSelect,
        onSearch,
        giveSortFocus,
        changeLoadingList,
        onGeneratePasscodeButtonPressed,
        onViewPasscodeDetail,
    };
};
