import { Notification, NotificationItem, GetNotificationResponse } from 'services/notification/notification.service';
import axios from 'axios';
import { useErrorHandler } from 'tools/error-handler';
import { ErrorDto } from 'services/dtos/errors-dto';

export const useAPINotification = (): Notification => {
    const errorHandler = useErrorHandler();

    const getNotificationList = async (accessToken: string, searchToken: number): Promise<GetNotificationResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL_NOTIFICATION_BACKEND + '/user/notifications?next=' + searchToken,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                ...response.data,
                data: response.data.data.map((item: NotificationItem) => ({ ...item, id: item.id.toLowerCase() })),
            } as GetNotificationResponse;
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const markNotificationAsRead = async (accessToken: string, notificationId: string): Promise<NotificationItem> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL_NOTIFICATION_BACKEND + `/user/notification/${notificationId}/read`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return response.data as NotificationItem;
        } catch (err: any) {
            // errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        getNotificationList,
        markNotificationAsRead,
    };
};
