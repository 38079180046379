import React from 'react';
import 'fragments/notification/fragments/notification-list/notification-list.scss';
import { NotificationListFragmentProps } from 'fragments/notification/fragments/notification-list/interfaces';
import { useNotificationListController } from 'fragments/notification/fragments/notification-list/notification-list.controller';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Badge, Empty, List, Modal, Skeleton, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import InfoIcon from 'assets/icons/info-icon';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { NotificationItem } from 'services/notification/notification.service';
const { info } = Modal;

const { Text, Title } = Typography;

export const NotificationListFragment: React.FC<NotificationListFragmentProps> = (props) => {
    const { useController = useNotificationListController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showNotificationModal = (item: NotificationItem) => {
        item.status_id == 1 && controller.onClickNotification(item.id);
        info({
            title: <Text strong>{item.title}</Text>,
            icon: undefined,
            cancelButtonProps: { className: 'btn-cancel' },
            content: item.content,
            okText: translate({ key: 'general.close' }),
            onOk() {
                item.status_id == 1 && controller.onUpdateNotificationList(item.id);
            },
        });
    };
    const onClickNotification = (item: NotificationItem) => {
        switch (item.type_id) {
            case 1:
                showNotificationModal(item);
                break;
            case 2:
                item.status_id == 1 && controller.onClickNotification(item.id);
                controller.onClickNotificationTypeLink(
                    item.extended_info?.link ? item.extended_info?.link : '',
                    item.id,
                );
                break;
            case 3:
                console.log('type content');
                item.status_id == 1 && controller.onClickNotification(item.id);
                controller.onClickNotificationTypeLink(
                    item.extended_info?.link ? item.extended_info?.link : '',
                    item.id,
                );
                break;
            default:
                console.log('unknown type');
                break;
        }
    };

    // Render
    return (
        <div className={'notification-list'}>
            <div id="scrollableResult" className={'list-scrollcontainer'}>
                <InfiniteScroll
                    dataLength={controller.notifications.length}
                    next={controller.loadMoreData}
                    hasMore={controller.notifications.length < controller.totalNotifications}
                    // loader={controller.isLoadingList ? <Skeleton title={true} paragraph={false} active /> : null}
                    loader={
                        controller.isLoadingList ? (
                            <Skeleton avatar title={false} loading={controller.isLoadingList} active />
                        ) : null
                    }
                    scrollableTarget="scrollableResult"
                >
                    <List
                        className="notification-list"
                        loading={{
                            spinning: controller.isLoading,
                            style: { position: 'fixed', top: '80px' },
                        }}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{ paddingTop: '2rem', height: 120 }}
                                    description={<span style={{ color: '#888888' }}>No hay notificaciones</span>}
                                >
                                    <ReloadOutlined
                                        style={{ color: '#888888', fontSize: '1.5rem' }}
                                        spin={controller.isLoadingList}
                                        onClick={controller.onReloadNotifications}
                                    />
                                </Empty>
                            ),
                        }}
                        itemLayout="horizontal"
                        dataSource={controller.notifications}
                        renderItem={(item) => (
                            <List.Item
                                style={{ backgroundColor: item.status_id == 1 ? '#7c5fbc22' : 'white' }}
                                className={'notification-item'}
                                onClick={() => onClickNotification(item)}
                            >
                                {/* <Badge dot color={item.status_id == 1 ? 'purple' : 'white'} offset={[0, -42]} /> */}
                                <List.Item.Meta
                                    style={{ width: '100px' }}
                                    avatar={
                                        item.image_url ? (
                                            <Avatar src={item.image_url} className="notification-item-avatar" />
                                        ) : (
                                            <InfoIcon
                                                style={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                }}
                                            />
                                        )
                                    }
                                    title={
                                        <Title ellipsis level={5} className="notification-item-title">
                                            {item.title}
                                        </Title>
                                    }
                                    description={
                                        <Text ellipsis className="notification-item-content">
                                            {item.content}
                                        </Text>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
