import { CustomFields, CustomField, AutocompleteTagsResponse } from 'services/custom-fields/custom-fields.service';
import axios from 'axios';
import { ErrorDto } from 'services/dtos/errors-dto';

export const useAPICustomFields = (): CustomFields => {
    const listCustomFields = async (accessToken: string, deviceId: string): Promise<CustomField[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL + `/user/devices/${deviceId}/custom-fields`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return response.data as CustomField[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const autocompleteTags = async (
        accessToken: string,
        deviceId: string,
        input: string,
        fieldId: string,
    ): Promise<AutocompleteTagsResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL +
                    `/user/devices/${deviceId}/custom-fields/${fieldId}/tags?input=${input}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return response.data as AutocompleteTagsResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        listCustomFields,
        autocompleteTags,
    };
};
