import { useEffect, useState } from 'react';
import {
    CreateKeySummaryController,
    FormattedDateTime,
} from 'fragments/keys/fragments/mobile-key-creation/fragments/create-key-summary/interfaces';
import { useKeysCreationContext } from 'fragments/keys/fragments/mobile-key-creation/context/keys-creation.context';
import {
    CreateKeySummaryDto,
    KeyCreationResponse,
    KeyScheduleOption,
    OverwriteSmartTokenRecordRequest,
} from 'services/keys/keys.service';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useDowFunctions } from 'tools/dow-functions';
import dayjs from 'dayjs';

export const useCreateKeySummaryController = (
    keyService = useAPIKeys(),
): /* <--Dependency Injections  like services hooks */
CreateKeySummaryController => {
    const { getKeySchedules } = useDowFunctions();
    /* State */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<number>(0);
    const [color, setColor] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [validity, setValidity] = useState<FormattedDateTime>({
        sinceDate: '',
        untilDate: '',
        sinceTime: '',
        untilTime: '',
    });
    const [keySchedules, setKeySchedules] = useState<KeyScheduleOption[]>([]);
    const {
        isCreateKeySummaryModalVisible,
        setIsCreateKeySummaryModalVisible,
        keyCreationResponse,
        setKeyCreationResponse,
        accessToken,
    } = useKeysCreationContext();

    const { translate } = useTranslator();
    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    useEffect(() => {
        setStatus(keyCreationResponse.status);
        const dataDto = keyCreationResponse.data as CreateKeySummaryDto;
        const dataError = keyCreationResponse.data as string;
        switch (keyCreationResponse.status) {
            case 200:
                setColor('#15B9B0');
                setTitle(translate({ key: 'key.summary-title-create-success' }));
                setMessage(
                    `${dataDto.assigned_to_email} ${translate({ key: 'key.summary-text-create-success-1' })} ${
                        dataDto.lock_name
                    } ${translate({ key: 'key.summary-text-create-success-2' })} ${dataDto.site_name}.`,
                );
                setValidity(formatDatetime(dataDto.nbf, dataDto.expires_at));
                setKeySchedules(getKeySchedules(dataDto.dow));
                break;
            case 202:
                setColor('#F7B28B');
                setTitle(translate({ key: 'key.summary-title-create-overwrite' }));
                setMessage(`${dataDto.assigned_to_email} ${translate({ key: 'key.summary-text-create-overwrite' })}`);
                break;
            case 409:
                const errorType = dataError.split(' ')[0];
                const errorEmail = dataError.split(' ')[1];
                setColor('#F23D4FCC');
                if (errorType === 'user') {
                    setTitle(translate({ key: 'key.summary-title-create-error' }));
                    setMessage(`${errorEmail} ${translate({ key: 'key.summary-text-create-error' })}`);
                }
                if (errorType === 'denied') {
                    setTitle(translate({ key: 'key.summary-title-create-error-1' }));
                    setMessage(`${errorEmail} ${translate({ key: 'key.summary-text-create-error-1' })}`);
                }
                break;
            default:
        }
    }, [keyCreationResponse]);

    /* View Events */
    const onCloseButtonPressed = () => {
        setIsCreateKeySummaryModalVisible(false);
    };
    const onOverwriteKey = () => {
        const data = keyCreationResponse.data as CreateKeySummaryDto;
        overwriteKey({ id: data.id });
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}
    const formatDatetime = (since: string, until: string): FormattedDateTime => {
        const fotmattedDateTime = {
            sinceDate: dayjs(since).utc().format('DD/MM/YY'),
            untilDate: dayjs(until).utc().format('DD/MM/YY'),
            sinceTime: dayjs(since).utc().format('HH:mm'),
            untilTime: dayjs(until).utc().format('HH:mm'),
        };

        return fotmattedDateTime;
    };

    const overwriteKey = (input: OverwriteSmartTokenRecordRequest) => {
        setIsLoading(true);
        keyService
            .overwriteKeys(accessToken, input)
            .then((r: KeyCreationResponse) => {
                setKeyCreationResponse(r);
            })
            .catch((errorMsg: ErrorDto) => {
                console.log(errorMsg);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    // Return state and events

    return {
        isLoading,
        status,
        color,
        title,
        message,
        isCreateKeySummaryModalVisible,
        keyCreationResponse,
        validity,
        keySchedules,
        onCloseButtonPressed,
        onOverwriteKey,
    };
};
