import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const XCircleSvg = () => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M78.5 40C78.5 61.263 61.263 78.5 40 78.5C18.737 78.5 1.5 61.263 1.5 40C1.5 18.737 18.737 1.5 40 1.5C61.263 1.5 78.5 18.737 78.5 40Z"
            stroke="#F23D4F"
            strokeOpacity="0.8"
            strokeWidth="3"
        />
        <path d="M28 52L52 28" stroke="#F23D4F" strokeOpacity="0.8" strokeWidth="3" />
        <path d="M28 28L52 52" stroke="#F23D4F" strokeOpacity="0.8" strokeWidth="3" />
    </svg>
);
const XCircleIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={XCircleSvg} {...props} />;
export default XCircleIcon;
