import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-delete-error-lock/rfid-card-delete-error-lock.scss';
import { RfidCardDeleteErrorLockFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-delete-error-lock/interfaces';
import { useRfidCardDeleteErrorLockController } from 'fragments/rfidcards/fragments/rfid-card-delete-error-lock/rfid-card-delete-error-lock.controller';
import { Button, Result, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import WarningIcon from 'assets/icons/warning-icon';

const { Text, Title } = Typography;

export const RfidCardDeleteErrorLockFragment: React.FC<RfidCardDeleteErrorLockFragmentProps> = (props) => {
    const { useController = useRfidCardDeleteErrorLockController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'rfid-card-delete-error-lock'}>
            <Result
                icon={<WarningIcon />}
                title={
                    <Title className={'rfid-card-delete-error-lock-title'}>
                        {translate({ key: 'rfid-card.summary-title-delete-error-lock' })}
                    </Title>
                }
                subTitle={
                    <Text className={'rfid-card-delete-error-lock-subtitle'}>
                        {translate({ key: 'rfid-card.summary-text-delete-error-lock' })}
                    </Text>
                }
                extra={
                    <div className={'rfid-card-delete-error-lock-btns'}>
                        <Button
                            className={'rfid-card-delete-error-lock-btns-item'}
                            type="default"
                            key="console"
                            onClick={controller.onDeleteRetryPressed}
                        >
                            {translate({ key: 'general.try-again' })}
                        </Button>
                    </div>
                }
            />
        </div>
    );
};
