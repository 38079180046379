/* eslint-disable @typescript-eslint/ban-types */
import { MobileDataInterface } from 'mobile';
import { OnAlertInput } from './utils-interfaces';

export const onInvalidToken = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onInvalidToken.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onInvalidToken(JSON.stringify(object));
};
export const onInvalidSession = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onInvalidSession.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onInvalidSession(JSON.stringify(object));
};
export const onAlert = (object: MobileDataInterface<OnAlertInput>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onAlert.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onAlert(JSON.stringify(object));
};
export const onOpenExternalBrowser = (object: MobileDataInterface<{ url: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onOpenExternalBrowser.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onOpenExternalBrowser(JSON.stringify(object));
};
