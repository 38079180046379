import React from 'react';
import 'fragments/passcodes/fragments/passcode-details/passcode-details.scss';
import { PasscodeDetailsFragmentProps } from 'fragments/passcodes/fragments/passcode-details/interfaces';
import { usePasscodeDetailsController } from 'fragments/passcodes/fragments/passcode-details/passcode-details.controller';
import { Button, Divider, Modal, PageHeader, Spin, Tag, Typography } from 'antd';
import { ShareAltOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ErrorComponent from 'components/error-component/error-component.component';
const { confirm } = Modal;
const { Text } = Typography;

export const PasscodeDetailsFragment: React.FC<PasscodeDetailsFragmentProps> = (props) => {
    const { useController = usePasscodeDetailsController } = props;
    const { passcodeDetails, isLoadingDetails, errorDetails, onShareButtonPressed, onDeleteButtonPressed } =
        useController();
    const { translate } = useTranslator();

    const showConfirmDeleteModal = (code: string, id: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                onDeleteButtonPressed(code, id);
            },
        });
    };

    // Render
    return (
        <div className={'passcode-details'}>
            {!isLoadingDetails && !errorDetails && (
                <>
                    <PageHeader className="passcode-details-header" title={passcodeDetails?.label} />
                    <div className="passcode-details-container">
                        <h3 className="passcode-details-container-label">
                            {translate({ key: 'label.code' })}:{' '}
                            <strong className="spacing">{passcodeDetails?.code}</strong>
                            {'(#)'}
                        </h3>
                        <h3 className="passcode-details-container-label">
                            {translate({ key: 'label.creator-email' })}:{' '}
                            <strong>{passcodeDetails?.created_by_email}</strong>
                        </h3>
                        <h3 className="passcode-details-container-label">
                            {translate({ key: 'label.lock' })}: <strong>{passcodeDetails?.lock_name}</strong>
                        </h3>
                        <h3 className="passcode-details-container-label">
                            {translate({ key: 'label.state' })}:{' '}
                            {passcodeDetails?.state === 0 ? (
                                <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                    {translate({ key: 'general.invalid' })}
                                </Tag>
                            ) : passcodeDetails?.state === 1 ? (
                                <Tag color="success" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                    {translate({ key: 'general.valid' })}
                                </Tag>
                            ) : (
                                passcodeDetails?.state === 2 && (
                                    <Tag color="default" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                        {translate({ key: 'general.inactive' })}
                                    </Tag>
                                )
                            )}
                        </h3>
                    </div>
                    <Divider style={{ margin: '1rem 0' }} />
                    <div className="passcode-details-container">
                        <div className="passcode-details-container-dateTags">
                            {passcodeDetails?.validity.map((dateTag) => (
                                <Tag key={dateTag} className="passcode-details-container-tag">
                                    {dateTag}
                                </Tag>
                            ))}
                        </div>
                        {passcodeDetails?.state === 1 && (
                            <Button
                                className="passcode-details-container-button"
                                onClick={() =>
                                    onShareButtonPressed(
                                        passcodeDetails?.code || '',
                                        passcodeDetails?.lock_name || '',
                                        passcodeDetails?.type || 1,
                                    )
                                }
                            >
                                <ShareAltOutlined /> {translate({ key: 'general.share' })}
                            </Button>
                        )}
                        {passcodeDetails?.type !== 1 && passcodeDetails?.state === 1 && (
                            <Button
                                className="passcode-details-container-button-delete"
                                onClick={() =>
                                    showConfirmDeleteModal(passcodeDetails?.code || '', passcodeDetails?.id || '')
                                }
                            >
                                <DeleteOutlined /> {translate({ key: 'general.delete' })}
                            </Button>
                        )}
                    </div>
                </>
            )}
            {isLoadingDetails && (
                <div className={'loading-spiner'}>
                    <Spin></Spin>
                </div>
            )}
            {errorDetails && <ErrorComponent />}
        </div>
    );
};
