import React, { useContext } from 'react';
import { KeyCreationResponse } from 'services/keys/keys.service';
import { KeyCreationErrorResponse } from '../interfaces';
// import { KeyCreationResponse } from '../interfaces';

export interface KeysCreationContextInterface {
    keyCreationResponse: KeyCreationResponse | KeyCreationErrorResponse;
    isCreateKeySummaryModalVisible: boolean;
    accessToken: string;
    setAccessToken: React.Dispatch<React.SetStateAction<string>>;
    setIsCreateKeySummaryModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setKeyCreationResponse: React.Dispatch<React.SetStateAction<KeyCreationResponse | KeyCreationErrorResponse>>;
}

export const KeysCreationContext = React.createContext<KeysCreationContextInterface>({
    isCreateKeySummaryModalVisible: false,
    keyCreationResponse: {
        status: 0,
        data: {
            id: '',
            lock_name: '',
            site_name: '',
            created_at: '',
            assigned_to_email: '',
            nbf: '',
            expires_at: '',
            dow: 0,
        },
    },
    accessToken: '',
    setAccessToken: () => {
        /* Nothing to do here*/
    },

    setIsCreateKeySummaryModalVisible: () => {
        /* Nothing to do here*/
    },
    setKeyCreationResponse: () => {
        /* Nothing to do here*/
    },
});

export const useKeysCreationContext = (): KeysCreationContextInterface => {
    const store = useContext(KeysCreationContext);
    return store;
};
