import React, { useEffect, useState } from 'react';
import 'components/form-days-selector/form-days-selector.scss';
import { Button, Col, Form, Row, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { KeyScheduleOption } from 'services/keys/keys.service';
import { useDowFunctions } from 'tools/dow-functions';

const { Title, Text } = Typography;

export interface FormDaysSelectorProps {
    dowBytewise: number;
    setDowBytewise: React.Dispatch<React.SetStateAction<number>>;
    disabledDaysBytewise?: number[];
}

// ======================================= INSTRUCCIONES DEL COMPONENTE =======================================
// Este componente debe recibir por props un state y su funcion de seteo respetando la estructura en FormDaysSelectorProps.
// El componente actualizará este state cada vez que se interactue con él.
// Ejemplo:
// const [state, setState] = useState()
// return <FormDaysSelector dowBytewise={state} setDowBytewise={setState}/>
// ============================================================================================================

const FormDaysSelector: React.FC<FormDaysSelectorProps> = (props) => {
    // State
    const [keySchedules, setKeySchedules] = useState<KeyScheduleOption[]>([]);
    const { translate } = useTranslator();
    const { dowChallenge } = useDowFunctions();

    useEffect(() => {
        props.setDowBytewise(127);
        setKeySchedules(getDefaultKeySchedules());
    }, []);

    useEffect(() => {
        if (props.disabledDaysBytewise) {
            const disabledDow = props.disabledDaysBytewise?.reduce((acc, e) => acc + e, 0) || 0;
            props.setDowBytewise(127 - disabledDow);
            setKeySchedules(getDefaultKeySchedules());
        }
    }, [props.disabledDaysBytewise]);

    const onDayPressed = (key: number) => {
        const updatedKeySchedules = keySchedules.map((keySchedule) => {
            if (keySchedule.key == key) {
                keySchedule.selected = !keySchedule.selected;
                if (keySchedule.selected) {
                    props.setDowBytewise(props.dowBytewise + key);
                } else {
                    props.setDowBytewise(props.dowBytewise - key);
                }
            }
            return keySchedule;
        });
        setKeySchedules(updatedKeySchedules);
    };

    const getDefaultKeySchedules = (): KeyScheduleOption[] => {
        const defaults = { selected: true, disabled: false };

        let defaultKeySchedules = [
            { key: 64, label: translate({ key: 'general.sunday-initial' }), ...defaults },
            { key: 1, label: translate({ key: 'general.monday-initial' }), ...defaults },
            { key: 2, label: translate({ key: 'general.tuesday-initial' }), ...defaults },
            { key: 4, label: translate({ key: 'general.wednesday-initial' }), ...defaults },
            { key: 8, label: translate({ key: 'general.thursday-initial' }), ...defaults },
            { key: 16, label: translate({ key: 'general.friday-initial' }), ...defaults },
            { key: 32, label: translate({ key: 'general.saturday-initial' }), ...defaults },
        ];

        const disabledDays = props.disabledDaysBytewise || undefined;
        if (disabledDays) {
            defaultKeySchedules = defaultKeySchedules.map((ks) => {
                return { ...ks, disabled: disabledDays?.some((x) => x === ks.key) || false };
            });
        }
        return defaultKeySchedules;
    };

    return (
        <div className={'form-days-selector'}>
            <Form.Item
                label={
                    <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                        {translate({ key: 'label.days' })}
                    </Title>
                }
                required
                rules={[
                    {
                        required: props.dowBytewise == 0 ? true : false,
                        message: props.dowBytewise == 0 ? translate({ key: 'form.rules-required' }) : '',
                    },
                ]}
                name="days-of-week"
            >
                <Row justify="space-between">
                    {keySchedules.map((keyOption) => {
                        return (
                            <Col
                                xs={3}
                                key={keyOption.key}
                                style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                            >
                                <Button
                                    disabled={keyOption.disabled}
                                    type={keyOption.selected ? 'primary' : 'default'}
                                    shape={'circle'}
                                    size={'large'}
                                    key={keyOption.key}
                                    onClick={() => onDayPressed(keyOption.key)}
                                >
                                    {keyOption.label}
                                </Button>
                            </Col>
                        );
                    })}
                </Row>
                <Text
                    style={{
                        display: 'block',
                        width: '100%',
                        textAlign: 'right',
                    }}
                >
                    {dowChallenge(props.dowBytewise)}
                </Text>
            </Form.Item>
        </div>
    );
};

export default FormDaysSelector;
