import { RfidCardCreationResultWarningController } from 'fragments/rfidcards/fragments/rfid-card-creation-result-warning/interfaces';
import { onUpdateCardRetry } from 'mobile/rfid/rfid-functions';

export const useRfidCardCreationResultWarningController = (): /* <--Dependency Injections  like services hooks */
RfidCardCreationResultWarningController => {
    /* State */

    /* Listeners */

    /* View Events */
    const onRetryButtonPressed = () => {
        onUpdateCardRetry({
            v: 3,
            data: {},
        });
    };

    /* Private Methods */

    // Return state and events
    return { onRetryButtonPressed };
};
