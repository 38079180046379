import { useEffect, useState } from 'react';
import { InfoModalController } from 'fragments/common/info-modal/interfaces';

export const useInfoModalController = (): /* <--Dependency Injections  like services hooks */ InfoModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [example, setExample] = useState('example');

    /* Listeners */

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onButtonPressed = () => {
        // Example event
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    // Return state and events
    return { example, onButtonPressed };
};
