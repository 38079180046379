import React, { useEffect, useState } from 'react';
import 'components/form-duration-picker/form-duration-picker.scss';
import {
    Button,
    Calendar,
    Col,
    Divider,
    Form,
    FormInstance,
    Modal,
    Radio,
    RadioChangeEvent,
    Row,
    Steps,
    TimePicker,
    Typography,
} from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs, { ManipulateType } from 'dayjs';
import moment from 'moment';
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

const { Title, Text } = Typography;
const { Step } = Steps;

export interface DurationOption {
    amount: number;
    unit: string;
    label: string;
}
export interface FormDurationPickerProps {
    dateRange: {
        from: string; // '2021-08-01';
        to: string; // '2021-08-01';
    };
    setDateRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
    hour?: boolean;
    form?: FormInstance;
    durations?: DurationOption[];
    disabled?: boolean;
}
// ======================================= INSTRUCCIONES DEL COMPONENTE =======================================
// Este componente debe recibir por props un state y su funcion de seteo respetando la estructura en FormDurationPickerProps.
// El componente actualizará este state cada vez que se interactue con él.
// Ejemplo:
// const [state, setState] = useState()
// return <FormDurationPicker dateRange={state} setDateRange={setState}/>
// ============================================================================================================

const FormDurationPicker: React.FC<FormDurationPickerProps> = (props) => {
    // State
    const { translate } = useTranslator();
    let [form] = useForm();
    form = props.form ? props.form : form;
    const { disabled } = props;
    const [label, setLabel] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempCustomDate, setTempCustomDate] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });
    const [tempCustomTime, setTempCustomTime] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [radioValue, setRadioValue] = useState<number | undefined>(undefined);
    const [lastRadioValue, setLastRadioValue] = useState<number | undefined>(undefined);

    const durations: DurationOption[] = !props.durations
        ? [
              { amount: 1, unit: 'day', label: '1 ' + translate({ key: 'general.day' }) },
              { amount: 1, unit: 'month', label: '1 ' + translate({ key: 'general.month' }) },
              { amount: 3, unit: 'month', label: '3 ' + translate({ key: 'general.months' }) },
              { amount: 6, unit: 'month', label: '6 ' + translate({ key: 'general.months' }) },
              { amount: 1, unit: 'year', label: '1 ' + translate({ key: 'general.year' }) },
              { amount: 2, unit: 'year', label: '2 ' + translate({ key: 'general.years' }) },
          ]
        : props.durations;

    const daysShortNames = [
        translate({ key: 'general.january-short' }),
        translate({ key: 'general.february-short' }),
        translate({ key: 'general.march-short' }),
        translate({ key: 'general.april-short' }),
        translate({ key: 'general.may-short' }),
        translate({ key: 'general.june-short' }),
        translate({ key: 'general.juli-short' }),
        translate({ key: 'general.august-short' }),
        translate({ key: 'general.september-short' }),
        translate({ key: 'general.october-short' }),
        translate({ key: 'general.november-short' }),
        translate({ key: 'general.december-short' }),
    ];
    const formatLabel = props.hour ? 'DD/MM/YYYY HH:00' : 'DD/MM/YYYY';
    const formatDate = props.hour ? 'YYYY-MM-DD HH:00:00' : 'YYYY-MM-DD';

    useEffect(() => {
        if (isModalVisible) {
            setTempCustomDate({ from: '', to: '' });
            setTempCustomTime({ from: '', to: '' });
            form.resetFields();
        }
    }, [isModalVisible]);

    const onSelectDuration = (e: RadioChangeEvent) => {
        setLastRadioValue(radioValue);
        setRadioValue(e.target.value);
        if (e.target.value === 'customDate') {
            setIsModalVisible(true);
            return;
        }
        const selectedDuration: DurationOption = durations[e.target.value as number];

        const since_date = dayjs().format(formatDate);
        const since_date_label = dayjs().format(formatLabel);

        const until_date = dayjs()
            .add(selectedDuration.amount, selectedDuration.unit as ManipulateType)
            .format(formatDate);
        const until_date_label = dayjs()
            .add(selectedDuration.amount, selectedDuration.unit as ManipulateType)
            .format(formatLabel);
        setLabel({ from: since_date_label, to: until_date_label });
        props.setDateRange({ from: since_date, to: until_date });
    };

    const onSinceChange = (date: moment.Moment) => {
        setTempCustomDate({ ...tempCustomDate, from: date.format('YYYY-MM-DD') });
        setTempCustomTime({ ...tempCustomTime, from: '' });
    };

    const onUntilChange = (date: moment.Moment) => {
        setTempCustomDate({ ...tempCustomDate, to: date.format('YYYY-MM-DD') });
        setTempCustomTime({ ...tempCustomTime, to: '' });
    };

    const onSinceTimeChange = (date: moment.Moment | null) => {
        setTempCustomTime({ ...tempCustomTime, from: date?.format('HH:00:00') || '' });
    };

    const onUntilTimeChange = (date: moment.Moment | null) => {
        setTempCustomTime({ ...tempCustomTime, to: date?.format('HH:00:00') || '' });
    };

    const onCancelDateModal = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        } else {
            setIsModalVisible(false);
            setRadioValue(lastRadioValue);
            setTempCustomDate({
                from: '',
                to: '',
            });
            props.hour &&
                setTempCustomTime({
                    from: '',
                    to: '',
                });
            props.form ? props.form.resetFields() : form.resetFields();
        }
    };

    const onOkDateModal = () => {
        if (currentStep < 1) {
            setCurrentStep(currentStep + 1);
        } else {
            setIsModalVisible(false);
            setCurrentStep(0);
            const fromFormated = props.hour
                ? dateFormater(tempCustomDate.from, tempCustomTime.from)
                : dateFormater(tempCustomDate.from, '00:00:00');
            const toFormated = props.hour
                ? dateFormater(tempCustomDate.to, tempCustomTime.to)
                : dateFormater(tempCustomDate.to, '00:00:00');
            setLabel({
                from: dayjs.utc(fromFormated).format(formatLabel),
                to: dayjs.utc(toFormated).format(formatLabel),
            });
            props.setDateRange({
                from: dayjs.utc(fromFormated).format(formatDate),
                to: dayjs.utc(toFormated).format(formatDate),
            });
            form.resetFields();
        }
    };

    const disableButtonSubmit = () => {
        if (props.hour) {
            if (currentStep === 0) {
                return !tempCustomDate.from.length || !tempCustomTime.from.length;
            }
            if (currentStep === 1) {
                return (
                    !tempCustomDate.from.length ||
                    !tempCustomTime.from.length ||
                    !tempCustomDate.to.length ||
                    !tempCustomTime.to.length
                );
            }
        } else {
            if (currentStep === 0) {
                return !tempCustomDate.from.length;
            }
            if (currentStep === 1) {
                return (
                    !tempCustomDate.from.length || !tempCustomDate.to.length || tempCustomDate.to < tempCustomDate.from
                );
            }
        }
    };

    const dateFormater = (date: string, time: string) => {
        return `${date}T${time}Z`;
    };

    const disabledDate = (current: moment.Moment | null, type: 'since' | 'until') => {
        if (!current) return true;
        const moreThanOneYear =
            !!tempCustomDate.from.length &&
            !!tempCustomTime.from.length &&
            current.isAfter(moment(tempCustomDate.from).add(1, 'year'));
        const isBeforeToday = !!current && current < moment().startOf('day');
        const isBeforeSince = !!current && current < moment(tempCustomDate.from).startOf('day');
        return isBeforeToday || moreThanOneYear || (type === 'until' && isBeforeSince);
    };
    const disabledTime = (date: moment.Moment | null, type: 'since' | 'until') => {
        return { disabledHours: () => disabledHour(date, type) };
    };

    const disabledHour = (date: moment.Moment | null, type: 'since' | 'until'): number[] => {
        const currentHour = dayjs().get('hour');
        const disabledHoursArray: number[] = [];

        if (
            !tempCustomDate.from.length ||
            (dayjs().format('YYYY-MM-DD') === dayjs(tempCustomDate.from).format('YYYY-MM-DD') && type === 'since')
        ) {
            for (let i = 0; i < currentHour; i++) {
                disabledHoursArray.push(i);
            }
        }
        if (
            !!date &&
            type === 'until' &&
            !!tempCustomTime.from.length &&
            dayjs(tempCustomDate.from) >= dayjs(tempCustomDate.to)
        ) {
            for (let i = 0; i <= dayjs.utc(dateFormater(tempCustomDate.from, tempCustomTime.from)).get('hour'); i++) {
                disabledHoursArray.push(i);
            }
        }
        return disabledHoursArray;
    };

    return (
        <Form.Item
            label={
                <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                    {translate({ key: 'label.duracion' })}
                </Title>
            }
            required
            rules={[
                {
                    required: props.dateRange.from.length == 0 ? true : false,
                    message: props.dateRange.from.length == 0 ? translate({ key: 'form.rules-required' }) : '',
                },
            ]}
            name="duration"
        >
            <div className={'form-duration-picker'}>
                <Radio.Group
                    buttonStyle="solid"
                    onChange={onSelectDuration}
                    value={radioValue}
                    className={'form-duration-picker-radio-group'}
                    disabled={disabled}
                >
                    {durations.map((durationOption: DurationOption, index: number) => (
                        <Radio.Button key={index} value={index}>
                            {durationOption.label}
                        </Radio.Button>
                    ))}
                    <Radio.Button
                        onClick={() => {
                            setIsModalVisible(true);
                        }}
                        key={'customDate'}
                        value={'customDate'}
                        //style={{ width: '50%', textAlign: 'center' }}
                    >
                        {translate({ key: 'general.choose' })}
                    </Radio.Button>
                </Radio.Group>
                {props.dateRange.from && (
                    <Row justify="end" className={'form-duration-picker-text'}>
                        <Text>{`Desde ${label.from} hasta ${label.to}`}</Text>
                    </Row>
                )}
            </div>
            <Modal
                open={isModalVisible}
                centered
                closable={false}
                onCancel={onCancelDateModal}
                onOk={onOkDateModal}
                okText={currentStep < 1 ? translate({ key: 'general.next' }) : translate({ key: 'general.finish' })}
                cancelText={currentStep > 0 ? translate({ key: 'general.back' }) : translate({ key: 'general.cancel' })}
                okButtonProps={{
                    disabled: disableButtonSubmit(),
                }}
                //cancelButtonProps={{ hidden: currentStep === 0 }}
            >
                <Steps current={currentStep} responsive={false}>
                    <Step title={translate({ key: 'label.since' })} />
                    <Step title={translate({ key: 'label.until' })} />
                </Steps>
                <Form
                    form={form}
                    id="create-passcode-form"
                    layout="horizontal"
                    size="middle"
                    className={'passcode-creation-form'}
                >
                    {currentStep === 0 && (
                        <>
                            <Form.Item initialValue={moment()} name={'calendar_since'}>
                                <Calendar
                                    disabledDate={(e) => disabledDate(e, 'since')}
                                    value={tempCustomDate.from.length ? moment(tempCustomDate.from) : undefined}
                                    fullscreen={false}
                                    headerRender={({ value, type, onChange, onTypeChange }) => {
                                        const month = value.month();
                                        const year = value.year();

                                        return (
                                            <div style={{ padding: 8 }}>
                                                <Typography.Title level={5}>
                                                    {translate({ key: 'form.since-text' })}
                                                </Typography.Title>
                                                <Row justify={'space-between'}>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().year(Number(year - 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<DoubleLeftOutlined />}
                                                        />
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().month(Number(month - 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<LeftOutlined />}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                if (type === 'year') onTypeChange('month');
                                                                else onTypeChange('year');
                                                            }}
                                                        >
                                                            {daysShortNames[value.clone().month()] +
                                                                ' ' +
                                                                value.clone().format('YYYY')}
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().month(Number(month + 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<RightOutlined />}
                                                        />
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().year(Number(year + 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<DoubleRightOutlined />}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }}
                                    onSelect={onSinceChange}
                                />
                            </Form.Item>
                            <Form.Item initialValue={moment()} name={'time_since'}>
                                {!!props.hour && (
                                    <TimePicker
                                        onSelect={(e) => onSinceTimeChange(e)}
                                        disabledTime={(e) => disabledTime(e, 'since')}
                                        value={tempCustomTime.from.length ? moment(tempCustomTime.from) : undefined}
                                        showMinute={false}
                                        showSecond={false}
                                        format={'HH:00'}
                                        disabled={!tempCustomDate.from.length}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Form.Item>
                            <Divider style={{ margin: '0.5rem 0' }} />
                            <Row justify={'center'}>
                                <Button
                                    type={'link'}
                                    className={'form-duration-picker-modal-arrow-btn'}
                                    onClick={() => {
                                        onSinceChange(moment());
                                        onSinceTimeChange(moment());
                                        form.resetFields(['time_since', 'calendar_since']);
                                    }}
                                >
                                    {translate({ key: 'general.today' })}
                                </Button>
                            </Row>
                        </>
                    )}
                    {currentStep === 1 && (
                        <>
                            <Form.Item name={'calendar_until'}>
                                <Calendar
                                    disabledDate={(e) => disabledDate(e, 'until')}
                                    value={tempCustomDate.to.length ? moment(tempCustomDate.to) : undefined}
                                    fullscreen={false}
                                    headerRender={({ value, type, onChange, onTypeChange }) => {
                                        const month = value.month();
                                        const year = value.year();

                                        return (
                                            <div style={{ padding: 8 }}>
                                                <Typography.Title level={5}>
                                                    {translate({ key: 'form.until-text' })}
                                                </Typography.Title>
                                                <Row justify={'space-between'}>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().year(Number(year - 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<DoubleLeftOutlined />}
                                                        />
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().month(Number(month - 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<LeftOutlined />}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                if (type === 'year') onTypeChange('month');
                                                                else onTypeChange('year');
                                                            }}
                                                        >
                                                            {daysShortNames[value.clone().month()] +
                                                                ' ' +
                                                                value.clone().format('YYYY')}
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().month(Number(month + 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<RightOutlined />}
                                                        />
                                                        <Button
                                                            type={'text'}
                                                            className={'form-duration-picker-modal-arrow-btn'}
                                                            onClick={() => {
                                                                const newValue = value.clone().year(Number(year + 1));
                                                                onChange(newValue);
                                                            }}
                                                            icon={<DoubleRightOutlined />}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }}
                                    onSelect={onUntilChange}
                                />
                            </Form.Item>
                            <Form.Item name={'time_until'}>
                                {!!props.hour && (
                                    <TimePicker
                                        onSelect={(e) => onUntilTimeChange(e)}
                                        disabledTime={(e) => disabledTime(e, 'until')}
                                        value={tempCustomTime.to.length ? moment(tempCustomTime.to) : undefined}
                                        showMinute={false}
                                        showSecond={false}
                                        format={'HH:00'}
                                        disabled={!tempCustomDate.to.length}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Form.Item>
                            <Divider style={{ margin: '0.5rem 0' }} />
                            <Row justify={'center'}>
                                <Button
                                    type={'link'}
                                    className={'form-duration-picker-modal-arrow-btn'}
                                    onClick={() => {
                                        onUntilChange(moment());
                                        onUntilTimeChange(null);
                                        form.resetFields(['time_until', 'calendar_until']);
                                    }}
                                >
                                    {translate({ key: 'general.today' })}
                                </Button>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal>
        </Form.Item>
    );
};

export default FormDurationPicker;
