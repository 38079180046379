import { useEffect, useState } from 'react';
import { PasscodeDetail, PasscodeDetailsController } from 'fragments/passcodes/fragments/passcode-details/interfaces';
import { useAPIPasscodes } from 'services/passcodes/api-pascodes.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PasscodesDto } from 'services/passcodes/passcodes.service';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { onPasscodeDelete, onSharePasscode } from 'mobile/passcodes/passcode-functions';
import { useTranslator } from 'tools/view-hooks/translator-hook';
dayjs.extend(utc);

export const usePasscodeDetailsController = (
    passcodeServices = useAPIPasscodes(),
): /* <--Dependency Injections  like services hooks */
PasscodeDetailsController => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const { translate } = useTranslator();

    /* State */
    const [passcodeDetails, setPasscodeDetails] = useState<PasscodeDetail>();
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);
    const [errorDetails, setErrorDetails] = useState<string>();

    const passcodeId = searchParams.get('passcode_id')?.toLowerCase() || state?.passcodeId || '';
    const accessToken = searchParams.get('access_token') || state?.accessToken || '';
    /* Listeners */
    useEffect(() => {
        if (accessToken && passcodeId) {
            getPasscodeDetails({ accessToken, passcodeId });
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, passcodeId]);

    /* View Events */
    const onShareButtonPressed = (code: string, lockName: string, type: number) => {
        let text1 = '';
        let text2 = '';
        if (type === 1) {
            text1 = translate({ key: 'passcodes.passcode-share-single-use-description-1' });
            text2 = translate({ key: 'passcodes.passcode-share-single-use-description-2' });
        }
        if (type === 2) {
            text1 = translate({ key: 'passcodes.passcode-share-temporary-description-1' });
            text2 = translate({ key: 'passcodes.passcode-share-temporary-description-2' });
        }
        onSharePasscode({
            v: 1,
            data: {
                message: `${text1} ${lockName}: ${code}. ${text2}`,
            },
        });
    };
    const onDeleteButtonPressed = (code: string, id: string) => {
        console.log('Deleted: ', { id, code });

        onPasscodeDelete({
            v: 1,
            data: {
                id,
                code,
            },
        });
    };

    /* Private Methods */
    const getPasscodeDetails = (payload: { accessToken: string; passcodeId: string }) => {
        setIsLoadingDetails(true);
        passcodeServices
            .getPasscodeDetail(payload)
            .then((data) => {
                setPasscodeDetails(mapDtoToDetail(data));
            })
            .catch((error) => {
                console.log('passcodes-details-error', error);
                setErrorDetails(error.message);
            })
            .finally(() => {
                setIsLoadingDetails(false);
            });
    };
    const mapDtoToDetail = (dto: PasscodesDto | undefined): PasscodeDetail | undefined => {
        if (!dto) return dto;
        const mappedDates = {
            from: dayjs.utc(dto.since).format('DD/MM/YY'),
            to: dayjs.utc(dto.until).format('DD/MM/YY'),
            fromHour: dayjs.utc(dto.since).format('HH'),
            toHour: dayjs.utc(dto.until).format('HH'),
            tooltip: `Valido desde: ${dayjs.utc(dto.since).format('DD/MM/YYYY HH:00')} hasta: ${dayjs
                .utc(dto.until)
                .format('DD/MM/YYYY HH:00')} (${dto.time_zone})`,
            timeZone: `${dto.time_zone}`,
        };
        const viewModelObject = {
            ...dto,
            status: dto.state,
            type: dto.type,
            label: capitalize(dto.label),
            created_by_email: dto.created_by_email || '-',
            lock_name: capitalize(dto.lock_name),
            validity:
                mappedDates.from === mappedDates.to
                    ? [
                          `${mappedDates.from} De ${mappedDates.fromHour} a ${mappedDates.toHour} hs (${mappedDates.timeZone})`,
                      ]
                    : [
                          `Desde: ${mappedDates.from} ${mappedDates.fromHour} hs (${mappedDates.timeZone})`,
                          `Hasta: ${mappedDates.to} ${mappedDates.toHour} hs (${mappedDates.timeZone})`,
                      ],
        };
        return viewModelObject;
    };

    // Return state and events
    return { passcodeDetails, isLoadingDetails, errorDetails, onShareButtonPressed, onDeleteButtonPressed };
};
