import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-creation-result-warning/rfid-card-creation-result-warning.scss';
import { RfidCardCreationResultWarningFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-creation-result-warning/interfaces';
import { useRfidCardCreationResultWarningController } from 'fragments/rfidcards/fragments/rfid-card-creation-result-warning/rfid-card-creation-result-warning.controller';
import { Button, Result, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import WarningIcon from 'assets/icons/warning-icon';
const { Title, Text } = Typography;

export const RfidCardCreationResultWarningFragment: React.FC<RfidCardCreationResultWarningFragmentProps> = (props) => {
    const { useController = useRfidCardCreationResultWarningController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'rfid-card-creation-result-warning'}>
            <Result
                icon={<WarningIcon />}
                title={
                    <Title className={'rfid-card-creation-result-warning-title'}>
                        {translate({ key: 'rfid-card.summary-title-create-warning' })}
                    </Title>
                }
                subTitle={
                    <Text className={'rfid-card-creation-result-warning-subtitle'}>
                        {translate({ key: 'rfid-card.summary-text-create-warning' })}
                    </Text>
                }
                extra={
                    <div className={'rfid-card-creation-result-warning-btns'}>
                        <Button
                            className={'rfid-card-creation-result-warning-btns-item'}
                            type="primary"
                            key="console"
                            onClick={controller.onRetryButtonPressed}
                        >
                            {translate({ key: 'general.retry' })}
                        </Button>
                    </div>
                }
            />
        </div>
    );
};
