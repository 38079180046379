import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const InfoSvg = () => (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 40C1.49999 18.737 18.737 1.50001 40 1.5C61.263 1.5 78.5 18.737 78.5 40C78.5 61.263 61.263 78.5 40 78.5C18.737 78.5 1.5 61.263 1.5 40Z"
            stroke="#935EE0"
            strokeOpacity="0.6"
            strokeWidth="3"
        />
        <path d="M40 23.0295L40 27" stroke="#935EE0" strokeOpacity="0.6" strokeWidth="3" />
        <path d="M40 29L40 56.9706" stroke="#935EE0" strokeOpacity="0.6" strokeWidth="3" />
    </svg>
);
const InfoIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={InfoSvg} {...props} />;
export default InfoIcon;
