import React, { useContext } from 'react';
// import { KeyCreationResponse } from 'services/keys/keys.service';
// import { KeyCreationErrorResponse } from '../interfaces';
// import { KeyCreationResponse } from '../interfaces';

export interface PasscodesContextInterface {
    accessToken: string;
    lockId: string;
    setLockId: React.Dispatch<React.SetStateAction<string>>;
    setAccessToken: React.Dispatch<React.SetStateAction<string>>;
}

export const PasscodesContext = React.createContext<PasscodesContextInterface>({
    lockId: '',
    accessToken: '',
    setAccessToken: () => {
        /* Nothing to do here*/
    },
    setLockId: () => {
        /* Nothing to do here*/
    },
});

export const usePasscodesContext = (): PasscodesContextInterface => {
    const store = useContext(PasscodesContext);
    return store;
};
