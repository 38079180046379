import React from 'react';
import 'fragments/passcodes/fragments/passcode-list/passcode-list.scss';
import { PasscodeListFragmentProps } from 'fragments/passcodes/fragments/passcode-list/interfaces';
import { usePasscodeListController } from 'fragments/passcodes/fragments/passcode-list/passcode-list.controller';
import { Divider, List, Skeleton, Tag, Typography } from 'antd';
import { InfoCircleFilled, CloseCircleFilled, CheckCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import PasscodeIcon from 'assets/icons/passcode-icon';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListHeaderSearchAndSort from 'components/list-header-search-and-sort/list-header-search-and-sort.component';
const { Text } = Typography;

export const PasscodeListFragment: React.FC<PasscodeListFragmentProps> = (props) => {
    const { useController = usePasscodeListController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'passcode-list'} style={{ minHeight: controller.isFullSize ? '100vh' : undefined }}>
            <ListHeaderSearchAndSort
                onSort={controller.onSortSelect}
                onSearchChange={controller.onSearch}
                onAddButtonPressed={controller.onGeneratePasscodeButtonPressed}
                onLoaderSearch={controller.changeLoadingList}
                giveSortFocus={controller.giveSortFocus}
                orderOptions={controller.orderOptions}
                icon={<PlusCircleOutlined />}
            />
            <div
                id="scrollableDiv"
                className={'passcode-list-scrollcontainer'}
                style={{
                    height: controller.isFullSize ? 'calc(100vh - 65px)' : 'calc(100vh - 193px)',
                }}
            >
                <InfiniteScroll
                    dataLength={controller.passcodeList.length}
                    next={controller.loadMoreData}
                    hasMore={controller.passcodeList.length < controller.totalPasscodes}
                    loader={<Skeleton style={{ paddingLeft: '2.4rem' }} paragraph={{ rows: 1 }} active />}
                    // endMessage={<Divider plain>Fin</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        loading={controller.isLoadingList}
                        itemLayout="horizontal"
                        dataSource={controller.passcodeList}
                        renderItem={(item) => (
                            <List.Item
                                onClick={() => controller.onViewPasscodeDetail(item.id)}
                                actions={[
                                    item.state.label === translate({ key: 'general.valid' }) ? (
                                        <CheckCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ) : item.state.label === translate({ key: 'general.inactive' }) ? (
                                        <InfoCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ) : (
                                        <CloseCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ),
                                ]}
                            >
                                {/* <Skeleton title={false} loading={controller.isLoadingList} active> */}
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    avatar={
                                        <PasscodeIcon
                                            style={{
                                                marginLeft: '3px',
                                                width: '20px',
                                                height: '20px',
                                                color: '#888',
                                            }}
                                        />
                                    }
                                    title={
                                        <Text strong style={{ fontSize: '1rem' }}>
                                            {item.label}
                                        </Text>
                                    }
                                    description={
                                        <div style={{ width: 'fit-content' }}>
                                            {<Tag className={'passcode-list-scrollcontainer-tag'}>{item.type}</Tag>}
                                        </div>
                                    }
                                />
                                {/* </Skeleton> */}
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
