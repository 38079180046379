import React, { useState } from 'react';
import 'components/error-component/error-component.scss';
import { Button, Result } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const ErrorComponent: React.FC = (props) => {
    const { translate } = useTranslator();
    const navigate = useNavigate();

    const reloadPage = () => navigate(0);

    return (
        <div className={'error-component'}>
            <Result
                icon={<WarningFilled className={'error-component-icon'} />}
                status="warning"
                title={translate({ key: 'common-errors.web-error-message' })}
                // extra={
                //     <Button type="default" className={'error-component-button'} onClick={reloadPage}>
                //         {translate({ key: 'common-errors.reload-label' })}
                //     </Button>
                // }
            />
        </div>
    );
};

export default ErrorComponent;
