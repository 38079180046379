import { useEffect, useState } from 'react';
import {
    PasscodeCreationController,
    SelectedDateRange,
} from 'fragments/passcodes/fragments/passcode-creation/interfaces';
import { CreatePasscodeResponse, PasscodesDeviceDto } from 'services/passcodes/passcodes.service';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useAPIPasscodes } from 'services/passcodes/api-pascodes.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDatetime } from 'tools/date-handling';
import moment from 'moment';
import { onSharePasscode } from 'mobile/passcodes/passcode-functions';
import { onAlert } from 'mobile/common-functions';
import { useTranslator } from 'tools/view-hooks/translator-hook';

const defaultDateRange = {
    sinceDate: '',
    untilDate: '',
    sinceTime: '',
    untilTime: '',
};
export const usePasscodeCreationController = (
    passcodesService = useAPIPasscodes(),
): /* <--Dependency Injections  like services hooks */
PasscodeCreationController => {
    const { translate } = useTranslator();
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [selectedType, setSelectedType] = useState<number>(1);
    const [selectedLock, setSelectedLock] = useState<PasscodesDeviceDto>();
    const [selectedLockTimezone, setSelectedLockTimezone] = useState<string>('');
    const [formatedDates, setFormatedDates] = useState<string[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({ ...defaultDateRange });
    const [customDateRange, setCustomDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [label, setLabel] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [createdPasscode, setCreatedPasscode] = useState<CreatePasscodeResponse>({
        id: '',
        since: '',
        until: '',
        lock_name: '',
        code: '',
        label: '',
        type: 1,
    });
    const [locks, setLocks] = useState<PasscodesDeviceDto[]>([]);
    const [isLockListingLoading, setIsLockListingLoading] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<number>(0);

    const [form] = useForm();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const accessToken = searchParams.get('access_token') || '';
    const lock_id = searchParams.get('lock_id') || '';
    const lockId = lock_id.toLowerCase();
    if (!accessToken) {
        navigate('/404');
    }

    /* Listeners */

    useEffect(() => {
        setCurrentStep(0);
    }, [accessToken]);

    useEffect(() => {
        setIsLockListingLoading(true);
        if (accessToken) getDevices();
    }, []);

    useEffect(() => {
        const arr = [];

        if (selectedDateRange.sinceDate.length && selectedDateRange.sinceTime.length) {
            const formatedSince = formatDate(selectedDateRange.sinceDate, selectedDateRange.sinceTime);
            arr.push(dayjs.utc(formatedSince).format('DD/MM/YYYY HH:00') + 'hs' + ' (' + selectedLockTimezone + ')');
        }

        if (selectedDateRange.untilDate.length && selectedDateRange.untilTime.length) {
            const formatedUntil = formatDate(selectedDateRange.untilDate, selectedDateRange.untilTime);
            arr.push(dayjs.utc(formatedUntil).format('DD/MM/YYYY HH:00') + 'hs' + ' (' + selectedLockTimezone + ')');
        }
        setFormatedDates(arr);
    }, [selectedDateRange, selectedLockTimezone]);

    useEffect(() => {
        if (selectedLock && locks.length) setSelectedLockTimezone(selectedLock.timezone);
    }, [selectedLock?.id]);

    useEffect(() => {
        setSelectedDateRange({
            sinceDate: customDateRange.from.slice(0, 10),
            untilDate: customDateRange.to.slice(0, 10),
            sinceTime: customDateRange.from.slice(11, customDateRange.from.length),
            untilTime: customDateRange.to.slice(11, customDateRange.from.length),
        });
    }, [customDateRange]);

    /* View Events */
    const onCreateButtonPressed = () => {
        if (!selectedLock) return;
        setIsLoading(true);
        passcodesService
            .createPasscode(accessToken, {
                lock_id: selectedLock.id,
                label,
                since: formatDate(selectedDateRange.sinceDate, selectedDateRange.sinceTime),
                until: formatDate(selectedDateRange.untilDate, selectedDateRange.untilTime),
                type: selectedType === 2 ? 3 : 1,
                code: code,
            })
            .then((r: CreatePasscodeResponse) => {
                setSelectedDateRange({ ...defaultDateRange });
                setLabel('');
                setCode('');
                setCreatedPasscode(r);
                setCurrentStep(1);
                form.resetFields();
            })
            .catch((error) => {
                if (error == 'code') {
                    setCode('');
                    form.setFieldsValue({ code_passcode: undefined });
                    onAlert({
                        v: 1,
                        data: {
                            status: 0,
                            message: translate({ key: 'passcodes.create-passcode-code-error-mobile' }),
                        },
                    });
                } else if (error == 'label') {
                    setLabel('');
                    form.setFieldsValue({ code_label: undefined });
                    onAlert({
                        v: 1,
                        data: {
                            status: 0,
                            message: translate({ key: 'passcodes.create-passcode-label-error-mobile' }),
                        },
                    });
                } else if (error == 'existing') {
                    setLabel('');
                    form.setFieldsValue({ code_label: undefined });
                    onAlert({
                        v: 1,
                        data: {
                            status: 0,
                            message: translate({ key: 'passcodes.create-passcode-label-error-mobile' }),
                        },
                    });
                } else {
                    onAlert({
                        v: 1,
                        data: {
                            status: 0,
                            message: translate({ key: 'passcodes.create-passcode-error-mobile' }),
                        },
                    });
                }
                console.log('create-passcode-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCreateOtherPasscodeButtonPressed = () => {
        setCurrentStep(0);
        setCreatedPasscode({ id: '', since: '', until: '', code: '', lock_name: '', label: '', type: 1 });
    };

    const onTypeChange = (activeKey: string) => {
        setSelectedType(Number(activeKey));
        if (lockId && locks) setSelectedLock(locks.find((l) => l.id == lockId));
        setSelectedDateRange({
            sinceDate: '',
            untilDate: '',
            sinceTime: '',
            untilTime: '',
        });
        setLabel('');
        setCode('');
        form.resetFields();
    };

    const onShareButtonPressed = (code: string, lockName: string) => {
        onSharePasscode({
            v: 1,
            data: {
                message: `Aquí está tu código de acceso para ${lockName}: ${code}. Recuerda que debes apretar # al final del código`,
            },
        });
    };

    const onLockSelect = (value: string) => {
        setSelectedLock(locks.find((l) => l.id == value));
    };

    const onPasscodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };

    const onLabelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(e.target.value);
    };

    const onPickedDate = (value: moment.Moment | null, type: 'since' | 'until') => {
        if (value) {
            if (type === 'since') {
                if (selectedType === 1) {
                    setSelectedDateRange({
                        ...selectedDateRange,
                        sinceDate: value.format('YYYY-MM-DD'),
                    });
                }
                if (selectedType === 2) {
                    setSelectedDateRange({
                        ...selectedDateRange,
                        sinceDate: value.format('YYYY-MM-DD'),
                        sinceTime: value.format('HH:00:00'),
                    });
                }
            }
            if (type === 'until') {
                setSelectedDateRange({
                    ...selectedDateRange,
                    untilDate: value.format('YYYY-MM-DD'),
                    untilTime: value.format('HH:00:00'),
                });
            }
        }
    };
    const onPickedHour = (value: moment.Moment | null) => {
        if (value) {
            const formatedSince = formatDate(selectedDateRange.sinceDate, value.format('HH:00:00'));
            setSelectedDateRange({
                ...selectedDateRange,
                sinceTime: value.format('HH:00:00'),
                untilDate: dayjs(formatedSince).add(6, 'hour').format('YYYY-MM-DD'),
                untilTime: dayjs(formatedSince).add(6, 'hour').format('HH:00:00'),
            });
        }
    };

    const disabledDate = (current: moment.Moment | null) => {
        if (!current) return true;
        const moreThanOneYear =
            !!selectedDateRange.sinceDate.length &&
            !!selectedDateRange.sinceTime.length &&
            current.isAfter(moment(selectedDateRange.sinceDate).add(1, 'year'));
        const isBeforeToday = !!current && current < moment().startOf('day');
        return isBeforeToday || moreThanOneYear;
    };
    const disabledTime = (date: moment.Moment | null, range: boolean) => {
        return { disabledHours: () => disabledHour(date, range) };
    };

    const disabledHour = (date: moment.Moment | null, range: boolean): number[] => {
        console.log({ disabledHour: date?.format('DD-MM HH') });
        const currentHour = dayjs().get('hour');
        const disabledHoursArray: number[] = [];
        if (
            !date ||
            dayjs().format('YYYY-MM-DD') === (!range ? selectedDateRange.sinceDate : date.format('YYYY-MM-DD'))
        ) {
            for (let i = 0; i < currentHour; i++) {
                disabledHoursArray.push(i);
            }
        }
        return disabledHoursArray;
    };

    /* Private Methods */
    const getDevices = () => {
        passcodesService
            .listDevicesCreatePasscodeView(accessToken)
            .then((data) => {
                const locks = data.filter((lock) => lock.lock_type_id === 5 && lock.lock_status_id === 1 && lock);
                setLocks(locks);
                if (lockId && locks) setSelectedLock(locks.find((l) => l.id == lockId));
            })
            .catch((error) => {
                if (error.response?.data?.code !== 433 && error.response?.data?.code !== 434) {
                    onAlert({
                        v: 1,
                        data: {
                            status: 1,
                            message: translate({ key: 'lock.list-error' }),
                        },
                    });
                }
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLockListingLoading(false);
            });
    };

    const formatPasscode = (passcode: CreatePasscodeResponse): CreatePasscodeResponse => {
        return {
            ...passcode,
            since: formatDatetime(passcode.since, selectedLockTimezone),
            until: formatDatetime(passcode.until, selectedLockTimezone),
        };
    };

    const formatDate = (date: string, time: string) => {
        return `${date}T${time}Z`;
    };

    // Return state and events
    return {
        isLoading,
        form,
        code,
        locks,
        selectedType,
        selectedLock,
        customDateRange,
        selectedDateRange,
        formatedDates,
        currentStep,
        isLockListingLoading,
        createdPasscode: formatPasscode(createdPasscode),
        onShareButtonPressed,
        onCreateOtherPasscodeButtonPressed,
        disabledDate,
        disabledTime,
        disabledHour,
        onCreateButtonPressed,
        onLockSelect,
        setCustomDateRange,
        onTypeChange,
        onPickedDate,
        onPickedHour,
        onLabelInputChange,
        onPasscodeInputChange,
    };
};
