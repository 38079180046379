import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-creation-validity/rfid-card-creation-validity.scss';
import { RfidCardCreationValidityFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-creation-validity/interfaces';
import { useRfidCardCreationValidityController } from 'fragments/rfidcards/fragments/rfid-card-creation-validity/rfid-card-creation-validity.controller';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Typography } from 'antd';
import CustomHeader from 'components/custom-header/custom-header.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import FormDurationPicker from 'components/form-duration-picker/form-duration-picker.component';
import FormDaysSelector from 'components/form-days-selector/form-days-selector.component';
import FormTimeSlider from 'components/form-time-slider/form-time-slider.component';
import { DebounceInput } from 'react-debounce-input';
import { LoadingOutlined } from '@ant-design/icons';
import { SiteCardCustomField } from 'services/rfidcards/rfidcards.service';
import DebounceSelect from 'components/debounce-select/debounce-select.component';
import moment from 'moment';

const { warning } = Modal;
const { Title, Text } = Typography;
const { Option } = Select;

export const RfidCardCreationValidityFragment: React.FC<RfidCardCreationValidityFragmentProps> = (props) => {
    const { useController = useRfidCardCreationValidityController } = props;
    const { translate } = useTranslator();

    const onModalLabel = () => {
        warning({
            title: <Title level={3}>{'Etiqueta no disponible'}</Title>,
            content: (
                <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{
                        flexWrap: 'nowrap',
                        gap: '3px',
                    }}
                >
                    <Text style={{ fontSize: '1rem' }}>
                        {'Ya existe una tarjeta con la misma etiqueta, por favor elige otra'}
                    </Text>
                </Row>
            ),
            okText: 'Entendido',
            onOk: () => {
                controller.form.validateFields(['label']);
            },
            centered: true,
        });
    };

    const onModalOverwriteMsg = () => {
        warning({
            title: <Title level={4}>{translate({ key: 'rfid-card.overwritten-title' })}</Title>,
            content: (
                <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{
                        flexWrap: 'nowrap',
                        gap: '3px',
                    }}
                >
                    <Text style={{ fontSize: '0.875rem' }}>{translate({ key: 'rfid-card.overwritten-message' })}</Text>
                </Row>
            ),
            okText: 'Entendido',
            onOk: () => {
                console.log();
            },
            centered: true,
        });
    };

    const controller = useController(onModalLabel, onModalOverwriteMsg);

    const mapFields = (fields: SiteCardCustomField[]) => {
        const fieldInputs = fields.map((f) => {
            switch (f.type_id) {
                case 1:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!f.optional}
                            rules={[
                                {
                                    required: !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value}
                        >
                            <Input
                                disabled={controller.isLoading || !!controller.cardId}
                                placeholder={f.name}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{
                                    width: '100%',
                                    borderRadius: '.5rem',
                                    lineHeight: '32px',
                                }}
                            />
                        </Form.Item>
                    );
                case 2:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!controller.cardId && !f.optional}
                            rules={[
                                {
                                    required: !controller.cardId && !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value ? moment(f.value) : undefined}
                        >
                            <DatePicker
                                disabled={controller.isLoading || !!controller.cardId}
                                format="YYYY-MM-DD HH:mm"
                                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{
                                    width: '100%',
                                    borderRadius: '.5rem',
                                    lineHeight: '32px',
                                }}
                            />
                        </Form.Item>
                    );
                case 3:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!controller.cardId && !f.optional}
                            rules={[
                                {
                                    required: !controller.cardId && !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value ? f.value : []}
                        >
                            <DebounceSelect
                                mode="tags"
                                placeholder={f.name}
                                fetchOptions={(e) => controller.autocompleteTags(e, f.id)}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                className={'rfid-card-creation-form-select'}
                                value={{ label: f.value, value: f.value }}
                                tokenSeparators={[',']}
                                disabled={controller.isLoading || !!controller.cardId}
                            />
                        </Form.Item>
                    );
                case 4:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            required={!controller.cardId && !f.optional}
                            name={f.name}
                            rules={[
                                {
                                    required: !controller.cardId && !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value}
                        >
                            <Select
                                showSearch
                                placeholder={f.name}
                                allowClear
                                value={f.value}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                className={'rfid-card-creation-form-select'}
                                disabled={controller.isLoading || !!controller.cardId}
                                filterOption={(inputValue, option) => {
                                    const opt = f.default_values?.find((item) => item.value == option?.value);
                                    return !!opt?.value.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                {f.default_values?.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {v.value}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
                default:
                    return <></>;
            }
        });
        return fieldInputs;
    };

    return (
        <div className={'rfid-card-creation'}>
            <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
            <CustomHeader>{translate({ key: 'rfid-card.validity-header' })}</CustomHeader>
            <Form
                id={'create-rfid-card-form'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'rfid-card-creation-form'}
                onFinish={controller.onCreateRfidCardFormSubmit}
                form={controller.form}
                initialValues={{
                    'time-picker': [dayjs().startOf('day'), dayjs().endOf('day')],
                }}
            >
                <Form.Item
                    label={
                        <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                            {translate({ key: 'label.label' })}
                        </Title>
                    }
                    required
                    rules={[
                        {
                            required: true,
                            message: translate({ key: 'form.rules-required' }),
                        },
                        {
                            validator: async (_, value) => {
                                if (value.length < 3 && value.length != 0) {
                                    return Promise.reject(new Error('Ingrese al menos 3 letras'));
                                }
                                if (!controller.label) {
                                    if (controller.labelError) {
                                        return Promise.reject(new Error(translate({ key: 'rfid-card.label-error' })));
                                    }
                                }
                            },
                        },
                    ]}
                    name="label"
                    initialValue={controller.label}
                >
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <DebounceInput
                            disabled={!!controller.cardId || controller.isLoading}
                            onChange={controller.onLabelInputChange}
                            debounceTimeout={1000}
                            placeholder={translate({ key: 'form.placeholder.rfid-card.label' })}
                            className={'ant-input'}
                            style={{ width: '100%', borderRadius: '0.5rem', lineHeight: '32px' }}
                            value={controller.label}
                        />
                        <div style={{ height: 'fit-content', position: 'absolute', right: '12px' }}>
                            {controller.isChecking ? (
                                <LoadingOutlined
                                    style={{
                                        color: '#a9a9a9',
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Form.Item>

                <FormDurationPicker
                    disabled={controller.isLoading}
                    dateRange={controller.dateRange}
                    setDateRange={controller.setDateRange}
                />
                {controller.cyclicRfidConfigEnabled && (
                    <FormDaysSelector
                        dowBytewise={controller.dowBytewise}
                        setDowBytewise={controller.setDowBytewise}
                        disabledDaysBytewise={controller.disabledDays}
                    />
                )}
                {controller.cyclicRfidConfigEnabled && (
                    <FormTimeSlider timeRange={controller.timeRange} setTimeRange={controller.setTimeRange} />
                )}

                {!!controller.newCustomFields.length && <>{mapFields(controller.newCustomFields)}</>}

                <Row justify={'space-between'} style={{ width: '100%' }}>
                    <Col span={11}>
                        <Button
                            type="default"
                            style={{ width: '100%' }}
                            className={'rfid-card-creation-form-btn'}
                            onClick={controller.onBackButtonPressed}
                            disabled={controller.isLoading}
                        >
                            {translate({ key: 'general.back' })}
                        </Button>
                    </Col>
                    <Col span={11}>
                        <Button
                            form={'create-rfid-card-form'}
                            htmlType="submit"
                            type="primary"
                            style={{ width: '100%' }}
                            className={'rfid-card-creation-form-btn'}
                            loading={controller.isLoading}
                            disabled={controller.label.length < 3 || controller.labelError || controller.isChecking}
                        >
                            {translate({ key: 'general.finish' })}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
