import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const UpdateAppSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4680 3120">
        <defs>
            <style>
                {
                    '.update-app-cls-1{fill:none;}.update-app-cls-2,.update-app-cls-4{fill:#fff;}.update-app-cls-2{opacity:0;}.update-app-cls-3{fill:#f2dff7;}.update-app-cls-5{fill:#e0ccea;}.update-app-cls-6{fill:#d0b1dd;}.update-app-cls-7{fill:#d13364;}.update-app-cls-8{fill:#e86795;}.update-app-cls-9{opacity:0.9;}.update-app-cls-10{fill:#ffcadd;}.update-app-cls-11{opacity:0.7;}.update-app-cls-12{fill:#6c1b75;}.update-app-cls-13{opacity:0.3;}.update-app-cls-14{opacity:0.4;}.update-app-cls-15{fill:#ff8aa6;}.update-app-cls-16{fill:#aa2152;}.update-app-cls-17{fill:#ffcad2;}.update-app-cls-18{clip-path:url(#clip-path);}.update-app-cls-19{fill:#ffc336;}.update-app-cls-20{clip-path:url(#clip-path-2);}.update-app-cls-21{fill:#ffac1a;}.update-app-cls-22{fill:#f6921e;}.update-app-cls-23{clip-path:url(#clip-path-3);}.update-app-cls-24{fill:#501159;}.update-app-cls-25{fill:#370a3f;}.update-app-cls-26{fill:#6b260e;}.update-app-cls-27{clip-path:url(#clip-path-4);}.update-app-cls-28{fill:#351006;}.update-app-cls-29{fill:#f46790;}.update-app-cls-30{fill:#9c5ba5;}.update-app-cls-31{fill:#e9b3f4;}.update-app-cls-32{fill:#c688d1;}.update-app-cls-33{fill:#a86bb5;}.update-app-cls-34{clip-path:url(#clip-path-5);}.update-app-cls-35{fill:#470e4f;}.update-app-cls-36{fill:#541809;}.update-app-cls-37{fill:#783c82;}.update-app-cls-38{clip-path:url(#clip-path-6);}.update-app-cls-39{clip-path:url(#clip-path-7);}.update-app-cls-40{clip-path:url(#clip-path-8);}.update-app-cls-41{clip-path:url(#clip-path-9);'
                }
            </style>
            <clipPath id="clip-path">
                <path
                    className="update-app-cls-1"
                    d="M1367.1,1477.86s37.28,65.15,93,84.2S1595.57,1399,1595.57,1399s21.62-31.78,42-34.89,26.49-64.82,16-70.38c-7.58-4-13.75,18-16.56,30.49a1.18,1.18,0,0,1-2.32-.15c-1.08-10.13-3.72-26-9.73-25.83-6.83.23-10.9,26.55-12.37,38.3a1.18,1.18,0,0,1-2.3.21c-3.13-9.57-10-28.33-15-24.52-4.8,3.65-3.54,21.92-2.46,31.82a1.17,1.17,0,0,1-2.19.71c-3.86-6.86-10.59-17.06-14.89-13.88-6.33,4.68-.29,25.26-.29,25.26l-24.87-11.41-83.41,81.47s-36.3-44.81-38-48.28S1358.57,1396.12,1367.1,1477.86Z"
                />
            </clipPath>
            <clipPath id="clip-path-2">
                <path
                    className="update-app-cls-1"
                    d="M1172.48,1253.83s-116.78,19.8-153.93,40.83-65.81,61.52-69.79,101-23.2,139.21-23.2,139.21l139.37,1,289.91-9s45.79-110.61,101.64-114c-76.71-93.88-107.75-138.8-151.65-142.95S1172.48,1253.83,1172.48,1253.83Z"
                />
            </clipPath>
            <clipPath id="clip-path-3">
                <path
                    className="update-app-cls-1"
                    d="M1452.39,2606.32l-200.64-1.44s-1.08-35.16-2.64-86.7a3.86,3.86,0,0,0,0-.47c-.2-6.36-.4-13-.6-19.81-4-133-10.45-346.84-11.33-381.55-1.29-51-10.05-205.63-10.05-205.63l-11.55,6.5L1231,2517.58l2.59,100.35L1026.32,2623s-.63-42.61-1.63-106.9c-2.91-189.65-8.76-568-9.78-598.7-1.33-41.1,34.81-381.69,34.81-381.69l12.29-85,1.21-169.89,49.79-15.26,20.47,170.86,151-10.08,1.13-158.43,40.92,7.76s42,415.69,48.43,430.54,57.44,474,61.54,590.83c2.09,59.59,6.19,138.9,9.71,202.27C1449.56,2560.14,1452.39,2606.32,1452.39,2606.32Z"
                />
            </clipPath>
            <clipPath id="clip-path-4">
                <path
                    className="update-app-cls-1"
                    d="M1286.75,1107.09l-.51,71.89a49.3,49.3,0,0,1-49.52,48.82l-6.88,0,2.38,20.94.91,8.18,27.8,8.87s-43.43,37.29-72.87,29-55.33-29.33-55.33-29.33a68.67,68.67,0,0,0,13.46-12.05c7.1-8,5.65-49.32,5.66-49.34a30.71,30.71,0,0,1-17.39-5.33c-13.47-9.1-17.49-26.69-9-39.24,5.87-8.66,16.13-12.68,26.35-11.38l0-3.17a12.09,12.09,0,0,0,6.76-3c2.84-2.5,4.62-6,6.24-9.37a215,215,0,0,0,16.5-48.81A157.58,157.58,0,0,0,1286.75,1107.09Z"
                />
            </clipPath>
            <clipPath id="clip-path-5">
                <path
                    className="update-app-cls-1"
                    d="M936.25,1534.91s-37.26,151.45,11,186.33,193.27-53.84,193.27-53.84,52.2-6.08,59.68-17.54,1-25.36,4.36-34.44-19.69-6.78-18.7-29.8-21.21-24.33-21.21-24.33-7.75,10.33-14.88,11.74-35.57,1.76-35.57,1.76L1097.71,1608l-58.37-.42,10.38-71.88Z"
                />
            </clipPath>
            <clipPath id="clip-path-6">
                <path
                    className="update-app-cls-1"
                    d="M2897.15,1509.1s66.42,137,165.09,137,113.07-85.53,113.07-85.53l-157.67-46.72-80.5-41.7-19.31,19.78Z"
                />
            </clipPath>
            <clipPath id="clip-path-7">
                <path
                    className="update-app-cls-1"
                    d="M3567.93,1510.38l-92.87,62.6-.87.6s-238.66,10-305.11.64c-6.61-.94-11.55-2.08-14.33-3.41C3052.38,1590.55,3008,1498,3008,1498s4.94-8.63,19.74-37,60.43-167.74,101.14-192.18c12.6-7.58,32.68-13.57,54.39-18.21,13.22-2.84,27.06-5.18,40.18-7.1,34.76-5,64.48-7,64.48-7s68.95,8.11,123.46,23.13c22.08,6.1,41.79,13.3,53.58,21.59a44.73,44.73,0,0,1,4.32,3.38C3503.79,1315.5,3567.93,1510.38,3567.93,1510.38Z"
                />
            </clipPath>
            <clipPath id="clip-path-8">
                <path
                    className="update-app-cls-1"
                    d="M3338.55,1203.26c-15.42,13-21.58,9.26-22.82,7.41-1-1.5-16.19-11.78-22.07-15.73,1.68-5.22,2.24-10.41.77-15,.52-.64,1-1.28,1.42-1.92a37.92,37.92,0,0,0,21-9.52c13.67-12,17-30.61,7.43-41.45-9.36-10.62-27.62-9.86-41.2,1.51h0c-40.68,12.26-56-41.25-56-41.25a82.39,82.39,0,0,0-2.59,21.58c-19.17-12.78-14.88-32.92-10.39-44.15a1.16,1.16,0,0,0-2-1.07c-7.17,10.56-13.89,29.55-10.17,62.6l0,0c-10.08,2.06-7,11.91-7,12h0s-17.11,80.48,67.1,73c-.5,9.86-2.56,22.35-9.1,30.86,25.9,13.56,40.09,32.06,60.44,25.28s38.23-21,38.23-21S3337.32,1220.53,3338.55,1203.26Z"
                />
            </clipPath>
            <clipPath id="clip-path-9">
                <path
                    className="update-app-cls-1"
                    d="M3706.07,2505.72l-197.34,69.07s-15.07-41-32.51-88.44c-12.54-34-26.25-71.24-36.56-99C3415,2320.71,3321.25,1963,3321.25,1963s-12.33,244.21-12.33,276.28,29.6,212.14,29.6,212.14,0,15.27,5.87,57.6c3.28,23.55,8.39,55.47,16.33,97.8-78.93-17.26-229.41,29.61-229.41,29.61s-2-38.83-4.76-98.53c-7.79-170-21.63-509.56-12.5-604.5,12.33-128.27,14.8-214.61,55-359.21v0c51.31-99,14.16-323.54,14.16-323.54,13.22-2.84,27.06-5.18,40.18-7.1,33.43,63.91,49,231.06,49,231.06s108,7.4,136.09-9.87c22.33-13.71,9.15-149.8,2.81-205,22.08,6.1,41.79,13.3,53.58,21.59.87,62.09,2.59,172.23,4.32,220.45.62,17.32,2.79,42.26,5.8,71.24,9,86.59,25.65,209.43,31.2,274.11,7.4,86.34,54.27,239.28,74,301,12.63,39.44,60.56,174.67,93.47,267.1v0C3692.28,2467.24,3706.07,2505.72,3706.07,2505.72Z"
                />
            </clipPath>
        </defs>
        <title>03</title>
        <g id="_03" data-name="03">
            <rect id="_03-2" data-name="03" className="update-app-cls-2" width="4680" height="3120" />
            <path
                className="update-app-cls-3"
                d="M3378.88,1245.94a511.1,511.1,0,0,1-112.45-245l-13.5-81.89a511.14,511.14,0,0,0-300.17-385.43l-300.9-131.09a511.12,511.12,0,0,0-432.47,11.29l-1023,510.79c-213.77,106.73-325.07,347.3-268,579.32l104,423.09c57.35,233.28,269.41,395.14,509.56,388.94l473.06-12.23a509,509,0,0,1,56.19,1.64l870.11,73.43A511.12,511.12,0,0,0,3399.84,2167l115.28-161.06a511.13,511.13,0,0,0-23.76-625.65Z"
            />
            <path className="update-app-cls-4" d="M1617.75,1583.69a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1583.69Z" />
            <path className="update-app-cls-4" d="M1698.76,1583.69a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1583.69Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1611.68" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1583.69a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1583.69Z" />
            <path className="update-app-cls-4" d="M1941.82,1583.69a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1583.69Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1611.68" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1611.68" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,1660.29a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1660.29Z" />
            <path className="update-app-cls-4" d="M1698.76,1660.29a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1660.29Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1688.27" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1660.29a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1660.29Z" />
            <path className="update-app-cls-4" d="M1941.82,1660.29a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1660.29Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1688.27" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1688.27" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,1736.88a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1736.88Z" />
            <path className="update-app-cls-4" d="M1698.76,1736.88a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1736.88Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1764.87" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1736.88a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1736.88Z" />
            <path className="update-app-cls-4" d="M1941.82,1736.88a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1736.88Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1764.87" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1764.87" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,1813.48a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1813.48Z" />
            <path className="update-app-cls-4" d="M1698.76,1813.48a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1813.48Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1841.47" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1813.48a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1813.48Z" />
            <path className="update-app-cls-4" d="M1941.82,1813.48a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1813.48Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1841.47" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1841.47" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,1890.08a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1890.08Z" />
            <path className="update-app-cls-4" d="M1698.76,1890.08a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1890.08Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1918.07" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1890.08a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1890.08Z" />
            <path className="update-app-cls-4" d="M1941.82,1890.08a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1890.08Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1918.07" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1918.07" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,1966.68a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,1966.68Z" />
            <path className="update-app-cls-4" d="M1698.76,1966.68a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,1966.68Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="1994.67" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,1966.68a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,1966.68Z" />
            <path className="update-app-cls-4" d="M1941.82,1966.68a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,1966.68Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="1994.67" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="1994.67" r="27.99" />
            <path className="update-app-cls-4" d="M1617.75,2043.28a28,28,0,1,0,28,28A28,28,0,0,0,1617.75,2043.28Z" />
            <path className="update-app-cls-4" d="M1698.76,2043.28a28,28,0,1,0,28,28A28,28,0,0,0,1698.76,2043.28Z" />
            <circle className="update-app-cls-4" cx="1779.78" cy="2071.27" r="27.99" />
            <path className="update-app-cls-4" d="M1860.8,2043.28a28,28,0,1,0,28,28A28,28,0,0,0,1860.8,2043.28Z" />
            <path className="update-app-cls-4" d="M1941.82,2043.28a28,28,0,1,0,28,28A28,28,0,0,0,1941.82,2043.28Z" />
            <circle className="update-app-cls-4" cx="2022.83" cy="2071.27" r="27.99" />
            <circle className="update-app-cls-4" cx="2103.85" cy="2071.27" r="27.99" />
            <path
                className="update-app-cls-5"
                d="M1611.69,655.7a170.24,170.24,0,0,0-1.85-25l30.4-17.3a200.17,200.17,0,0,0-9-30.17l-34.91,2.11a169.47,169.47,0,0,0-17.31-29.46l18.87-29.43a203.52,203.52,0,0,0-22-22.55L1546.05,522a168.59,168.59,0,0,0-29-18.08l3-34.85a201.58,201.58,0,0,0-29.92-9.75l-18.09,29.93a169.7,169.7,0,0,0-29.42-2.56c-1.55,0-3.1,0-4.65.07l-13.5-32.25a201.74,201.74,0,0,0-31,5.23l-2.12,34.89A168.34,168.34,0,0,0,1360,508.25L1333.07,486a202.45,202.45,0,0,0-25,19.11L1322.39,537a170.06,170.06,0,0,0-21.43,26.6l-34.22-7.22a201.29,201.29,0,0,0-13.32,28.52L1281,606.43a168.11,168.11,0,0,0-6.57,33.53l-33.64,9.52c-.06,2.06-.1,4.14-.1,6.22a205.91,205.91,0,0,0,1.56,25.23l34.39,6.3a168.48,168.48,0,0,0,9.72,32.76l-25.37,24.07a202,202,0,0,0,16,27.13l33.39-10.4a170,170,0,0,0,23.82,24.46l-11.28,33.1A202.08,202.08,0,0,0,1349.54,835l24.72-24.72a167.21,167.21,0,0,0,32.5,10.59l5.39,34.55a204.59,204.59,0,0,0,30.51,2.29h.89l10.4-33.38a167.75,167.75,0,0,0,33.69-5.68l20.84,28.1a201.93,201.93,0,0,0,28.85-12.56l-6.3-34.4a169.73,169.73,0,0,0,27.15-20.72l31.49,15.18a202.74,202.74,0,0,0,19.76-24.51l-21.55-27.52a168.12,168.12,0,0,0,14.45-31l35-1.19a200.41,200.41,0,0,0,6-30.89l-31.88-14.35C1611.6,661.81,1611.69,658.77,1611.69,655.7Zm-169,83.13a83.13,83.13,0,1,1,83.13-83.13A83.13,83.13,0,0,1,1442.66,738.83Z"
            />
            <path
                className="update-app-cls-4"
                d="M1314.4,723.25a2.17,2.17,0,1,0-3.85,2,151.64,151.64,0,0,0,12.64,20.16,2.19,2.19,0,0,0,1.75.87,2.17,2.17,0,0,0,1.74-3.46A145.67,145.67,0,0,1,1314.4,723.25Z"
            />
            <path
                className="update-app-cls-4"
                d="M1424.89,800.64a145.82,145.82,0,0,1-69.67-28.85,2.17,2.17,0,0,0-2.64,3.45,150.17,150.17,0,0,0,71.75,29.7,1.32,1.32,0,0,0,.28,0,2.17,2.17,0,0,0,.28-4.32Z"
            />
            <path
                className="update-app-cls-6"
                d="M3746,661.69a191.69,191.69,0,0,0-8.51-38l30.76-25.36a230.93,230.93,0,0,0-16-32.15l-38.8,9.2a192.77,192.77,0,0,0-25.16-29.74l15.46-36.77a229.58,229.58,0,0,0-29.07-21.06L3644.56,514a191.08,191.08,0,0,0-36.13-14.58L3605,459.65c-2.3-.52-4.61-1-6.95-1.46a231.19,231.19,0,0,0-28.58-3.69L3555,491.65a190.55,190.55,0,0,0-38.78,3.83l-21.48-33.6a230,230,0,0,0-33.83,12l4.45,39.63a193.37,193.37,0,0,0-32.53,21.41l-34.64-19.77a230.66,230.66,0,0,0-24.42,26.31l22.35,33a191.37,191.37,0,0,0-18.86,34.11l-39.85-1.41a231.83,231.83,0,0,0-9.15,33.68c-.06.33-.11.66-.18,1l35.14,18.85a192.82,192.82,0,0,0-.91,38.95l-36,17.28a228.83,228.83,0,0,0,7.84,35l39.88.36a193.82,193.82,0,0,0,17.36,34.87l-23.8,32a231.27,231.27,0,0,0,23.2,27.41l35.46-18.21a191.86,191.86,0,0,0,31.57,22.87l-6.2,39.39A229.48,229.48,0,0,0,3485,904.09l23-32.61q5,1.24,10.16,2.24a195.3,195.3,0,0,0,28.39,3.33l12.82,37.77A228.61,228.61,0,0,0,3595,911.3l5.16-39.55a192.41,192.41,0,0,0,36.72-13l28.89,27.48a232.46,232.46,0,0,0,30-19.75L3682,829a192.52,192.52,0,0,0,26.5-28.56l38.37,10.9a229.25,229.25,0,0,0,17.37-31.4l-29.62-26.72a194.32,194.32,0,0,0,9.21-32.39c.33-1.74.64-3.49.93-5.23l39-8.17a228.44,228.44,0,0,0,.84-35.89Zm-98.36,40.67a94.81,94.81,0,1,1-75.18-111A94.81,94.81,0,0,1,3647.62,702.36Z"
            />
            <path
                className="update-app-cls-4"
                d="M3505.11,521.48a171.94,171.94,0,0,0-25.3,9.82,2.47,2.47,0,0,0-1.16,3.3,2.49,2.49,0,0,0,3.31,1.16,166.9,166.9,0,0,1,24.57-9.53,2.48,2.48,0,0,0-1.42-4.75Z"
            />
            <path
                className="update-app-cls-4"
                d="M3440.07,557.78a171.26,171.26,0,0,0-48.73,74,1.77,1.77,0,0,0-.08.31,2.47,2.47,0,0,0,4.78,1.24,166.36,166.36,0,0,1,47.32-71.8,2.48,2.48,0,1,0-3.29-3.7Z"
            />
            <path
                className="update-app-cls-6"
                d="M1969.49,1203.62l52.06-31.7a350.3,350.3,0,0,0-17.14-52.09l-60.69,5.47a293.1,293.1,0,0,0-31.69-50.41l31.35-52.23q-3.59-4-7.3-8a354.94,354.94,0,0,0-32.1-30.15l-51.18,33a292.24,292.24,0,0,0-51.45-30l3.48-60.84a352.77,352.77,0,0,0-52.64-15.4l-30,53.06a296.32,296.32,0,0,0-59.45-2.62l-25.19-55.49A353.39,353.39,0,0,0,1583.79,917l-1.88,60.9a291.41,291.41,0,0,0-53.9,25.34l-48.08-37.42a354.08,354.08,0,0,0-41.48,33.51c-.38.35-.74.72-1.11,1.07l26.63,54.79a293.41,293.41,0,0,0-36,47.42l-60-10.79a349.11,349.11,0,0,0-21.65,50.38l49.06,36.15a295.38,295.38,0,0,0-9.73,58.72l-58.11,18.31a354.65,354.65,0,0,0,4.18,54.71l60.21,9.21a293.67,293.67,0,0,0,18.62,56.57l-43,43.22a350,350,0,0,0,29.2,46.43l57.61-19.83q5.1,6,10.58,11.88a296.33,296.33,0,0,0,32.18,29.53l-18,58.25a349.42,349.42,0,0,0,47.38,27.62l41.8-44.35a294.24,294.24,0,0,0,57.14,16.73l11.17,59.88a352.45,352.45,0,0,0,54.82,2.41l16.4-58.66a295.13,295.13,0,0,0,58.38-11.64l37.74,47.85a349.76,349.76,0,0,0,49.63-23.33L1841,1502.23a296,296,0,0,0,40.39-31.87c2-1.86,3.92-3.74,5.84-5.64l55.63,24.82a350.32,350.32,0,0,0,33.18-43.68L1937,1399a292.74,292.74,0,0,0,23.53-54.72l60.79-3.87a353.14,353.14,0,0,0,9-54.13L1974,1263A295.46,295.46,0,0,0,1969.49,1203.62Zm-190.67,157.69a144.86,144.86,0,1,1,6.31-204.76A144.86,144.86,0,0,1,1778.82,1361.31Z"
            />
            <path
                className="update-app-cls-4"
                d="M1764.3,1009.77a263.24,263.24,0,0,0-40.12-10.49,3.73,3.73,0,0,0-3.25,1,3.62,3.62,0,0,0-1.13,2.08,3.78,3.78,0,0,0,3,4.39,254.52,254.52,0,0,1,39,10.2,3.79,3.79,0,0,0,2.49-7.15Z"
            />
            <path
                className="update-app-cls-4"
                d="M1651.28,996.49a261.72,261.72,0,0,0-126.55,47.93c-.13.1-.26.2-.38.31a3.78,3.78,0,0,0,4.81,5.82A254.07,254.07,0,0,1,1652,1004a3.78,3.78,0,0,0-.76-7.53Z"
            />
            <rect className="update-app-cls-7" x="1791.18" y="572.57" width="1161.46" height="2138.53" rx="158.38" />
            <path
                className="update-app-cls-8"
                d="M2861,684.8A158,158,0,0,0,2837,667.51a168.94,168.94,0,0,0-85.5-22.9H2657.6l-29.26,61.66H2154.57l-37.25-61.66h-125c-42.43,0-81.08,15.32-109.55,40.17-29.83,26-48.46,62.49-48.46,102.71V2437.28a129.12,129.12,0,0,0,5.16,36.14,136.23,136.23,0,0,0,20.84,42.09c28.33,38.85,77,64.61,132,64.61h759.11c86.9,0,158-64.3,158-142.84V787.49a126.87,126.87,0,0,0-1.18-17.31C2903.82,736.86,2886.52,707,2861,684.8Z"
            />
            <g className="update-app-cls-9">
                <path
                    className="update-app-cls-4"
                    d="M2843.76,699.35a152.39,152.39,0,0,0-23.21-16.85,161.61,161.61,0,0,0-82.49-22.33h-90.54l-28.23,60.11H2162.24l-35.94-60.11H2005.75c-40.94,0-78.22,14.93-105.69,39.16-28.78,25.36-46.75,60.91-46.75,100.12V2407.67a126.81,126.81,0,0,0,5,35.23,133.62,133.62,0,0,0,20.1,41c27.33,37.87,74.29,63,127.36,63h732.31c83.84,0,152.46-62.68,152.46-139.24V799.45a123.88,123.88,0,0,0-1.13-16.88C2885.05,750.1,2868.36,721,2843.76,699.35Z"
                />
            </g>
            <path
                className="update-app-cls-10"
                d="M2738.06,660.17h-90.54l-28.23,64.64H2162.24l-35.94-64.64H2005.75a153.25,153.25,0,0,0-105.69,42.11V2244.39c0,74.91,62.43,136.23,138.71,136.23h666.28c76.27,0,138.71-61.32,138.71-136.23V702.3a153.53,153.53,0,0,0-105.7-42.13Z"
            />
            <g className="update-app-cls-11">
                <path
                    className="update-app-cls-4"
                    d="M2489.41,628.16H2222.23a8.34,8.34,0,0,0-8.34,8.33V662a8.35,8.35,0,0,0,8.34,8.33h267.18a8.35,8.35,0,0,0,8.34-8.33V636.49A8.34,8.34,0,0,0,2489.41,628.16Zm2.34,33.81a2.34,2.34,0,0,1-2.34,2.33H2222.23a2.34,2.34,0,0,1-2.34-2.33V636.49a2.33,2.33,0,0,1,2.34-2.33h267.18a2.33,2.33,0,0,1,2.34,2.33Z"
                />
            </g>
            <g className="update-app-cls-11">
                <path
                    className="update-app-cls-4"
                    d="M2568.44,624.77h-31.05a9.51,9.51,0,0,0-9.5,9.5v31.05a9.52,9.52,0,0,0,9.5,9.5h31.05a9.52,9.52,0,0,0,9.5-9.5V634.27A9.51,9.51,0,0,0,2568.44,624.77Zm3.5,40.55a3.5,3.5,0,0,1-3.5,3.5h-31.05a3.5,3.5,0,0,1-3.5-3.5V634.27a3.5,3.5,0,0,1,3.5-3.5h31.05a3.5,3.5,0,0,1,3.5,3.5Z"
                />
            </g>
            <g className="update-app-cls-11">
                <rect className="update-app-cls-4" x="2207.41" y="2607.78" width="358.05" height="41.85" rx="8.25" />
            </g>
            <path
                className="update-app-cls-12"
                d="M2524.11,1396.71a164,164,0,0,0-1.77-24l29.21-16.62a192.17,192.17,0,0,0-8.6-29l-33.54,2a162.75,162.75,0,0,0-16.64-28.3l18.13-28.28a194.72,194.72,0,0,0-21.12-21.67L2461,1268.22a162.6,162.6,0,0,0-27.85-17.37l2.91-33.49a192.81,192.81,0,0,0-28.75-9.37L2390,1236.75a163.47,163.47,0,0,0-28.26-2.45c-1.5,0-3,0-4.48.06l-13-31a194.1,194.1,0,0,0-29.83,5l-2,33.53a161.72,161.72,0,0,0-30.12,13.1l-25.89-21.41a195.26,195.26,0,0,0-24,18.36l13.79,30.64a163.57,163.57,0,0,0-20.6,25.55l-32.89-6.93a194.78,194.78,0,0,0-12.79,27.4l26.45,20.73a162.5,162.5,0,0,0-6.31,32.22l-32.32,9.14c-.06,2-.1,4-.1,6a195.69,195.69,0,0,0,1.5,24.23l33,6.06a161.13,161.13,0,0,0,9.34,31.49l-24.38,23.12a194,194,0,0,0,15.33,26.07l32.08-10a163.6,163.6,0,0,0,22.9,23.5L2246.55,1553a194.4,194.4,0,0,0,25.66,16l23.76-23.75a161.07,161.07,0,0,0,31.22,10.18l5.18,33.19a195.65,195.65,0,0,0,29.33,2.21l.85,0,10-32.07a162.08,162.08,0,0,0,32.37-5.45l20,27a192.73,192.73,0,0,0,27.72-12.06l-6-33.06a162.17,162.17,0,0,0,26.08-19.91l30.27,14.59a193.9,193.9,0,0,0,19-23.56l-20.71-26.44a160.83,160.83,0,0,0,13.89-29.76l33.58-1.14a192.57,192.57,0,0,0,5.81-29.68l-30.64-13.79Q2524.11,1401.11,2524.11,1396.71Zm-162.41,79.88a79.88,79.88,0,1,1,79.88-79.88A79.88,79.88,0,0,1,2361.7,1476.59Z"
            />
            <path
                className="update-app-cls-7"
                d="M2357.78,1059.7a25.29,25.29,0,1,0,25.29,25.29A25.28,25.28,0,0,0,2357.78,1059.7Z"
            />
            <circle className="update-app-cls-7" cx="2536.35" cy="1135.55" r="25.28" />
            <path
                className="update-app-cls-7"
                d="M2637.48,1222.47a25.28,25.28,0,1,0,25.29,25.28A25.28,25.28,0,0,0,2637.48,1222.47Z"
            />
            <path
                className="update-app-cls-7"
                d="M2677.74,1391.55a25.29,25.29,0,1,0,25.29,25.28A25.28,25.28,0,0,0,2677.74,1391.55Z"
            />
            <circle className="update-app-cls-7" cx="2627.17" cy="1567.57" r="25.28" />
            <path
                className="update-app-cls-7"
                d="M2522.06,1649.13a20.69,20.69,0,1,0,20.69,20.68A20.68,20.68,0,0,0,2522.06,1649.13Z"
            />
            <path
                className="update-app-cls-7"
                d="M2357.78,1692.46a20.69,20.69,0,1,0,20.69,20.69A20.68,20.68,0,0,0,2357.78,1692.46Z"
            />
            <circle className="update-app-cls-7" cx="2180.12" cy="1657.03" r="18.91" />
            <path
                className="update-app-cls-7"
                d="M2067.84,1528.92a18.91,18.91,0,1,0,26.75,0A18.92,18.92,0,0,0,2067.84,1528.92Z"
            />
            <circle className="update-app-cls-7" cx="2046.19" cy="1398.54" r="16.32" />
            <rect className="update-app-cls-4" x="2038.94" y="1854.55" width="704.06" height="89.28" rx="17.5" />
            <rect className="update-app-cls-12" x="2060.53" y="1871.34" width="461.53" height="55.7" rx="11.29" />
            <path
                className="update-app-cls-4"
                d="M2085.17,2088.2c-10.39,0-17.18-8.5-17.18-23.11,0-14.26,7.7-22.86,17.44-22.86,5.15,0,9,2.34,12.55,5.67l8.48-10.32a30,30,0,0,0-21.38-9.23c-18,0-33.57,13.69-33.57,37.28,0,23.95,15,36.45,33.09,36.45a29.26,29.26,0,0,0,23-10.44l-8.47-10.09C2095.61,2085.32,2091,2088.2,2085.17,2088.2Z"
            />
            <polygon
                className="update-app-cls-4"
                points="2159.01 2057.06 2134.45 2057.06 2134.45 2029.66 2118.38 2029.66 2118.38 2100.77 2134.45 2100.77 2134.45 2071.09 2159.01 2071.09 2159.01 2100.77 2175.08 2100.77 2175.08 2029.66 2159.01 2029.66 2159.01 2057.06"
            />
            <polygon
                className="update-app-cls-4"
                points="2207.97 2070.9 2232.22 2070.9 2232.22 2057.42 2207.97 2057.42 2207.97 2043.14 2236.45 2043.14 2236.45 2029.66 2191.9 2029.66 2191.9 2100.77 2237.55 2100.77 2237.55 2087.29 2207.97 2087.29 2207.97 2070.9"
            />
            <path
                className="update-app-cls-4"
                d="M2282,2088.2c-10.4,0-17.19-8.5-17.19-23.11,0-14.26,7.7-22.86,17.44-22.86,5.16,0,9,2.34,12.56,5.67l8.47-10.32a30,30,0,0,0-21.38-9.23c-18,0-33.57,13.69-33.57,37.28,0,23.95,15.06,36.45,33.09,36.45a29.24,29.24,0,0,0,23-10.44l-8.47-10.09C2292.46,2085.32,2287.86,2088.2,2282,2088.2Z"
            />
            <polygon
                className="update-app-cls-4"
                points="2370.56 2029.66 2352.89 2029.66 2331.69 2058.72 2331.29 2058.72 2331.29 2029.66 2315.22 2029.66 2315.22 2100.77 2331.29 2100.77 2331.29 2081.72 2339.6 2070.73 2356.89 2100.77 2374.51 2100.77 2349.05 2058.04 2370.56 2029.66"
            />
            <rect className="update-app-cls-4" x="2382.28" y="2029.66" width="16.07" height="71.11" />
            <path
                className="update-app-cls-4"
                d="M2455.59,2054.78c0,8.39,1.3,18.35,2.06,25.87h-.44l-7-15.62-18.55-35.37h-16.47v71.11h15.31v-25.24c0-8.38-1.3-17.9-2-25.75h.43l7,15.54,18.55,35.45h16.48v-71.11h-15.33Z"
            />
            <path
                className="update-app-cls-4"
                d="M2516.61,2073.8h11.78v12.28c-1.73,1.37-4.69,2.12-7.53,2.12-13.39,0-20-8.5-20-23.11,0-14.26,7.77-22.86,18.71-22.86,6.11,0,9.9,2.41,13.43,5.67l8.47-10.32a30.85,30.85,0,0,0-22.43-9.23c-19,0-34.66,13.69-34.66,37.28,0,23.95,15.24,36.45,34.82,36.45,9.92,0,18.57-3.82,23.47-8.61v-32.79h-26.06Z"
            />
            <circle className="update-app-cls-4" cx="2586.09" cy="2087.34" r="12.64" />
            <path
                className="update-app-cls-4"
                d="M2649.3,2074.7a12.64,12.64,0,1,0,12.64,12.64A12.64,12.64,0,0,0,2649.3,2074.7Z"
            />
            <path
                className="update-app-cls-4"
                d="M2712.51,2074.7a12.64,12.64,0,1,0,12.64,12.64A12.64,12.64,0,0,0,2712.51,2074.7Z"
            />
            <g className="update-app-cls-13">
                <path
                    className="update-app-cls-4"
                    d="M2126.3,660.17H2005.75c-40.94,0-78.22,14.93-105.69,39.16-28.78,25.36-46.75,60.91-46.75,100.12v351.09L2255,720.28h-92.73Z"
                />
                <path
                    className="update-app-cls-4"
                    d="M2843.76,699.35a152.39,152.39,0,0,0-23.21-16.85,161.61,161.61,0,0,0-82.49-22.33h-54.23l-830.52,889.68v342.61L2889.39,782.57C2885.05,750.1,2868.36,721,2843.76,699.35Z"
                />
                <path
                    className="update-app-cls-4"
                    d="M1878.39,2483.93,2890.52,1399.66v-62.52L1858.29,2442.9A133.62,133.62,0,0,0,1878.39,2483.93Z"
                />
                <polygon
                    className="update-app-cls-4"
                    points="1853.31 1204.68 1853.31 1343.06 2434.66 720.28 2305.5 720.28 1853.31 1204.68"
                />
            </g>
            <ellipse className="update-app-cls-3" cx="1125.47" cy="2669.71" rx="436.66" ry="66.78" />
            <ellipse className="update-app-cls-3" cx="3554.53" cy="2674.15" rx="436.66" ry="66.78" />
            <path
                className="update-app-cls-7"
                d="M1375.22,2582.41l-116,11-3.09,21.6-12.31,86.14,200,7.38,202.85,7.47S1622.64,2673.58,1375.22,2582.41Z"
            />
            <polygon
                className="update-app-cls-4"
                points="1243.79 2701.13 1239.55 2730.83 1466.32 2733.23 1642.37 2735.08 1646.62 2715.98 1443.78 2708.51 1243.79 2701.13"
            />
            <path
                className="update-app-cls-4"
                d="M1407.29,2591.62c-12.92,12.76-21.67,28.72-31.93,43.52-1.84,2.65,2.49,5.15,4.32,2.52,10-14.45,18.53-30,31.15-42.5C1413.12,2592.89,1409.59,2589.36,1407.29,2591.62Z"
            />
            <path
                className="update-app-cls-4"
                d="M1432.57,2599.12c-12.92,12.75-21.67,28.71-31.93,43.52-1.84,2.65,2.49,5.15,4.32,2.52,10-14.45,18.53-30,31.15-42.51C1438.4,2600.39,1434.86,2596.85,1432.57,2599.12Z"
            />
            <path
                className="update-app-cls-4"
                d="M1460.3,2608.58c-12.92,12.76-21.67,28.72-31.94,43.52-1.83,2.65,2.5,5.16,4.32,2.53,10-14.46,18.54-30.06,31.15-42.51C1466.13,2609.85,1462.59,2606.32,1460.3,2608.58Z"
            />
            <g className="update-app-cls-14">
                <path d="M1443.77,2708.51c-19.11-18.21-41.22-33.6-64.4-46.18-38.78-21-80.86-34.9-123.27-47.34l-12.31,86.14-4.24,29.7,226.77,2.4A234.3,234.3,0,0,0,1443.77,2708.51Z" />
            </g>
            <path
                className="update-app-cls-7"
                d="M1152.71,2592l-116.06,11-15.39,107.76,402.84,14.84S1400.11,2683.12,1152.71,2592Z"
            />
            <polygon
                className="update-app-cls-4"
                points="1017.02 2740.37 1419.86 2744.61 1424.1 2725.53 1021.26 2710.69 1017.02 2740.37"
            />
            <path
                className="update-app-cls-4"
                d="M1184.77,2601.16c-12.92,12.76-21.67,28.72-31.93,43.52-1.84,2.65,2.49,5.16,4.32,2.53,10-14.46,18.53-30.06,31.15-42.51C1190.6,2602.43,1187.06,2598.9,1184.77,2601.16Z"
            />
            <path
                className="update-app-cls-4"
                d="M1210.05,2608.66c-12.92,12.75-21.67,28.72-31.93,43.52-1.84,2.65,2.49,5.15,4.31,2.52,10-14.45,18.54-30,31.15-42.51C1215.88,2609.93,1212.34,2606.39,1210.05,2608.66Z"
            />
            <path
                className="update-app-cls-4"
                d="M1237.78,2618.12c-12.92,12.76-21.67,28.72-31.94,43.52-1.83,2.65,2.5,5.16,4.32,2.53,10-14.46,18.53-30.06,31.15-42.51C1243.6,2619.39,1240.07,2615.86,1237.78,2618.12Z"
            />
            <path
                className="update-app-cls-15"
                d="M1577.09,1259.87c-11.1,15.8-14.23,52.28-14.23,52.28l25.34-8.41S1589.33,1242.44,1577.09,1259.87Z"
            />
            <path
                className="update-app-cls-16"
                d="M1677.69,1267.81l-32.29-28.12a14.38,14.38,0,0,0-20.23,1.4L1548,1329.68a7.9,7.9,0,0,0,.77,11.1l41.22,35.89a9,9,0,0,0,12.66-.88l76.4-87.76A14.37,14.37,0,0,0,1677.69,1267.81Z"
            />
            <path
                className="update-app-cls-7"
                d="M1672.26,1263.08l-31.18-27.15a15.13,15.13,0,0,0-21.27,1.47L1547.9,1320a15.13,15.13,0,0,0,1.47,21.27l31.18,27.15a15.12,15.12,0,0,0,21.27-1.48l71.91-82.59A15.12,15.12,0,0,0,1672.26,1263.08Z"
            />
            <path
                className="update-app-cls-4"
                d="M1649,1244.31a2.39,2.39,0,0,0-1.71-.57,2.28,2.28,0,0,0-1.61.8l-.89,1a2.34,2.34,0,0,0-.58,1.71,2.36,2.36,0,0,0,.8,1.61l12.57,10.94a2.36,2.36,0,0,0,1.54.58h.17a2.36,2.36,0,0,0,1.61-.8l.89-1a2.34,2.34,0,0,0-.23-3.31Zm-1.36,1.55Z"
            />
            <path
                className="update-app-cls-4"
                d="M1641.58,1241.48h0a3.33,3.33,0,0,0,2.5,5.51h.24a3.32,3.32,0,0,0,3.08-3.55,3.28,3.28,0,0,0-1.13-2.27A3.32,3.32,0,0,0,1641.58,1241.48Zm2.06,2.69a.66.66,0,0,1-.23-.46.67.67,0,0,1,.17-.49h0l1,.89A.68.68,0,0,1,1643.64,1244.17Zm1-.06-1-.89a.63.63,0,0,1,.46-.23.68.68,0,0,1,.49.16A.69.69,0,0,1,1644.6,1244.11Z"
            />
            <rect
                className="update-app-cls-4"
                x="1648.31"
                y="1305.91"
                width="22.81"
                height="1.78"
                rx="0.3"
                transform="translate(-415.7 1700.51) rotate(-48.96)"
            />
            <path
                className="update-app-cls-4"
                d="M1669.4,1270.37l-8.08-7-2.49,1.24a1.92,1.92,0,0,1-2.12-.27l-18-15.64a1.91,1.91,0,0,1-.62-1.84l.64-3.12-5.19-4.52a7.46,7.46,0,0,0-10.49.63l-69,79.22a7.46,7.46,0,0,0,.64,10.32l.18.16,35.82,31.18.19.16a7.45,7.45,0,0,0,10.3-.78l69-79.22A7.47,7.47,0,0,0,1669.4,1270.37Z"
            />
            <path
                className="update-app-cls-17"
                d="M1653.62,1293.68c-7.58-4-13.75,18-16.56,30.49a1.18,1.18,0,0,1-2.32-.15c-1.08-10.13-3.72-26-9.73-25.83-6.83.23-10.9,26.55-12.37,38.3a1.18,1.18,0,0,1-2.3.21c-3.13-9.57-10-28.33-15-24.52-4.8,3.65-3.54,21.92-2.46,31.82a1.17,1.17,0,0,1-2.19.71c-3.86-6.86-10.59-17.06-14.89-13.88-6.33,4.68-.29,25.26-.29,25.26l-24.87-11.41-83.41,81.47s-36.3-44.81-38-48.28-70.67,18.25-62.14,100c0,0,37.28,65.15,93,84.2S1595.57,1399,1595.57,1399s21.62-31.78,42-34.89S1664.06,1299.24,1653.62,1293.68Z"
            />
            <g className="update-app-cls-18">
                <path
                    className="update-app-cls-15"
                    d="M1455.27,1274.91c-20.71.18-40.26,9.75-57.26,21.59a217.29,217.29,0,0,0-86.91,129.41l53.39,77.76c6.28-27.83,28.85-51.34,56.4-58.76,11.26-3,23.32-3.62,33.71-8.89,14.79-7.51,23.53-23.06,30.4-38.15a381.52,381.52,0,0,0,17.2-45.19c4.76-15.39,8.51-32.26,2.85-47.35C1497.78,1285.94,1476,1274.73,1455.27,1274.91Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1465.69,1535.85c-28.29-.9-53,26.29-64.34,52.23l18.06.83a60,60,0,0,0,89.89-15.86C1516.25,1561.23,1509.1,1537.25,1465.69,1535.85Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1576,1328.58c.07-2,.23-4.1-.89-5.72-1.23-1.8-3.72-2.36-5.86-1.92a16.83,16.83,0,0,0-5.82,2.9l-14.42,9.71-4.5,6.32a177.23,177.23,0,0,1,31.48,17.61c1.11.79,2.37,1.62,3.72,1.44,1.81-.24,2.91-2.29,2.81-4.12a13.36,13.36,0,0,0-1.88-5.11A43.73,43.73,0,0,1,1576,1328.58Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1597.89,1317.65a27.79,27.79,0,0,0,.56-7.37,7.76,7.76,0,0,0-3.67-6.11c-2.78-1.47-6.36-.32-8.6,1.89s-3.42,5.25-4.49,8.2l2.34,13.58a76,76,0,0,0,14.9,22.55A90.12,90.12,0,0,1,1597.89,1317.65Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1623.25,1286.92a13.25,13.25,0,0,0-9.07,4.79,36.74,36.74,0,0,0-5.37,9l-.69,17.84a69.58,69.58,0,0,0,6.51,26.12,118.71,118.71,0,0,1,9.43-36c2.22-5,4.83-10.11,5.09-15.62a6.6,6.6,0,0,0-.81-4A5.8,5.8,0,0,0,1623.25,1286.92Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1648.47,1285a23.57,23.57,0,0,0-10.88,11.31l-4.65,17.33a88.28,88.28,0,0,0,2.86,23.16,84.51,84.51,0,0,1,3.66-15.09c3.93-10.4,11.81-18.88,16.28-29.06,1.1-2.51,1.85-5.8-.14-7.68C1653.76,1283.24,1650.74,1283.89,1648.47,1285Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1670.63,1342.56q-3.15,3.35-6.29,6.68A14.44,14.44,0,0,0,1670.63,1342.56Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1630.32,1359.83c-2.4,2.25-4.14,5.29-7,6.85-5.11,2.75-12.24,0-16.75,3.7-3.34,2.71-3.59,7.6-4,11.88a63.93,63.93,0,0,1-8,25.13l15.44-9.16c7.63,0,13.77-5.94,19-11.5l35.34-37.49a35,35,0,0,1-12.7,3.89C1644.12,1354.13,1635.85,1354.64,1630.32,1359.83Z"
                />
            </g>
            <path
                className="update-app-cls-19"
                d="M1304.83,1269.9c-43.9-4.16-132.35-16.07-132.35-16.07s-116.78,19.8-153.93,40.83-65.81,61.52-69.79,101-23.2,139.21-23.2,139.21l139.37,1,289.91-9s45.79-110.61,101.64-114C1379.76,1319,1348.73,1274.05,1304.83,1269.9Z"
            />
            <g className="update-app-cls-20">
                <path
                    className="update-app-cls-21"
                    d="M1479,1359.41a85.34,85.34,0,0,0-61.92,9.28c-7.37,4.3-14.36,9.83-22.74,11.42-16.09,3-31.49-10-37.85-25.06s-6.25-32-8.06-48.23-6.39-33.57-19.14-43.84c-12-9.67-28.71-11-42.17-18.56a316.09,316.09,0,0,1,16.49,146.71c-1.08,8.64-2.67,17.6-7.81,24.64-7.27,10-20,14-32.07,16.84a346.39,346.39,0,0,1-42.31,7.17c-15.56,1.66-33.22,1.56-44.37-9.41-12.12-11.93-29.59-27.73-31.91-44.56-6.6-47.9-10-125.1-24.13-171.35-17.68,9.06-52.7,48.86-72.54,48,7.63,52.21,15.11,106.56-.79,156.88-5.9,18.69-15.59,37.11-31.75,48.21s-39.85,12.81-54.63-.07c-12.83-11.19-16-29.71-16.86-46.72s-.39-34.92-8.53-49.88l-15.1,227.62c9.36,4.08,20.09,1.9,30.13.12a338.49,338.49,0,0,1,175.13,15.52c34.13,12.57,66.88,30.83,103,34.76,31.44,3.43,63.1-4.35,92.79-15.26,43.7-16.06,87.47-41.78,106.9-84.09,10.78-23.46,13-49.78,18.48-75a293.23,293.23,0,0,1,36.39-90.8c4-6.59,8.09-15.86,2.47-21.17C1484.23,1360.8,1481.58,1360,1479,1359.41Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M1130,1342.56c-9.12-53.26,3.43-129.26-40-161.38l-31.3,16.59c16.92,38.89,26.06,80.69,33.18,122.5a1394.5,1394.5,0,0,1,19.32,198.13l26-7.23c2.32-.64,4.78-1.38,6.28-3.25,1.66-2.08,1.69-5,1.63-7.63A1068.3,1068.3,0,0,0,1130,1342.56Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M1345.38,1400.64c-2.58-28.36-8.31-51.84-8.17-90.48.09-28.48,14.2-103.6-9.65-119.16l-36.82,62.47a734.07,734.07,0,0,1,28.57,304.33c13.08,1.25,29.49.74,35.14-11.12,1.81-3.81,123.78-127.28,124-131.49C1480.59,1368.33,1349.62,1447.36,1345.38,1400.64Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M969.35,1506.15c-6.6-1.18-13.33-2.71-18.91-6.42-12-7.93-15.77-23.43-18.64-37.48l-13-3.6a269.24,269.24,0,0,0-21.46,91.55c-.13,2.29-.2,4.69.81,6.75,2.59,5.26,10,5.1,15.81,4.26a509.53,509.53,0,0,1,139.33-1c5.08.66,10.43,1.38,15.19-.53,4.22-1.7,7.39-5.23,10.24-8.78a151.61,151.61,0,0,0,29.72-61.88A238.88,238.88,0,0,1,969.35,1506.15Z"
                />
            </g>
            <path
                className="update-app-cls-12"
                d="M1436.49,2297c-4.1-116.79-55.08-576-61.54-590.83s-48.43-430.54-48.43-430.54l-40.92-7.76-1.13,158.43-151,10.08L1113,1265.56l-49.79,15.26L1062,1450.71l-12.29,85s-36.14,340.59-34.81,381.69c1,30.72,6.87,409,9.78,598.7,1,64.29,1.63,106.9,1.63,106.9l207.25-5.08L1231,2517.58l-15.42-600.36,11.55-6.5s8.76,154.65,10.05,205.63c.88,34.71,7.32,248.56,11.33,381.55.2,6.83.4,13.45.6,19.81a3.86,3.86,0,0,1,0,.47c1.56,51.54,2.64,86.7,2.64,86.7l200.64,1.44s-2.83-46.18-6.19-107C1442.68,2435.94,1438.58,2356.63,1436.49,2297Z"
            />
            <g className="update-app-cls-23">
                <path
                    className="update-app-cls-24"
                    d="M1055.81,1623c3.18-14.3,7.92-28.42,11.07-42.76,6.88-31.2,6.21-63.5,5.52-95.44q-2.49-114.79-5-229.59l6.22-34.67c-15-.26-32.79.86-40.85,13.54-3.49,5.5-4.38,12.2-5.18,18.66-8.33,67.13-16.68,134.65-13.64,202.23,1.84,40.83,7.82,81.4,9.27,122.25,5,141.38-44.38,281.37-32.34,422.33a429.48,429.48,0,0,1,63.8-180.67c5-7.86,10.31-15.73,12.75-24.71,2.67-9.81,1.7-20.19,2.16-30.35a105.18,105.18,0,0,1,2-16,87.82,87.82,0,0,0,25.12,18.06c20.68,9.73,44.35,10.52,67.2,9.89,25.88-.72,52.33-3.21,75.8-14.14-45.84-13.21-92.92-27-130.55-56.38C1083,1684.79,1062.25,1654.89,1055.81,1623Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M1386.17,1604.06c-20.12-136.76-18-275.76-30-413.47l-40.15,67q2,106.46,4,212.9c.62,33.1,1.26,66.36,7,99,4,23,10.64,45.88,10.52,69.27a122.6,122.6,0,0,1-21.8,68.92c-2.58,3.71-5.52,7.8-4.9,12.28a10.69,10.69,0,0,0,2.66,5.44,99.54,99.54,0,0,1-26.19,23,73.2,73.2,0,0,0,39.39-11.47c8.62,8.45,15.16,18.77,20.61,29.84-10.36,13.83-26.92,23.78-42.74,31.9-36.11,18.55-76.76,34.78-116.41,26,17.88,5,36.57,6.27,55.1,7.36,28.48,1.66,58.27,2.69,84.14-9.33a73.56,73.56,0,0,0,31.51-28.33l1.11,2.93q33.4,89.22,66.8,178.45A1169.77,1169.77,0,0,0,1416,1780.71C1407.68,1721.57,1394.87,1663.14,1386.17,1604.06Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M1301.81,1232.53,1162.92,1235l-68,15.54a970.64,970.64,0,0,1,26.46,195.36c.14,4.22.52,9,3.82,11.65,2.84,2.26,6.85,2.1,10.48,1.83l143.93-10.83c3-.23,6.31-.62,8.21-2.93,1.42-1.72,1.68-4.08,1.88-6.29q9.08-99.73,18.16-199.46c.21-2.27.25-4.94-1.53-6.35C1305.13,1232.51,1303.4,1232.5,1301.81,1232.53Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M1393.29,2431.71c-68-19.73-126.85-532.51-126.85-532.51l-50.88,18s-5.86,502.16-35.28,529.29-200,37.42-200,37.42L968,2683.32l566.77-13.61s-16.68-171.39-22.23-185.78S1461.31,2451.45,1393.29,2431.71Z"
                />
                <path
                    className="update-app-cls-25"
                    d="M1035.68,2550.55l11.61,17.89c1-3,1.8-6.06,2.56-9.12C1044.26,2557.51,1039.31,2554.73,1035.68,2550.55Z"
                />
                <path
                    className="update-app-cls-25"
                    d="M1035.68,2550.55l-3.62-5.59A23.66,23.66,0,0,0,1035.68,2550.55Z"
                />
                <path
                    className="update-app-cls-25"
                    d="M1170.41,2488.11c-3.86,16.36-8.26,33.33-19.23,46.07s-30.54,19.73-45.08,11.29q-30.63-119.25-52.25-240.58,1,86.63,2,173.27c.31,27.2.54,54.88-6,81.16,8.74,2.82,19,3.27,28.23,3q51.36-1.58,102.48-7.2c10.58-1.17,21.69-2.68,30.18-9.1,12.64-9.56,15.81-26.94,17.74-42.67,20.22-164.87,7.85-331.8-4.54-497.45A2008.7,2008.7,0,0,1,1170.41,2488.11Z"
                />
            </g>
            <path
                className="update-app-cls-26"
                d="M1296.57,1070.73s1.32-19.6-9.92-26.81c-1.12-8.93-15.35-13.19-15.08-14.94s-18.08-25.71-57-9.94c-48.84-40.17-82.08,29.69-84.79,30.88-122.21,53.8,6.28,118.9,6.28,118.9s120.35-44.31,153.31-59.52S1296.57,1070.73,1296.57,1070.73Z"
            />
            <path
                className="update-app-cls-17"
                d="M1181.35,1083.7a215,215,0,0,1-16.5,48.81c-1.62,3.41-3.4,6.87-6.24,9.37a12.09,12.09,0,0,1-6.76,3l0,3.17c-10.22-1.3-20.48,2.72-26.35,11.38-8.51,12.55-4.49,30.14,9,39.24a30.7,30.7,0,0,0,17.38,5.33s1.45,41.29-5.65,49.34a68.67,68.67,0,0,1-13.46,12.05s25.88,21,55.33,29.33,72.87-29,72.87-29l-27.8-8.87-.91-8.18-2.38-20.94,6.88,0a49.3,49.3,0,0,0,49.52-48.82l.51-71.89A157.58,157.58,0,0,1,1181.35,1083.7Z"
            />
            <g className="update-app-cls-27">
                <path
                    className="update-app-cls-15"
                    d="M1242.14,1041.24c-31.69-7.72-65.54-7.67-96.59,2.3s-59.12,30.15-76.66,57.65l81.33,47.21a82,82,0,0,0,37.36-41.52,153.51,153.51,0,0,0,111,13.25c7.33-1.94,15.41-5.26,18-12.4,1.88-5.28.11-11.15-2.29-16.22C1301.3,1064.11,1271.59,1048.42,1242.14,1041.24Z"
                />
            </g>
            <path
                className="update-app-cls-15"
                d="M1189.2,1216.06c-15.18-9.92,8.77,29.65,43,32.63l-2.38-20.94S1205.32,1226.58,1189.2,1216.06Z"
            />
            <path
                className="update-app-cls-28"
                d="M1262.62,1133.64a6.85,6.85,0,1,0,6,7.61A6.85,6.85,0,0,0,1262.62,1133.64Z"
            />
            <path
                className="update-app-cls-28"
                d="M1216.1,1133.31a6.85,6.85,0,1,0,6,7.61A6.86,6.86,0,0,0,1216.1,1133.31Z"
            />
            <path
                className="update-app-cls-29"
                d="M1187.73,1149.63a15.3,15.3,0,1,0,16,14.6A15.31,15.31,0,0,0,1187.73,1149.63Z"
            />
            <path
                className="update-app-cls-15"
                d="M1153.08,1169.42a10.89,10.89,0,0,0-7.93-.52,20.32,20.32,0,0,1,6.34,3.22,12.44,12.44,0,0,1,3.79,5,25,25,0,0,1,1.69,6.49c.34,2.35.5,4.8.86,7.4a19.32,19.32,0,0,0,2.29-7.45,16.21,16.21,0,0,0-1.18-8.06A11.69,11.69,0,0,0,1153.08,1169.42Z"
            />
            <path
                className="update-app-cls-15"
                d="M1153.62,1175.42l-.57-.16-.6,0a6.53,6.53,0,0,0-1.25,0c-.42.07-.84.19-1.26.3a9.59,9.59,0,0,0-1.2.6,6.35,6.35,0,0,0-1,.83,7.06,7.06,0,0,0-2,4.37,6.82,6.82,0,0,0,1,4.33c.81-1.31,1.42-2.44,2-3.44a12,12,0,0,1,1.8-2.42,5.85,5.85,0,0,1,2.26-1.45l.38-.17.41-.12c.29-.08.58-.18.89-.28a19.63,19.63,0,0,0,2-.59,7.48,7.48,0,0,0-1.8-1.36C1154.35,1175.67,1154,1175.54,1153.62,1175.42Z"
            />
            <path
                className="update-app-cls-28"
                d="M1279.56,1101.14c-5-.23-14.86-.41-21.11,1.42-6.78,2-8.77,3.07-10.86,9.17a3.07,3.07,0,0,0,4,3.89c14.94-5.54,21.71-4.62,24.35-3.65a3,3,0,0,0,3.19-.71,12.71,12.71,0,0,0,3.31-6.45A3.06,3.06,0,0,0,1279.56,1101.14Z"
            />
            <path
                className="update-app-cls-28"
                d="M1205.91,1107.67c-6.51-.08-16,2.76-20.71,4.32a3.06,3.06,0,0,0-1.75,4.31,12.67,12.67,0,0,0,4.92,5.31,3,3,0,0,0,3.26-.17c2.29-1.65,8.55-4.36,24.44-3a3.08,3.08,0,0,0,2.77-4.82C1215.19,1108.26,1213,1107.76,1205.91,1107.67Z"
            />
            <path
                className="update-app-cls-15"
                d="M1253.1,1157.72l-20.56,11.49a1.59,1.59,0,0,0,.76,3l16.17.12a1.61,1.61,0,0,0,1.5-1l4.39-11.61A1.59,1.59,0,0,0,1253.1,1157.72Z"
            />
            <path
                className="update-app-cls-4"
                d="M1245.56,1189.1a70.15,70.15,0,0,1-22.74-1.09c-15.66-3.37-10.27,16.22,7.81,16,11.91-.15,16.87-5.22,18.85-9.8A3.71,3.71,0,0,0,1245.56,1189.1Z"
            />
            <path
                className="update-app-cls-30"
                d="M1188.54,1652.76l-25.88-91.07c21.12-13.5,32.29-37.35,25.88-59.92-6.76-23.76-30.81-38.51-57.43-37.32h-.34l14.13,49.7-30.86,8.78L1100,1473.42l-.69.4c-22.55,13.17-34.71,37.94-28.1,61.27,6.2,21.79,27,36,50.92,37.28l26.32,92.62c-19.71,13.69-29.9,36.68-23.71,58.49,6.71,23.58,30.49,38.29,56.91,37.32l-1.53-5.37v0l-12.53-44.1,30.83-8.76,14.14,49.7c.76-.42,1.53-.86,2.27-1.33,22-13.29,33.79-37.72,27.23-60.78C1235.65,1667.56,1213.6,1653.13,1188.54,1652.76Z"
            />
            <g className="update-app-cls-11">
                <path
                    className="update-app-cls-31"
                    d="M1130.77,1464.45l14.13,49.7-30.86,8.78L1100,1473.42l-.69.4,12.56,62,44.77-16-25.51-55.37Z"
                />
            </g>
            <g className="update-app-cls-11">
                <path
                    className="update-app-cls-31"
                    d="M1156.62,1713.89l23.48,41.51-12.53-44.1,30.83-8.76,14.14,49.7c.76-.42,1.53-.86,2.27-1.33l-3.45-55.94Z"
                />
            </g>
            <path
                className="update-app-cls-32"
                d="M1019.84,2518.05c.07,3,.14,6,.22,8.95.64,28.05,1.16,51.46,1.55,68.38.42,18.82.67,29.56.67,29.56l211.62-6.57-3.38-100.32Z"
            />
            <path
                className="update-app-cls-33"
                d="M1019.84,2518.05c.07,3,.14,6,.22,8.95,59,2.37,187.94,10.78,187.94,10.78l-120.4,13.94,120.4,9.5s-6.91,36.33-67.37,49.14c-48.59,10.26-84.43,1.25-119-15,.42,18.82.67,29.56.67,29.56l211.62-6.57-3.38-100.32Z"
            />
            <path
                className="update-app-cls-32"
                d="M1454.28,2560.82c-.1-1.6-.22-3.23-.32-4.9-.61-9.18-1.28-19.37-2-30.32,0-.74-.1-1.48-.14-2.22-.37-5.77-.77-11.77-1.16-17.91-.1-1.46-.2-2.89-.3-4.37,0-.93-.12-1.9-.17-2.86H1248.26q.4,10.26.76,19.81a2.58,2.58,0,0,1,0,.47c.52,13.47,1,25.8,1.4,36.68,1.19,30.81,1.93,50,1.93,50h204.91s-.86-12.33-2.22-32.63C1454.83,2568.92,1454.55,2565,1454.28,2560.82Z"
            />
            <path
                className="update-app-cls-33"
                d="M1313.31,2532.85c-11.87-14.58,85.5-26.52,137-31.75,0-.93-.12-1.9-.17-2.86H1248.26q.4,10.26.76,19.81a2.58,2.58,0,0,1,0,.47c.52,13.47,1,25.8,1.4,36.68,1.19,30.81,1.93,50,1.93,50h26.69c46-7.89,131.9-22.92,176-32.63l-.79-11.75C1408.33,2556.38,1326,2548.49,1313.31,2532.85Z"
            />
            <path
                className="update-app-cls-17"
                d="M1185.86,1585.62c1-23-21.21-24.33-21.21-24.33s-7.75,10.33-14.88,11.74-35.57,1.76-35.57,1.76L1097.71,1608l-58.37-.42,10.38-71.88-113.47-.81s-37.26,151.45,11,186.33,193.27-53.84,193.27-53.84,52.2-6.08,59.68-17.54,1-25.36,4.36-34.44S1184.87,1608.64,1185.86,1585.62Z"
            />
            <g className="update-app-cls-34">
                <path
                    className="update-app-cls-15"
                    d="M904.62,1691.26l-10,31.91c-1.9,6,.61,12.76,4.89,17.39s10.08,7.54,15.85,10.08c15.57,6.84,32.34,11.77,49.33,11.23,8-.26,16.9-2.3,21.28-9,5-7.58,2.07-17.84-2.59-25.61C967.74,1701.17,934.93,1688.8,904.62,1691.26Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1043.55,1467.85c-19.74-7-41.85-5-61.61,1.89s-37.53,18.56-54.44,30.92l-6.64,37.47q43.65,6,86.91,14.37c5.91,1.15,12.32,2.63,16.1,7.32,2,2.46,3,5.53,4,8.53q6.8,20.93,13.58,41.87,12.49-35,25-69.92c5.8-16.26,11.54-34.58,4.23-50.21C1065.53,1479.23,1054.84,1471.87,1043.55,1467.85Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1164.45,1544.11c-4.3-1.76-9.14-1.63-13.76-1.1-12.92,1.48-25.35,5.88-37.22,11.21l-10.69,22.08c11.49-2.48,22.09,6.1,33.24,9.82,3.68,1.23,7.72,1.93,11.4.73,4.46-1.46,7.59-5.37,10.44-9.09l9.55-12.44c2.46-3.2,5-6.72,5-10.75C1172.41,1549.92,1168.76,1545.87,1164.45,1544.11Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M1204.17,1566.43l-22.48,1.33-3.27,25.81c-.32,2.5-.63,5.1.17,7.48,2.45,7.35,12.94,8,18,13.86,5,5.68,3.44,14.32,1.64,21.63q-4.12,16.82-8.26,33.61a137.2,137.2,0,0,0,19.27-19.73,79.66,79.66,0,0,0,11.15-17.82C1230.11,1610.22,1223.13,1581.78,1204.17,1566.43Z"
                />
            </g>
            <path
                className="update-app-cls-12"
                d="M1224.11,982c-22.4-10-50.35-11.86-84.32,3.45-21.38,9.65-36.58,21.66-47.18,34.74-.52.64-1,1.28-1.53,1.95s-1,1.33-1.51,2v0c-.62.84-1.21,1.7-1.8,2.56-20.55,30-19.26,64.31-14.45,88a143.61,143.61,0,0,0,9,29.18l91.94-33.79s64.18-16.13,68.47-17.24c14.51-3.7,53.78-25.81,53.78-25.81C1295.62,1064.31,1273.17,1003.83,1224.11,982Z"
            />
            <path
                className="update-app-cls-35"
                d="M1224.11,982c7.5,12.06,12.78,25.36,12.78,39.42,0,20.18-14.43,41.37-34.59,42.75-15.32,1.06-28.91-8.83-42.08-16.75a194.87,194.87,0,0,0-69.14-25.28l-3.31,4.58a140.42,140.42,0,0,1,23.31,32.49c5.48,10.58,9.47,23.85,3.16,34-4.44,7.15-12.83,10.6-20.67,13.66-6.76,2.62-13.5,5.26-20.25,7.87a143.61,143.61,0,0,0,9,29.18l91.94-33.79s64.18-16.13,68.47-17.24c14.51-3.7,53.78-25.81,53.78-25.81C1295.62,1064.31,1273.17,1003.83,1224.11,982Z"
            />
            <path
                className="update-app-cls-30"
                d="M1092,1020.17c-1,2-1.71,2.66-2.44,4l0,0c-.22.38-.45.84-.7,1.39,56.43-.29,119.87,54.7,137.23,70.72l17.51-5.61c-14.81-14.36-78-66.27-151-70.46Z"
            />
            <path
                className="update-app-cls-30"
                d="M1097.15,1166.76c5.49,6.15,65.7-12.9,66.51-26.76s10.54-29.87,10.54-29.87l-91.93,33.79S1091.65,1160.61,1097.15,1166.76Z"
            />
            <path
                className="update-app-cls-30"
                d="M1332.48,1046.36c-8.14-.5-21.81-.23-43.86,2.9,0,0-34.6,41-114.42,60.87,0,0,114-18.28,161-54.94A4.93,4.93,0,0,0,1332.48,1046.36Z"
            />
            <path
                className="update-app-cls-26"
                d="M3399.18,1120.11c-4.54-25.58,8.76-96.84-47.68-95.69,0,0-53.71-39.46-93.77-24.66s-64.75,27.13-70.92,54.27,5.26,46.42,23.78,54.26c11,4.64,30.67,35,44.9,58.89,9.77,16.47,17,29.92,17,29.92l45.09,39.47,21-33.31s20.94,1.24,27.11-8.63,2.46-5.45,9.86-28.61,32.07-21,24.67-41.81A23.2,23.2,0,0,1,3399.18,1120.11Z"
            />
            <path
                className="update-app-cls-36"
                d="M3378.53,1154.38c-8.71-3.68-18.54,2-25.28,8.64s-12.75,14.82-21.73,17.77a22.14,22.14,0,0,1-28.41-26.35c1.17-4.73,3.91-9.11,4.1-14,.32-8.17-6.68-15-14.19-18.15s-15.87-4-23.51-6.9-15.09-8.89-15.8-17a119.09,119.09,0,0,0,39.15-.69c-22.62-3.63-42.56-20.67-49.67-42.45l-26.61-17.11c-9-.09-16.78,5.73-23.83,11.27-9.83,7.74-20.53,17.14-20.87,29.64-.27,10.27,6.84,19.37,15,25.56s17.75,10.45,25.76,16.89c17.52,14.13,25.54,37,28.74,59.33,1.84,12.81,10.55,22.19,20.51,30.44,6.19,5.14,7.11,11.77,15.11,12.56,19.36,1.93,38.9.5,58.18-2.1,11-1.48,22.13-3.43,31.65-9a46.27,46.27,0,0,0,22.22-35.09C3389.91,1168.53,3386.92,1157.92,3378.53,1154.38Z"
            />
            <path
                className="update-app-cls-36"
                d="M3414.47,1091.67a84.69,84.69,0,0,0-5,13.29A70.63,70.63,0,0,0,3414.47,1091.67Z"
            />
            <polygon
                className="update-app-cls-15"
                points="3432.26 1547.38 3453.23 1636.18 3561.76 1671.95 3521.06 1491.88 3432.26 1547.38"
            />
            <path
                className="update-app-cls-37"
                d="M3786.14,1571.26c-75.78-39.55-172.48-10.39-238.09,64.68L3001,1350.41c24-96.75-7.35-192.76-83.13-232.3-79-41.24-180.76-7.77-246.33,74.55l4.52,2.37L2844,1282.67l-6.88,13.2,1.58.84-55.93,107.15-168.72-88c-18.53,92.48,13.32,182.1,85.87,220,75.45,39.37,171.66,10.63,237.27-63.72l547.58,285.8c-23.46,96.33,8,191.69,83.42,231.06,72.55,37.87,164.31,12.73,229.58-55.37l-165.54-86.39,6.88-13.2-1.58-.84L3693.41,1726l171.07,89.27,1.39.71C3895.89,1715.12,3865.15,1612.5,3786.14,1571.26Z"
            />
            <path
                className="update-app-cls-30"
                d="M3780.84,1585.29c-75.78-39.54-172.48-10.38-238.12,64.68l-547-285.5c24-96.77-7.33-192.78-83.11-232.32-75.16-39.25-170.92-10.86-236.51,62.88l161,100.84,1.58.84-55.93,107.15-168.72-88c-18.53,92.48,13.32,182.1,85.87,220,75.45,39.37,171.66,10.63,237.27-63.72l547.58,285.8c-23.46,96.33,8,191.69,83.42,231.06,72.55,37.87,164.31,12.73,229.58-55.37l-165.54-86.39,6.88-13.2-1.58-.84L3693.41,1726l171.07,89.27C3887.47,1719.31,3856,1624.54,3780.84,1585.29Z"
            />
            <g className="update-app-cls-11">
                <polygon
                    className="update-app-cls-31"
                    points="2837.08 1295.87 2838.66 1296.71 2782.73 1403.86 2614.01 1315.82 2792.53 1442.12 2889.4 1268.86 2676.05 1195.03 2837.08 1295.87"
                />
            </g>
            <g className="update-app-cls-11">
                <polygon
                    className="update-app-cls-31"
                    points="3864.49 1815.27 3693.54 1671.95 3589.32 1854.57 3797.73 1933.58 3632.19 1847.19 3639.07 1833.99 3637.49 1833.15 3693.41 1726 3864.49 1815.27"
                />
            </g>
            <polygon
                className="update-app-cls-15"
                points="3494.39 2440.32 3554.24 2585.53 3692.83 2571.88 3618.82 2386.2 3494.39 2440.32"
            />
            <path
                className="update-app-cls-26"
                d="M3668.39,2520.77c-48.43,59.16-82.72,32.22-110.34,18-5.85-3-13.11-.61-17.79,4l-134.34,116.87a53.55,53.55,0,0,0-12.86,55.71l.86,2.47,336.39-117.6-48.7-78C3678.82,2517.46,3671.91,2516.48,3668.39,2520.77Z"
            />
            <rect
                className="update-app-cls-4"
                x="3387.82"
                y="2658.38"
                width="356.36"
                height="23.53"
                transform="matrix(0.94, -0.33, 0.33, 0.94, -681.42, 1326.46)"
            />
            <rect
                className="update-app-cls-4"
                x="3572.17"
                y="2595.8"
                width="4"
                height="72.59"
                transform="translate(-817.77 3275.08) rotate(-44.7)"
            />
            <rect
                className="update-app-cls-4"
                x="3533.49"
                y="2625.71"
                width="4"
                height="54.13"
                transform="translate(-843.5 3253.91) rotate(-44.7)"
            />
            <polygon
                className="update-app-cls-15"
                points="3187.07 2655.61 3323.76 2682.2 3335.09 2521.97 3199.18 2504.9 3187.07 2655.61"
            />
            <path
                className="update-app-cls-26"
                d="M3315,2626.23c-63.35,42.82-88.48,7.2-110.87-14.35-4.75-4.57-12.39-4.34-18.2-1.23l-162.18,73.52a53.56,53.56,0,0,0-28.27,49.71l.12,2.61,356-16.39-24.34-88.66C3325.91,2626.05,3319.57,2623.13,3315,2626.23Z"
            />
            <rect
                className="update-app-cls-4"
                x="2995.93"
                y="2728.28"
                width="356.36"
                height="23.53"
                transform="translate(-122.68 148.91) rotate(-2.64)"
            />
            <rect
                className="update-app-cls-4"
                x="3190.84"
                y="2669.63"
                width="4"
                height="72.59"
                transform="translate(-897.64 1820.33) rotate(-28.07)"
            />
            <rect
                className="update-app-cls-4"
                x="3147.85"
                y="2687.61"
                width="4"
                height="54.13"
                transform="translate(-906.82 1801.2) rotate(-28.07)"
            />
            <path
                className="update-app-cls-17"
                d="M2937.14,1472.1l-19.31,19.78-20.68,17.22s66.42,137,165.09,137,113.07-85.53,113.07-85.53l-157.67-46.72Z"
            />
            <g className="update-app-cls-38">
                <path
                    className="update-app-cls-15"
                    d="M3047.58,1408.45l-84.26,74.29c31.86,7,54.55,34.23,76,58.78s48,49.71,80.58,50.15c10.81.15,21.5-2.54,31.54-6.51,16.39-6.5,32.07-17.32,39.29-33.4,9.8-21.83,1.78-47.62-10.29-68.29C3153.33,1437,3101.06,1402.09,3047.58,1408.45Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M3088.09,1619.05a180.22,180.22,0,0,0-113.43,21.14l2.85,22.9c18.16,12.2,40.65,15.81,62.44,17.78,14,1.27,28.26,2,41.92-1.3s26.8-11,33.76-23.19c2.84-5,4.59-10.8,3.56-16.42C3116.77,1626.81,3101.32,1621,3088.09,1619.05Z"
                />
                <path className="update-app-cls-15" d="M2882.16,1521.44l-.18-2.69A8.77,8.77,0,0,0,2882.16,1521.44Z" />
                <path
                    className="update-app-cls-15"
                    d="M2926.38,1443.53a111.92,111.92,0,0,0-49.16,3.89l4.76,71.33c.35-4.58,4.22-8.77,8.67-10.56,5.31-2.13,11.22-1.88,16.93-2.38a63.23,63.23,0,0,0,41.18-20.6c4.23-4.73,7.86-10.48,8-16.82C2957.09,1454.05,2940.58,1445.6,2926.38,1443.53Z"
                />
            </g>
            <path
                className="update-app-cls-19"
                d="M3469.26,1284.67a44.73,44.73,0,0,0-4.32-3.38c-11.79-8.29-31.5-15.49-53.58-21.59-54.51-15-123.46-23.13-123.46-23.13s-29.72,2-64.48,7c-13.12,1.92-27,4.26-40.18,7.1-21.71,4.64-41.79,10.63-54.39,18.21-40.71,24.44-86.34,163.81-101.14,192.18s-19.74,37-19.74,37,44.41,92.51,146.78,72.77c2.78,1.33,7.72,2.47,14.33,3.41,66.45,9.32,305.11-.64,305.11-.64l.87-.6,92.87-62.6S3503.79,1315.5,3469.26,1284.67Z"
            />
            <g className="update-app-cls-39">
                <path
                    className="update-app-cls-21"
                    d="M3482.9,1492.78c-16.26-10.87-21.69-31.9-25.38-51.1a1143.64,1143.64,0,0,1-18.6-149.37c-.58-10-1.32-20.72-7.76-28.34-8.21-9.72-22.68-11-33.24-18a330.11,330.11,0,0,1-18.81,207.5c-4.48,10.19-9.87,20.61-19.08,26.86-7.28,4.94-16.19,6.74-24.9,8a245.9,245.9,0,0,1-68.17,0c-4.09-.57-8.54-1.45-11.16-4.63-2.75-3.34-2.61-8.12-2.37-12.44a670.41,670.41,0,0,0-23.39-216c-2.06-7.43-4.54-15.28-10.47-20.21-12.07-10-30.61-2.78-45.7-7.12,25.35,35.55,24.51,83.17,18.53,126.42-4.55,33-11.66,66.09-27,95.64s-39.7,55.47-70.88,67.14-69.34,7-93.26-16.2l-28.14,17.55,9.7,23.46c7.29,17.65,15.19,36.11,30.26,47.84,10.39,8.07,23.24,12.17,35.84,16q84.3,25.45,170.84,42.56c54.75,10.81,110.55,19.1,166.22,15.22,27.08-1.88,54.18-6.71,79.22-17.18,38.51-16.08,70.67-45,96-78.09a342.1,342.1,0,0,0,49.29-89.76l-65.9,16.34C3524,1499.91,3500.55,1504.58,3482.9,1492.78Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M3432.26,1454.05c-71.42,18-125.55,18-151.71-77.29-8.14-29.64-32.5-111.25-42.86-140.19-8.22-23-19.08,6-38.84-8.34,23.05,72.22,38.78,147.37,38.73,223.18s-16.41,152.5-54.18,218.24l-17.58,4c17.62,16.86,46.67,5.91,65.09-10.07C3281.93,1619.33,3483.08,1441.21,3432.26,1454.05Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M3468.21,1305.25c-7.7-48.26-18.58-97.31-45.06-138.39,6.18,151.05-.05,304.57-43.18,449.38,17.17-2.29,34.25-5.56,50.18-12.26,19-8,36.37-21.38,45.43-39.86,8.49-17.31,9-37.32,9-56.6A1312.55,1312.55,0,0,0,3468.21,1305.25Z"
                />
                <path
                    className="update-app-cls-22"
                    d="M3406.81,1702.71c.52-2.58,1-5.18,1.49-7.77-3.23.77-6.46,1.52-9.7,2.23Z"
                />
            </g>
            <path
                className="update-app-cls-17"
                d="M3338.55,1203.26c-15.42,13-21.58,9.26-22.82,7.41-1-1.5-16.19-11.78-22.07-15.73,1.68-5.22,2.24-10.41.77-15,.52-.64,1-1.28,1.42-1.92a37.92,37.92,0,0,0,21-9.52c13.67-12,17-30.61,7.43-41.45-9.36-10.62-27.62-9.86-41.2,1.51h0c-40.68,12.26-56-41.25-56-41.25a82.39,82.39,0,0,0-2.59,21.58c-19.17-12.78-14.88-32.92-10.39-44.15a1.16,1.16,0,0,0-2-1.07c-7.17,10.56-13.89,29.55-10.17,62.6l0,0c-10.08,2.06-7,11.91-7,12h0s-17.11,80.48,67.1,73c-.5,9.86-2.56,22.35-9.1,30.86,25.9,13.56,40.09,32.06,60.44,25.28s38.23-21,38.23-21S3337.32,1220.53,3338.55,1203.26Z"
            />
            <g className="update-app-cls-40">
                <path
                    className="update-app-cls-15"
                    d="M3253.35,1089.15c-2.54-4.25-4.88-8.66-8-12.49a38.12,38.12,0,0,0-32.1-13.62l-.28,3.88c-6.27,16.46.3,36.84,15,46.55a13.43,13.43,0,0,1,.3-15.63c2.64,6.67,5.44,13.58,10.7,18.46,6.38,5.93,15.38,8,24,9.19,6.94,1,14.12,1.56,20.86-.35A144.61,144.61,0,0,1,3253.35,1089.15Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M3285.61,1198.7c-3.25-2.44-7.82-.41-11.45,1.43-28.18,14.38-63.36,14.1-91.31-.72l-9.54,23.37c4.2,7.93,12.74,12.52,21.14,15.65,16.56,6.18,34.93,8.44,52.05,4s32.75-16,39.89-32.14C3288.09,1206.48,3289,1201.23,3285.61,1198.7Z"
                />
                <path
                    className="update-app-cls-15"
                    d="M3185.81,1126.31a17.58,17.58,0,0,0-5.95,6.44,9.93,9.93,0,0,0-.32,8.56,7,7,0,0,0,7.24,4,10.41,10.41,0,0,0,3.7-1.75,41.19,41.19,0,0,0,6.54-5.46,17.74,17.74,0,0,0,4.32-6.4,8.32,8.32,0,0,0-.76-7.45Z"
                />
            </g>
            <path
                className="update-app-cls-15"
                d="M3302,1139c-.58.08-1.17.17-1.75.28a9.31,9.31,0,0,0-1.73.43,15.23,15.23,0,0,0-3.31,1.46,17.28,17.28,0,0,0-3,2.14,15.32,15.32,0,0,0-4,6.14,11.47,11.47,0,0,0-.3,7.11,33.4,33.4,0,0,1,3.12-5.74,27.34,27.34,0,0,1,3.9-4.57c.78-.63,1.49-1.34,2.34-1.91.41-.29.79-.64,1.24-.91s.87-.56,1.31-.86a12.32,12.32,0,0,1,1.36-.83c.46-.27.93-.56,1.39-.87a30.27,30.27,0,0,0,2.95-1.77A20.77,20.77,0,0,0,3302,1139Z"
            />
            <path
                className="update-app-cls-15"
                d="M3305.11,1148a11.46,11.46,0,0,0-5-2.4,11.73,11.73,0,0,0-2.84-.24,10.43,10.43,0,0,0-2.78.58,8.82,8.82,0,0,0-4.39,3.21c1.88,0,3.42-.21,4.94-.12.74,0,1.46.1,2.17.13s1.4.17,2.09.35a33,33,0,0,1,4.23,1.2c1.46.51,2.95,1.1,4.68,1.73A9.57,9.57,0,0,0,3305.11,1148Z"
            />
            <path
                className="update-app-cls-4"
                d="M3233.3,1162.37c-19,1.12-25.93-1.72-25.93-1.72s-1.62,16.73,10.57,19.28S3245.33,1164.1,3233.3,1162.37Z"
            />
            <path
                className="update-app-cls-28"
                d="M3229.12,1116.14c-3.41-.6-6.81,2.52-7.59,7s1.34,8.54,4.75,9.14,6.81-2.52,7.59-7S3232.53,1116.74,3229.12,1116.14Z"
            />
            <path
                className="update-app-cls-29"
                d="M3254.82,1133.18a14.72,14.72,0,1,0,17.34,11.52A14.72,14.72,0,0,0,3254.82,1133.18Z"
            />
            <path
                className="update-app-cls-12"
                d="M3673.73,2415.14c-32.91-92.43-80.84-227.66-93.47-267.1-19.73-61.67-66.6-214.61-74-301-5.55-64.68-22.18-187.52-31.2-274.11-3-29-5.18-53.92-5.8-71.24-1.73-48.22-3.45-158.36-4.32-220.45-11.79-8.29-31.5-15.49-53.58-21.59,6.34,55.24,19.52,191.33-2.81,205-28.09,17.27-136.09,9.87-136.09,9.87s-15.61-167.15-49-231.06c-13.12,1.92-27,4.26-40.18,7.1,0,0,37.15,224.58-14.16,323.54v0c-40.23,144.6-42.7,230.94-55,359.21-9.13,94.94,4.71,434.47,12.5,604.5,2.76,59.7,4.76,98.53,4.76,98.53s150.48-46.87,229.41-29.61c-7.94-42.33-13-74.25-16.33-97.8-5.87-42.33-5.87-57.6-5.87-57.6s-29.6-180.08-29.6-212.14S3321.25,1963,3321.25,1963s93.74,357.68,118.41,424.28c10.31,27.8,24,65.08,36.56,99,17.44,47.41,32.51,88.44,32.51,88.44l197.34-69.07s-13.79-38.48-32.34-90.56Z"
            />
            <g className="update-app-cls-41">
                <path
                    className="update-app-cls-24"
                    d="M3435.44,1271.7c.65-10.28,1.7-20.93-1.85-30.6s-13.39-17.95-23.48-15.88c-14.36,3-17.53,21.92-16.63,36.55,1.46,23.89,5.25,47.8,3.24,71.65s-10.77,48.43-29.64,63.17c-10.8,8.43-26.53,12.92-38.18,5.7l-57,68.32a744.35,744.35,0,0,0,140.47-7.9c5.25-.8,10.84-1.82,14.63-5.53s4.94-9.15,5.78-14.32C3441.94,1386.47,3431.81,1328.72,3435.44,1271.7Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M3378.71,2436.73c-23.05-79.42-31.69-162.19-40.23-244.45l-21-149.76a1072.76,1072.76,0,0,1-19.27,139.5c-2.9,14.09-6.38,28.73-15.73,39.66-11.75,13.74-30.31,18.94-47.05,25.77s-34.26,18.18-37.38,36a133.85,133.85,0,0,1,79.94,11.12c-3.9,19.61-25.68,29-43.95,37.07A304.05,304.05,0,0,0,3092.18,2462c-4.84,8.81-9.27,17.94-12,27.62-2.48,8.81-3.52,17.94-4.55,27q-9.18,81-18.35,161.94c-.69,6.06-.87,13.35,4.07,16.93,3.26,2.37,7.66,2.19,11.69,1.88,118.78-9.15,234.26-42.14,348.78-74.94,2.68-.77,5.58-1.7,7.08-4.05s1.14-5.34.7-8.07C3420,2550.73,3395.53,2494.7,3378.71,2436.73Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M3715.91,2309.16q-51-103.27-102-206.54c-1,46.52,7.61,93.85-2.94,139.18-13,55.95-54.69,102.2-104.19,131.36-17.89,10.54-37.62,19.78-49.7,36.66-19.37,27-13,64.06-5.78,96.55l-10.5,134.32c61.53,18.58,127.95,4.93,189.46-13.72,28-8.47,55.91-18.1,80.72-33.55s46.48-37.31,57.05-64.56c14.72-37.94,6.32-80.89-7.29-119.24C3748.45,2375,3732.16,2342.05,3715.91,2309.16Z"
                />
                <path
                    className="update-app-cls-24"
                    d="M3164.64,1673.82c-3.18-5.83-3.12-12.82-3-19.46q.72-33.79,1.42-67.6a395.51,395.51,0,0,0,41.54-128.49c11.34-78.46.77-159.13-23.48-234.61l-54.59,31.71q-10,206.72-23.79,413.23c.84-.84,1.64-1.73,2.47-2.57a871.25,871.25,0,0,0-9.05,261.66A295.48,295.48,0,0,1,3160,1771.47c-12.65-9.76-13.63-31.21-1.93-42.08,11.1-10.33,28.12-9.83,43.26-9,36.09,2,73.28,2.27,107.1-10.48a343,343,0,0,1-123-21.68C3177.33,1685.23,3168.77,1681.38,3164.64,1673.82Z"
                />
                <path
                    className="update-app-cls-25"
                    d="M3174,2274l15,281.71L3178.37,2576l63.82-16.37c2.51-.64,5.31-1.52,6.45-3.85s0-5.29-1.13-7.74A801.17,801.17,0,0,1,3174,2274Z"
                />
                <path
                    className="update-app-cls-25"
                    d="M3591.14,2160.58q29,144.11,69,285.69l50.68-1.37a5,5,0,0,0,7.41,1.3c-29.3-53.19-52.9-109.29-76.44-165.27Z"
                />
            </g>
            <path
                className="update-app-cls-24"
                d="M3368.71,1985.23s29-66.6-3.05-177.61c17.26,81.41-44.41,155.41-44.41,155.41s93.74,357.68,118.41,424.28,69.07,187.48,69.07,187.48l197.34-69.07C3466.79,2508.18,3368.71,1985.23,3368.71,1985.23Z"
            />
            <path
                className="update-app-cls-32"
                d="M3126.55,2537.93c2.76,59.7,4.76,98.53,4.76,98.53s150.48-46.87,229.41-29.61c-7.94-42.33-13-74.25-16.33-97.8C3241.58,2491.93,3166.34,2517.88,3126.55,2537.93Z"
            />
            <path
                className="update-app-cls-32"
                d="M3476.22,2486.35c17.44,47.41,32.51,88.44,32.51,88.44l197.34-69.07s-13.79-38.48-32.34-90.56C3602.54,2428.9,3521.06,2464.79,3476.22,2486.35Z"
            />
            <path
                className="update-app-cls-12"
                d="M3412.18,1023.12c-.27-.47-.54-.92-.84-1.38-.62-1-1.26-2-2-3.09s-1.23-1.85-1.9-2.79c-9.74-13.83-23.93-27-44-38.62-65.52-37.72-114.31-6.32-143.1,25.53-19,21.06-29.38,42.3-30.05,43.93,0,0,39,27.33,53.8,32.71l70.21,25.26,93.09,45.07a158.72,158.72,0,0,0,15.74-42.19c5.35-24.1,6.27-55.23-11-84.41Z"
            />
            <path
                className="update-app-cls-35"
                d="M3340.4,1071c-.82-7.92,6-14.41,12.43-19.1a214.43,214.43,0,0,1,58.51-30.16l-3.85-5.88c-21.71.37-43.46,6.49-64.68,12.07-27.53,7.22-56.81,13.54-84,5.3-15.89-4.78-30.44-16.26-38.43-30.46-19.05,21.06-29.38,42.3-30.05,43.93,0,0,39,27.33,53.8,32.71l70.21,25.26,93.09,45.07a158.72,158.72,0,0,0,15.74-42.19,229.34,229.34,0,0,1-67.22-20.47C3348.88,1083.68,3341.21,1078.74,3340.4,1071Z"
            />
            <path
                className="update-app-cls-30"
                d="M3412.17,1023.11c-.63-1.46-1.26-2.28-2.16-4.43l-.61,0c-77.16-3.2-149,44.78-166,58.33l17.81,7.72c19.91-15,92.3-66.22,151.58-60a13.87,13.87,0,0,0-.6-1.54Z"
            />
            <path
                className="update-app-cls-30"
                d="M3322.26,1137.16c-.59,14.64,60.7,40.92,67.12,35s18-22.46,18-22.46l-93.09-45.08S3322.86,1122.51,3322.26,1137.16Z"
            />
            <path
                className="update-app-cls-30"
                d="M3200.38,1028.78c-22.84-5.58-37.18-7.28-45.79-7.6a5.21,5.21,0,0,0-3.73,9c45.58,43.41,163.43,74.48,163.43,74.48C3232.47,1075.52,3200.38,1028.78,3200.38,1028.78Z"
            />
        </g>
    </svg>
);
const UpdateAppIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={UpdateAppSvg} {...props} />;
export default UpdateAppIcon;
