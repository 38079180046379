import { useEffect, useState } from 'react';
import { NotificationListController } from 'fragments/notification/fragments/notification-list/interfaces';
import { NotificationItem } from 'services/notification/notification.service';
import { useAPINotification } from 'services/notification/api-notification.service';
import { useSearchParams } from 'react-router-dom';
import { onOpenExternalBrowser } from 'mobile/common-functions';

export const useNotificationListController = (): /* <--Dependency Injections  like services hooks */
NotificationListController => {
    const [searchParams] = useSearchParams();
    const notificationService = useAPINotification();
    /* State */
    const [notifications, setNotifications] = useState<NotificationItem[]>([]);
    const [totalNotifications, setTotalNotifications] = useState<number>(0);
    // const [pageSize, setPageSize] = useState<number>(20);
    const [page, setPage] = useState<number>(0);
    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [nextToken, setNextToken] = useState<number | null>(0);

    const accessToken = searchParams.get('access_token') || '';

    /* Listeners */
    useEffect(() => {
        accessToken && getNotificationList(accessToken);
    }, []);

    /* View Events */
    const loadMoreData = () => {
        if (isLoadingList) return;
        if (accessToken) {
            getNotificationList(accessToken);
        }
    };

    const onReloadNotifications = () => {
        accessToken && getNotificationList(accessToken);
    };

    const onClickNotification = (id: string) => {
        accessToken && markNotificationAsRead(accessToken, id);
    };
    const onClickNotificationTypeLink = (url: string, id: string) => {
        accessToken && markNotificationAsRead(accessToken, id);
        onOpenExternalBrowser({ v: 1, data: { url } });
    };

    const onUpdateNotificationList = (id: string) => {
        setNotifications(notifications.map((item) => (item.id == id ? { ...item, status_id: 2 } : item)));
    };

    /* Private Methods */
    const markNotificationAsRead = (accessToken: string, id: string) => {
        notificationService
            .markNotificationAsRead(accessToken, id)
            .then(() => {
                // console.log(data);
            })
            .catch((error) => {
                console.error('notification-error', error);
            })
            .finally(() => {
                //
            });
    };
    const getNotificationList = (accessToken: string) => {
        setIsLoadingList(true);
        setTimeout(() => {
            notificationService
                .getNotificationList(accessToken, nextToken || 0)
                .then((data) => {
                    setPage(page === 0 ? 1 : page + 1);
                    setNotifications(page === 0 ? data.data : [...notifications, ...data.data]);
                    setTotalNotifications(data.total);
                    setNextToken(data.next_token);
                })
                .catch((error) => {
                    console.error('notification-list-error', error);
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsLoadingList(false);
                });
        }, 3000);
    };
    // Return state and events
    return {
        notifications,
        isLoadingList,
        isLoading,
        totalNotifications,
        onClickNotification,
        loadMoreData,
        onReloadNotifications,
        onUpdateNotificationList,
        onClickNotificationTypeLink,
    };
};
