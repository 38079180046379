import React, { useState } from 'react';
import { KeyCreationResponse } from 'services/keys/keys.service';
import { KeyCreationErrorResponse } from '../interfaces';
// import { KeyCreationResponse } from '../interfaces';
import { KeysCreationContext } from './keys-creation.context';

type Props = {
    children?: React.ReactNode;
};

const KeysCreationProvider: React.FC<Props> = (props) => {
    const [isCreateKeySummaryModalVisible, setIsCreateKeySummaryModalVisible] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>('');
    const [keyCreationResponse, setKeyCreationResponse] = useState<KeyCreationResponse | KeyCreationErrorResponse>({
        status: 0,
        data: {
            id: '',
            lock_name: '',
            site_name: '',
            created_at: '',
            assigned_to_email: '',
            nbf: '',
            expires_at: '',
            dow: 0,
        },
    });

    return (
        <KeysCreationContext.Provider
            value={{
                isCreateKeySummaryModalVisible,
                setIsCreateKeySummaryModalVisible,
                keyCreationResponse,
                setKeyCreationResponse,
                accessToken,
                setAccessToken,
            }}
        >
            {props.children}
        </KeysCreationContext.Provider>
    );
};

export default KeysCreationProvider;
