import React, { useEffect } from 'react';
import 'fragments/common/info-modal/info-modal.scss';
import { InfoModalFragmentProps } from 'fragments/common/info-modal/interfaces';
import { useInfoModalController } from 'fragments/common/info-modal/info-modal.controller';
import { Col, Modal, Row, Spin, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useSearchParams } from 'react-router-dom';
const { info, destroyAll } = Modal;
const { Title, Text } = Typography;

export const InfoModalFragment: React.FC<InfoModalFragmentProps> = (props) => {
    const { useController = useInfoModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const [searchParams, setSearchParams] = useSearchParams();
    const title = searchParams.get('title') || '';
    const subtitle = searchParams.get('subtitle') || '';

    useEffect(() => {
        onOpenModal();
    }, []);

    const onOpenModal = () => {
        info({
            title: <Title level={3}>{title}</Title>,
            icon: <div />,
            content: (
                <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{
                        flexWrap: 'nowrap',
                        gap: '3px',
                    }}
                >
                    <Text style={{ fontSize: '1rem' }}>{subtitle}</Text>
                    <Spin size="large" />
                </Row>
            ),
            okButtonProps: { style: { display: 'none' } },
            centered: true,
        });
    };

    // Render
    return <div className={'info-modal'}></div>;
};
