import dayjs from 'dayjs';
import { KeyScheduleOption } from 'services/keys/keys.service';
import { useTranslator } from './view-hooks/translator-hook';

export const useDowFunctions = () => {
    const { translate } = useTranslator();
    const dowChallenge = (dow: number): string => {
        const arr = mapBytewiseToArray(dow);
        // Bytes dictionary
        const dict: any = {
            b1: {
                day: translate({ key: 'general.monday-short' }),
                bit: 1,
                value: 0,
            },
            b2: {
                day: translate({ key: 'general.tuesday-short' }),
                bit: 2,
                value: 1,
            },
            b4: {
                day: translate({ key: 'general.wednesday-short' }),
                bit: 4,
                value: 2,
            },
            b8: {
                day: translate({ key: 'general.thursday-short' }),
                bit: 8,
                value: 3,
            },
            b16: {
                day: translate({ key: 'general.friday-short' }),
                bit: 16,
                value: 4,
            },
            b32: {
                day: translate({ key: 'general.saturday-short' }),
                bit: 32,
                value: 5,
            },
            b64: {
                day: translate({ key: 'general.sunday-short' }),
                bit: 64,
                value: 6,
            },
        };

        // Mapped array
        const daysInfo = arr.map((e) => dict['b' + e]).sort((a, b) => a.value - b.value);
        const values = daysInfo.map((e) => e.value);

        // Specific cases
        if (values.length === 7) {
            return `${daysInfo[6].day} a ${daysInfo[5].day}`;
        }
        if (values.length === 1) {
            return daysInfo[0].day;
        }

        if (values.length === 0) {
            return '';
        }

        // Temporary variables
        let newValues = [...values];
        const offset = values[0];
        let first = daysInfo[0].day;
        let last = '';

        //--------- scan and delete consecutives from newValues -------------
        // Scan forward
        for (const i in values) {
            if (Number(i) + offset != values[i]) {
                break;
            }
            last = daysInfo[i].day;
            newValues = newValues.filter((v) => v !== values[i]);
        }

        // If it start on first day of week, scan backward
        if (offset === 0) {
            let i = 6;
            while (i > 0) {
                const ireverse = values.length - (7 - i);
                if (i != values[ireverse]) {
                    break;
                }
                first = daysInfo[ireverse].day;
                newValues = newValues.filter((v) => v !== values[ireverse]);
                i--;
            }
        }

        // if new values are empties it means that the days were consecutives
        if (newValues.length === 0) return `${first} a ${last}`;

        if (values.some((v) => v === 6)) {
            const saturday = daysInfo.pop();
            daysInfo.unshift(saturday);
        }

        return daysInfo.map((e) => e.day).join(', ');
    };

    const mapBytewiseToArray = (dow: number): number[] => {
        const defaultKeySchedules = [
            { key: 1, selected: false },
            { key: 2, selected: false },
            { key: 4, selected: false },
            { key: 8, selected: false },
            { key: 16, selected: false },
            { key: 32, selected: false },
            { key: 64, selected: false },
        ];
        for (let i = dow, j = 0; i >= 1; j++) {
            if (i % 2 === 1) {
                defaultKeySchedules[j].selected = true;
            }
            i = Math.floor(i / 2);
        }
        return defaultKeySchedules.filter((e) => e.selected).map((e) => e.key);
    };
    const getKeySchedules = (dow: number): KeyScheduleOption[] => {
        const defaultKeySchedules = [
            { key: 1, label: translate({ key: 'general.monday-initial' }), selected: false },
            { key: 2, label: translate({ key: 'general.tuesday-initial' }), selected: false },
            { key: 4, label: translate({ key: 'general.wednesday-initial' }), selected: false },
            { key: 8, label: translate({ key: 'general.thursday-initial' }), selected: false },
            { key: 16, label: translate({ key: 'general.friday-initial' }), selected: false },
            { key: 32, label: translate({ key: 'general.saturday-initial' }), selected: false },
            { key: 64, label: translate({ key: 'general.sunday-initial' }), selected: false },
        ];
        for (let i = dow, j = 0; i >= 1; j++) {
            if (i % 2 === 1) {
                defaultKeySchedules[j].selected = true;
            }
            i = Math.floor(i / 2);
        }
        const temp = defaultKeySchedules.pop();
        defaultKeySchedules.unshift(temp!);
        return defaultKeySchedules;
    };

    const getDaysBytesFromDateRange = (from: string, to: string): number[] => {
        const difference = dayjs(to).diff(dayjs(from), 'days');
        if (difference > 7 || from === '' || to === '') return [];
        const daysBytes: number[] = [];
        const values = [64, 1, 2, 4, 8, 16, 32];
        for (let i = 0; i <= difference; i++) {
            daysBytes.push(values[dayjs(from).add(i, 'days').day()]);
        }
        const newArr = values.reduce((acc: number[], e) => {
            if (!daysBytes.find((x) => x === e)) return [...acc, e];
            else return acc;
        }, []);
        return newArr;
    };
    return { dowChallenge, getKeySchedules, mapBytewiseToArray, getDaysBytesFromDateRange };
};
