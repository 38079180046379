import React, { useState } from 'react';
// import { KeyCreationResponse } from 'services/keys/keys.service';
// import { KeyCreationErrorResponse } from '../interfaces';
// import { KeyCreationResponse } from '../interfaces';
import { PasscodesContext } from './passcodes.context';

type Props = {
    children?: React.ReactNode;
};

const PasscodesProvider: React.FC<Props> = (props) => {
    const [accessToken, setAccessToken] = useState<string>('');
    const [lockId, setLockId] = useState<string>('');

    return (
        <PasscodesContext.Provider
            value={{
                lockId,
                setLockId,
                accessToken,
                setAccessToken,
            }}
        >
            {props.children}
        </PasscodesContext.Provider>
    );
};

export default PasscodesProvider;
