import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-creation-result-success/rfid-card-creation-result-success.scss';
import { RfidCardCreationResultSuccessFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-creation-result-success/interfaces';
import { useRfidCardCreationResultSuccessController } from 'fragments/rfidcards/fragments/rfid-card-creation-result-success/rfid-card-creation-result-success.controller';
import { Button, Col, Result, Row, Tag, Typography } from 'antd';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import { KeyScheduleOption } from 'services/keys/keys.service';
import CheckIcon from 'assets/icons/check-custom-icon';
const { Title, Text } = Typography;

export const RfidCardCreationResultSuccessFragment: React.FC<RfidCardCreationResultSuccessFragmentProps> = (props) => {
    const { useController = useRfidCardCreationResultSuccessController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'rfid-card-creation-result-success'}>
            <Result
                icon={<CheckIcon />}
                title={
                    <Title className={'rfid-card-creation-result-success-title'}>
                        {translate({ key: 'rfid-card.summary-title-create-success' })}
                    </Title>
                }
                subTitle={
                    <Text className={'rfid-card-creation-result-success-subtitle'}>
                        {translate({ key: 'rfid-card.summary-text-create-success-1' }) + ' '}
                        {controller.data.label ? <strong>{controller.data.label} </strong> : ''}
                        {translate({ key: 'rfid-card.summary-text-create-success-2' }) +
                            ' ' +
                            controller.data.lock_name}
                    </Text>
                }
                extra={
                    <>
                        <div className={'rfid-card-creation-result-success-tags'}>
                            {controller.data.cyclic_rfid_config_enabled === 'true' && (
                                <Row justify="space-between">
                                    {controller.keySchedules.map((keyOption: KeyScheduleOption) => {
                                        return (
                                            <Col
                                                xs={3}
                                                key={keyOption.key}
                                                style={{
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <Tag
                                                    style={{
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        minWidth: '100%',
                                                        padding: '0.5rem',
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                        color: keyOption.selected ? '#555' : '#aaa',
                                                        backgroundColor: keyOption.selected ? '#eee' : '#fff',
                                                        border: '2px solid #eee',
                                                        borderRadius: '8px',
                                                    }}
                                                    key={keyOption.key}
                                                >
                                                    {keyOption.label}
                                                </Tag>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                            <Row justify="center">
                                <Tag
                                    style={{
                                        marginBottom: '10px',
                                        padding: '.5rem',
                                        fontSize: '.875rem',
                                        fontWeight: 'bold',
                                        color: '#555',
                                        backgroundColor: '#eee',
                                        border: 'none',
                                        borderRadius: '8px',
                                    }}
                                    icon={<ClockCircleOutlined />}
                                >
                                    {controller.data.cyclic_rfid_config_enabled === 'true'
                                        ? `${controller.data.nbf_time?.slice(
                                              11,
                                              16,
                                          )} hs - ${controller.data.expires_at_time?.slice(11, 16)} hs`
                                        : 'FULLTIME'}
                                </Tag>
                                <Tag
                                    style={{
                                        marginBottom: '10px',
                                        padding: '.5rem',
                                        fontSize: '.875rem',
                                        fontWeight: 'bold',
                                        color: '#555',
                                        backgroundColor: '#eee',
                                        border: 'none',
                                        borderRadius: '8px',
                                        marginRight: '0',
                                    }}
                                    icon={<CalendarOutlined />}
                                >{`${dayjs.utc(controller.data.nbf_date).format('DD/MM/YY')} - ${dayjs
                                    .utc(controller.data.expires_at_date)
                                    .format('DD/MM/YY')}`}</Tag>
                            </Row>
                        </div>
                        <div>
                            <Row justify={'space-between'} style={{ width: '100%' }}>
                                <Col span={11}>
                                    <Button
                                        className={'rfid-card-creation-result-success-btns-item'}
                                        type="default"
                                        key="console"
                                        onClick={controller.onReadyButtonPressed}
                                    >
                                        {translate({ key: 'general.ready' })}
                                    </Button>
                                </Col>
                                <Col span={11}>
                                    <Button
                                        className={'rfid-card-creation-result-success-btns-item'}
                                        type="primary"
                                        key="console"
                                        onClick={controller.onAssociateAnotherPressed}
                                    >
                                        {translate({ key: 'rfid-card.associate-another' })}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            />
        </div>
    );
};
