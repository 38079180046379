import React, { useState } from 'react';
import 'components/page-500/page-500.scss';
import { Button, Result } from 'antd';

// export interface Page500Props {}

const Page500: React.FC = () => {
    // State

    return (
        <div className={'page-500'}>
            <Result
                status="500"
                title="500"
                subTitle="Ups... Algo salio mal, intenta luego."
                // extra={<Button type="primary">Volver</Button>}
            />
        </div>
    );
};

export default Page500;
