import { MobileDataInterface } from 'mobile';

export const onSharePasscode = (object: MobileDataInterface<{ message: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onSharePasscode.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onSharePasscode(JSON.stringify(object));
};

export const onPasscodeDelete = (object: MobileDataInterface<{ id: string; code: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onPasscodeDelete.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onPasscodeDelete(JSON.stringify(object));
};

export const onViewPasscodeDetailFromList = (object: MobileDataInterface<{ passcode_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onViewPasscodeDetailFromList.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onViewPasscodeDetailFromList(JSON.stringify(object));
};

export const onGeneratePasscodeFromList = (object: MobileDataInterface<{ lock_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onGeneratePasscodeFromList.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onGeneratePasscodeFromList(JSON.stringify(object));
};
