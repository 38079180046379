import React from 'react';
import 'fragments/keys/fragments/mobile-key-creation/fragments/create-key-summary/create-key-summary.scss';
import { CreateKeySummaryFragmentProps } from 'fragments/keys/fragments/mobile-key-creation/fragments/create-key-summary/interfaces';
import { useCreateKeySummaryController } from 'fragments/keys/fragments/mobile-key-creation/fragments/create-key-summary/create-key-summary.controller';
import { Button, Modal, Typography, Space, Tag, Row, Col, Result } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Title, Paragraph } = Typography;
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { KeyScheduleOption } from 'services/keys/keys.service';
import CheckIcon from 'assets/icons/check-custom-icon';
import WarningIcon from 'assets/icons/warning-icon';
import XCircleIcon from 'assets/icons/x-circle-icon';

export const CreateKeySummaryFragment: React.FC<CreateKeySummaryFragmentProps> = (props) => {
    const { useController = useCreateKeySummaryController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'create-key-summary'}>
            <Result
                icon={
                    <>
                        {controller.status === 409 && (
                            <XCircleIcon
                                style={{
                                    color: controller.color,
                                }}
                            />
                        )}
                        {controller.status === 202 && (
                            <WarningIcon
                                style={{
                                    color: controller.color,
                                }}
                            />
                        )}
                        {controller.status === 200 && (
                            <CheckIcon
                                style={{
                                    color: controller.color,
                                }}
                            />
                        )}
                    </>
                }
                title={
                    <Title
                        level={3}
                        style={{
                            textAlign: 'center',
                            color: controller.color,
                        }}
                    >
                        {controller.title}
                    </Title>
                }
                subTitle={<Paragraph className={'create-key-summary-subtitle'}>{controller.message}</Paragraph>}
                extra={
                    <>
                        {controller.status === 200 && (
                            <div className={'create-key-summary-tags'}>
                                <Row justify="space-between">
                                    {controller.keySchedules.map((keyOption: KeyScheduleOption) => {
                                        return (
                                            <Col
                                                xs={3}
                                                key={keyOption.key}
                                                style={{
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <Tag
                                                    style={{
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        minWidth: '100%',
                                                        padding: '0.5rem',
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                        color: keyOption.selected ? '#555' : '#aaa',
                                                        backgroundColor: keyOption.selected ? '#eee' : '#fff',
                                                        border: '2px solid #eee',
                                                        borderRadius: '8px',
                                                    }}
                                                    key={keyOption.key}
                                                >
                                                    {keyOption.label}
                                                </Tag>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row justify="center">
                                    <Tag
                                        style={{
                                            marginBottom: '10px',
                                            padding: '.5rem',
                                            fontSize: '.875rem',
                                            fontWeight: 'bold',
                                            color: '#555',
                                            backgroundColor: '#eee',
                                            border: 'none',
                                            borderRadius: '8px',
                                            marginRight: '0',
                                        }}
                                        icon={<CalendarOutlined />}
                                    >{`${controller.validity.sinceDate} - ${controller.validity.untilDate}`}</Tag>
                                    <Tag
                                        style={{
                                            marginBottom: '10px',
                                            padding: '.5rem',
                                            fontSize: '.875rem',
                                            fontWeight: 'bold',
                                            color: '#555',
                                            backgroundColor: '#eee',
                                            border: 'none',
                                            borderRadius: '8px',
                                            marginRight: '0',
                                        }}
                                        icon={<ClockCircleOutlined />}
                                    >{`${controller.validity.sinceTime}hs - ${controller.validity.untilTime}hs`}</Tag>
                                </Row>
                            </div>
                        )}
                        <Row justify={'center'} style={{ gap: '1.5rem', width: '100%' }}>
                            {controller.status !== 200 && (
                                <Col span={11}>
                                    <Button
                                        className={'create-key-summary-btn'}
                                        key="cancel"
                                        type="default"
                                        onClick={controller.onCloseButtonPressed}
                                        disabled={controller.isLoading}
                                    >
                                        {translate({ key: 'general.cancel' })}
                                    </Button>
                                </Col>
                            )}
                            {controller.status === 200 && (
                                <Col span={11}>
                                    <Button
                                        className={'create-key-summary-btn'}
                                        type="primary"
                                        key="cancel"
                                        onClick={controller.onCloseButtonPressed}
                                    >
                                        {translate({ key: 'general.continue' })}
                                    </Button>
                                </Col>
                            )}
                            {controller.status === 202 && (
                                <Col span={11}>
                                    <Button
                                        className={'create-key-summary-btn'}
                                        type="primary"
                                        key="cancel"
                                        onClick={controller.onOverwriteKey}
                                        loading={controller.isLoading}
                                    >
                                        {translate({ key: 'general.overwrite' })}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </>
                }
            />
        </div>
    );
};
