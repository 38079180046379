import { useEffect, useState } from 'react';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GetKeyDetail, KeyDto } from 'services/keys/keys.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { onAlert } from 'mobile/common-functions';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { KeyDetail, KeysDetailController } from './interfaces';
import { useDowFunctions } from 'tools/dow-functions';
import { onDeleteSmarttokenrecord } from 'mobile/key/key-functions';
import { ErrorDto } from 'services/dtos/errors-dto';

export const useKeysDetailController = (
    keysServices = useAPIKeys(),
): /* <--Dependency Injections  like services hooks */
KeysDetailController => {
    const { translate } = useTranslator();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const { getKeySchedules } = useDowFunctions();

    /* State */
    const [keysDetails, setKeysDetails] = useState<KeyDetail | undefined>(undefined);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [errorDetails, setErrorDetails] = useState<string>('');

    const accessToken = searchParams.get('access_token') || state?.accessToken || '';
    const keyId = searchParams.get('key_id')?.toLowerCase() || state?.keyId || '';

    /* Listeners */
    useEffect(() => {
        if (accessToken && keyId) {
            getKeyDetails({ accessToken, keyId });
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, keyId]);

    /* View Events */
    const onDeleteButtonPressed = (id: string) => {
        setIsLoadingDelete(true);
        keysServices
            .deleteKey(accessToken || '', id)
            .then((data) => {
                onAlert({
                    v: 1,
                    data: {
                        status: 1,
                        message: translate({ key: 'key.delete-success' }),
                    },
                });
                onDeleteSmarttokenrecord({ v: 1, data: {} });
            })
            .catch((error: ErrorDto) => {
                if (error.response.data.code === 409 && error.response.data.msg === 'deleted') {
                    onAlert({
                        v: 1,
                        data: {
                            status: 3,
                            message: translate({ key: 'key.delete-error-deleted' }),
                        },
                    });
                    onDeleteSmarttokenrecord({ v: 1, data: {} });
                } else {
                    onAlert({
                        v: 1,
                        data: {
                            status: 0,
                            message: translate({ key: 'key.delete-error' }),
                        },
                    });
                }
            })
            .finally(() => {
                setIsLoadingDelete(false);
            });
    };

    /* Private Methods */
    const getKeyDetails = ({ accessToken, keyId }: GetKeyDetail) => {
        setIsLoadingDetails(true);
        keysServices
            .getKeyDetail({ accessToken, keyId })
            .then((data) => {
                setKeysDetails(mapDtoToDetail(data));
            })
            .catch((error) => {
                console.error('key-details-error', error);
                setErrorDetails(error.message);
            })
            .finally(() => {
                setIsLoadingDetails(false);
            });
    };

    const mapDtoToDetail = (dto: KeyDto): KeyDetail => {
        const viewModelObject = {
            id: dto.id,
            label: dto.assigned_to_name, // label / User email
            validity: {
                from: dayjs.utc(dto.nbf).format('DD/MM/YY'),
                to: dayjs.utc(dto.expires_at).format('DD/MM/YY'),
                fromHour: dayjs.utc(dto.nbf).format('HH:mm'),
                toHour: dayjs.utc(dto.expires_at).format('HH:mm'),
                timeZone: dto.time_zone,
            },
            since: dto.nbf,
            until: dto.expires_at,
            state: dto.state_id,
            creatorName: dto.created_by_name,
            siteName: capitalize(dto.site_name),
            lockName: capitalize(dto.lock_name),
            createdAt: `${dayjs.utc(dto.nbf).format('DD/MM/YY HH:mm')}`,
            keySchedules: getKeySchedules(dto.dow),
        };
        return viewModelObject as KeyDetail;
    };
    // Return state and events
    return { keysDetails, isLoadingDetails, errorDetails, onDeleteButtonPressed };
};
