import { useEffect, useState } from 'react';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import { PaginationRequest, Sort } from 'global/interfaces';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { KeyListDto } from 'services/keys/keys.service';
import { useDowFunctions } from 'tools/dow-functions';
import { keyListItem, KeysListController } from './interfaces';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { onGenerateKeyFromList, onViewKeyDetailFromList } from 'mobile/key/key-functions';
import { useLockAdministrationContext } from 'fragments/locks/fragments/context/lock-administration.context';
import { OrderOption } from 'components/list-header-search-and-sort/list-header-search-and-sort.component';

let searchStr = '';

export const useKeysListController = (
    keysService = useAPIKeys(),
): /* <--Dependency Injections  like services hooks */
KeysListController => {
    const { translate } = useTranslator();
    const navigate = useNavigate();
    const { dowChallenge } = useDowFunctions();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { lockId: ctxLockId, accessToken: ctxAccessToken } = useLockAdministrationContext();

    /* State */
    const [totalKeys, setTotalKeys] = useState<number>(0);
    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
    const [sortFocused, setSortFocused] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [keys, setKeys] = useState<KeyListDto[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>({ orderBy: 'date', order: 'descend' });
    const [search, setSearch] = useState<string>('');

    const reactNavigation = location.pathname === '/mobile/locks/administration';
    const accessToken = !reactNavigation ? searchParams.get('access_token') : ctxAccessToken;
    const lockId = !reactNavigation ? searchParams.get('lock_id')?.toLowerCase() : ctxLockId;
    const [isFullSize, setIsFullSize] = useState<boolean>(!reactNavigation);

    const orderOptions: OrderOption[] = [
        {
            id: 0,
            value: translate({ key: 'general.later' }),
            sort: {
                order: 'ascend',
                orderBy: 'date',
            },
        },
        {
            id: 1,
            value: translate({ key: 'general.A-Z' }),
            sort: {
                order: 'ascend',
                orderBy: 'user',
            },
        },
        {
            id: 2,
            value: translate({ key: 'general.Z-A' }),
            sort: {
                order: 'descend',
                orderBy: 'user',
            },
        },
    ];

    /* Listeners */
    useEffect(() => {
        if (accessToken && lockId) {
            getKeysList(accessToken, lockId, { page: 0, pageSize }, sort, search);
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, lockId]);

    /* View Events */
    const onSortSelect = (sortSelected: Sort) => {
        if (!accessToken || !lockId) return;

        setSort(sortSelected);
        getKeysList(accessToken, lockId, { page: 0, pageSize }, sortSelected, search);
    };

    const onSearch = (value: string) => {
        if (!accessToken || !lockId) return;

        setSearch(value);
        searchStr = value;
        getKeysList(accessToken, lockId, { page: 0, pageSize }, sort, value);
    };

    const onGiveKeyButtonPressed = () => {
        if (reactNavigation) {
            navigate(`/mobile/keys/creation?lock_id=${lockId}&access_token=${accessToken}`);
        } else {
            onGenerateKeyFromList({ v: 1, data: { lock_id: lockId || '' } });
        }
    };

    const onKeyItemPressed = (id: string) => {
        if (!sortFocused) {
            if (reactNavigation) {
                navigate(`/mobile/keys/detail`, {
                    state: {
                        accessToken,
                        keyId: id,
                    },
                });
            } else {
                onViewKeyDetailFromList({ v: 1, data: { key_id: id } });
            }
        }
    };

    const giveSortFocus = (value: boolean) => {
        setSortFocused(value);
    };

    const changeLoadingList = (value: boolean) => {
        setIsLoadingList(value);
    };

    const loadMoreData = () => {
        if (isLoadingList) {
            return;
        }
        if (accessToken && lockId) {
            getKeysList(
                accessToken,
                lockId,
                { page: currentPage, pageSize },
                {
                    order: sort.order,
                    orderBy: sort.orderBy,
                },
                search,
            );
        }
    };

    /* Private Methods */
    const getKeysList = (
        accessToken: string,
        lockId: string,
        pagination: PaginationRequest,
        sort: Sort,
        srch: string,
    ) => {
        setIsLoadingList(true);
        keysService
            .listKeys({ accessToken, pagination, sort, search: { lockId, input: srch } })
            .then((data) => {
                if (data.search === searchStr) {
                    if (pagination.page === 0) {
                        setCurrentPage(1);
                        setKeys(data.keys);
                        setTotalKeys(data.total);
                    } else {
                        const newData = keys.concat(data.keys);
                        setKeys(newData);
                        setCurrentPage(currentPage + 1);
                        setTotalKeys(data.total);
                    }
                    setIsLoadingList(false);
                }
            })
            .catch((error) => {
                console.log('key-list-error', error);
            });
    };

    const getLabelAndColorByState = (state: number): { id: number; label: string; color: string } => {
        switch (state) {
            case 0:
                return { id: state, label: translate({ key: 'general.invalid' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 1:
                return { id: state, label: translate({ key: 'general.inactive' }), color: 'rgba(147, 94, 224, 0.6)' };
            case 2:
                return { id: state, label: translate({ key: 'general.valid' }), color: 'rgba(109, 206, 201, 0.8)' };
            default:
                return { id: state, label: translate({ key: 'general.invalid' }), color: 'rgba(242, 61, 79, 0.6)' };
        }
    };

    const mapDtoToListItem = (dto: KeyListDto): keyListItem => {
        const viewModelObject = {
            id: dto.id,
            state: getLabelAndColorByState(dto.state_id),
            label: dto.assigned_to_email,
            validity: {
                daysTag: dowChallenge(dto.dow),
                from: dayjs.utc(dto.nbf).format('DD/MM'),
                to: dayjs.utc(dto.expires_at).format('DD/MM'),
                fromHour: dayjs.utc(dto.nbf).format('HH:mm'),
                toHour: dayjs.utc(dto.expires_at).format('HH:mm'),
                timeZone: dto.time_zone,
            },
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        totalKeys,
        isLoadingList,
        keysList: keys.map(mapDtoToListItem),
        orderOptions,
        isFullSize,
        onSearch,
        onSortSelect,
        onGiveKeyButtonPressed,
        onKeyItemPressed,
        giveSortFocus,
        changeLoadingList,
        loadMoreData,
    };
};
