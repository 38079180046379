import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SortSvg = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.35 7.5C8.35 7.03056 7.96944 6.65 7.5 6.65C7.03056 6.65 6.65 7.03056 6.65 7.5L8.35 7.5ZM6.89896 23.101C7.2309 23.433 7.7691 23.433 8.10104 23.101L13.5104 17.6917C13.8424 17.3597 13.8424 16.8215 13.5104 16.4896C13.1785 16.1576 12.6403 16.1576 12.3083 16.4896L7.5 21.2979L2.69167 16.4896C2.35973 16.1576 1.82154 16.1576 1.48959 16.4896C1.15765 16.8215 1.15765 17.3597 1.48959 17.6917L6.89896 23.101ZM6.65 7.5L6.65 22.5L8.35 22.5L8.35 7.5L6.65 7.5Z"
            fill="#a9a9a9"
        />
        <path
            d="M18.101 1.89896C17.7691 1.56701 17.2309 1.56701 16.899 1.89896L11.4896 7.30833C11.1576 7.64027 11.1576 8.17846 11.4896 8.51041C11.8215 8.84235 12.3597 8.84235 12.6917 8.51041L17.5 3.70208L22.3083 8.51041C22.6403 8.84235 23.1785 8.84235 23.5104 8.51041C23.8424 8.17846 23.8424 7.64027 23.5104 7.30833L18.101 1.89896ZM16.65 17.5C16.65 17.9694 17.0306 18.35 17.5 18.35C17.9694 18.35 18.35 17.9694 18.35 17.5H16.65ZM16.65 2.5V17.5H18.35V2.5H16.65Z"
            fill="#a9a9a9"
        />
    </svg>
);
const SortIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={SortSvg} {...props} />;
export default SortIcon;
