import React from 'react';
import { Route, Routes, RouteProps, useLocation } from 'react-router-dom';
import { useLocalSession } from 'auth/helpers/session.hooks';
import FlexLoader from 'components/flex-loader/flex-loader.component';

export type RoutePrivateProps = RouteProps;

const AuthRoute: React.FC<RoutePrivateProps> = (props) => {
    const [session, , , isLoading] = useLocalSession();
    const location = useLocation();
    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', height: '100vh' }}>
                    <FlexLoader />
                </div>
            ) : (
                <Routes>
                    <Route path="*" element={props.children} />
                </Routes>
            )}
        </div>
    );
};

export default AuthRoute;
