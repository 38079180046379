import React, { useState } from 'react';
import 'components/form-time-slider/form-time-slider.scss';
import { Form, Slider, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

export interface FormTimeSliderProps {
    timeRange: { from: string; to: string };
    setTimeRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
}

// ======================================= INSTRUCCIONES DEL COMPONENTE =======================================
// Este componente debe recibir por props un state y su funcion de seteo respetando la estructura en FormTimeSliderProps.
// El componente actualizará este state cada vez que se interactue con él.
// Ejemplo:
// const [state, setState] = useState()
// return <FormTimeSlider timeRange={state} setTimeRange={setState}/>
// ============================================================================================================

const FormTimeSlider: React.FC<FormTimeSliderProps> = (props) => {
    // State
    const [marks, setMarks] = useState<any>({ 0: '00:00', 1439: '23:59' });
    const { translate } = useTranslator();

    const onSetTimeSlider = (value: [number, number]) => {
        const since_time = sliderFormatter(value[0]);
        const until_time = sliderFormatter(value[1]);
        props.setTimeRange({ from: since_time, to: until_time });
        setMarks({
            0: '00:00',
            [value[0]]: since_time,
            [value[1]]: until_time,
            1439: '23:59',
        });
    };

    const sliderFormatter = (value?: number) => {
        const hours: number = Math.floor(value ? value / 60 : 0);
        const finalMinutes: number = Math.floor(value ? value % 60 : 0);
        const formatterTime: string = dayjs().set('hour', hours).set('minute', finalMinutes).format('HH:mm');
        return formatterTime;
    };

    return (
        <Form.Item
            label={
                <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                    {translate({ key: 'label.times' })}
                </Title>
            }
            name="times"
        >
            <Slider
                range
                defaultValue={[0, 1439]}
                marks={marks}
                tooltip={{
                    formatter: (value: any) => {
                        return <div>{sliderFormatter(value)}</div>;
                    },
                }}
                min={0}
                max={1439}
                step={15}
                onChange={onSetTimeSlider}
            />
        </Form.Item>
    );
};

export default FormTimeSlider;
