import { RfidCardDeleteErrorLockController } from 'fragments/rfidcards/fragments/rfid-card-delete-error-lock/interfaces';
import { onDeleteCardRetry } from 'mobile/rfid/rfid-functions';

export const useRfidCardDeleteErrorLockController = (): /* <--Dependency Injections  like services hooks */
RfidCardDeleteErrorLockController => {
    /* State */

    /* Listeners */

    /* View Events */
    const onDeleteRetryPressed = () => {
        onDeleteCardRetry({
            v: 3,
            data: {},
        });
    };

    /* Private Methods */

    // Return state and events
    return { onDeleteRetryPressed };
};
