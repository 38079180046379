import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const BluetoothSvg = () => (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.28998 11.64L5.99998 7.99001L10.71 11.64L5.99998 15.3V0.700012L10.71 4.36001L5.99998 8.01001L1.28998 4.36001"
            stroke="black"
            strokeOpacity="0.3"
            strokeWidth="1.06"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
const BluetoothIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={BluetoothSvg} {...props} />;
export default BluetoothIcon;
