import React, { useState } from 'react';
import 'components/custom-header/custom-header.scss';
import { Typography } from 'antd';
const { Title } = Typography;

export interface CustomHeaderProps {
    children?: React.ReactNode;
}

const CustomHeader: React.FC<CustomHeaderProps> = (props) => {
    // State

    return (
        <Title level={3} className={'custom-header'} style={{ margin: 0 }}>
            {props.children}
        </Title>
    );
};

export default CustomHeader;
