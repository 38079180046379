import { useEffect, useState } from 'react';
import { RfidCardDetail, RfidCardDetailController } from 'fragments/rfidcards/fragments/rfid-card-detail/interfaces';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import {
    GetRfidCardDetail,
    RfidCardDeviceCustomFieldInterface,
    RfidCardDto,
} from 'services/rfidcards/rfidcards.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { onDeleteCard, onRetryCardConfig } from 'mobile/rfid/rfid-functions';
import { useDowFunctions } from 'tools/dow-functions';
import { MainError } from 'services/dtos/errors-dto';

export const useRfidCardDetailController = (
    rdifCardServices = useAPIRfidcard(),
): /* <--Dependency Injections  like services hooks */
RfidCardDetailController => {
    const { getKeySchedules } = useDowFunctions();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { state } = useLocation();

    /* State */
    const [rfidCardDetails, setRfidCardDetails] = useState<RfidCardDetail | undefined>(undefined);
    const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
    const [errorDetails, setErrorDetails] = useState<boolean>(false);

    const rfidCardId = searchParams.get('rfid_card_id')?.toLowerCase() || state?.rfid_card_id || '';
    const accessToken = searchParams.get('access_token') || state?.accessToken || '';

    /* Listeners */
    useEffect(() => {
        if (accessToken && rfidCardId) {
            getRfidCardDetails({ accessToken, rfidCardId });
        } else {
            navigate('/mobile/404');
        }
    }, [accessToken, rfidCardId]);

    /* View Events */
    const onDeleteButtonPressed = (id: string, cardNumber: string) => {
        onDeleteCard({ v: 3, data: { card_device_id: id, card_number: cardNumber } });
    };
    const onRetryCardConfigButtonPressed = () => {
        if (rfidCardDetails) {
            onRetryCardConfig({
                v: 3,
                data: {},
            });
        }
    };

    /* Private Methods */
    const getRfidCardDetails = ({ accessToken, rfidCardId }: GetRfidCardDetail) => {
        setIsLoadingDetails(true);
        rdifCardServices
            .getRfidCardDetail({ accessToken, rfidCardId })
            .then((data) => {
                setRfidCardDetails(mapDtoToDetail(data));
            })
            .catch((err) => {
                const error = err as MainError;
                if (error.code === 409) {
                    switch (error.msg) {
                        case 'deleted':
                            setErrorDetails(true);
                            break;
                        default:
                            navigate('/mobile/404');
                            break;
                    }
                }
            })
            .finally(() => {
                setIsLoadingDetails(false);
            });
    };

    const mapDtoToDetail = (dto: RfidCardDto): RfidCardDetail => {
        const viewModelObject: RfidCardDetail = {
            id: dto.id,
            label: capitalize(dto.label), // label / User email
            validity: {
                from: dayjs(dto.nbf_date).format('DD/MM/YY'),
                to: dayjs(dto.expires_at_date).format('DD/MM/YY'),
                fromHour: dayjs(dto.nbf_time).format('HH:mm'),
                toHour: dayjs(dto.expires_at_time).format('HH:mm'),
                timeZone: dto.time_zone,
            },
            state: dto.state,
            createdByEmail: dto.created_by_email,
            deviceName: capitalize(dto.device_name),
            cardNumber: dto.card_number,
            createdAt: `${dayjs(dto.created_at).format('DD/MM/YY HH:mm')} (${dto.time_zone})`,
            keySchedules: getKeySchedules(dto.dow),
            typeId: dto.card_type_id,
            customFields: dto.custom_fields ? parseCustomFields(dto.custom_fields) : [],
        };

        return viewModelObject;
    };

    const parseCustomFields = (fields: RfidCardDeviceCustomFieldInterface[]): RfidCardDeviceCustomFieldInterface[] => {
        return fields.map((field) => {
            switch (field.type_id) {
                case 2:
                    return {
                        ...field,
                        value: `${dayjs(field.value).format('DD/MM/YYYY')}  ${dayjs(field.value).format('HH:mm')}`,
                    };
                case 3:
                    return {
                        ...field,
                        value: JSON.parse(field.value).join(', '),
                    };
                default:
                    return field;
            }
        });
    };
    // Return state and events
    return { rfidCardDetails, isLoadingDetails, errorDetails, onDeleteButtonPressed, onRetryCardConfigButtonPressed };
};
