import React from 'react';
import 'fragments/keys/fragments/keys-detail/keys-detail.scss';
import { Button, Col, Divider, Modal, PageHeader, Row, Space, Spin, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import ErrorComponent from 'components/error-component/error-component.component';
import { ClockCircleOutlined, CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { KeysDetailFragmentProps } from './interfaces';
import { useKeysDetailController } from './keys-detail.controller';
const { Text } = Typography;
const { confirm } = Modal;

export const KeysDetailFragment: React.FC<KeysDetailFragmentProps> = (props) => {
    const { useController = useKeysDetailController } = props;
    const { isLoadingDetails, errorDetails, keysDetails, onDeleteButtonPressed } = useController();
    const { translate } = useTranslator();

    const showConfirmDeleteModal = (id: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                onDeleteButtonPressed(id);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    // Render
    return (
        <div className={'keys-detail'}>
            {!isLoadingDetails && keysDetails && (
                <>
                    <PageHeader className="keys-details-header" title={keysDetails.label} />
                    <div className="keys-details-container">
                        <Space direction="vertical">
                            <h3 className="keys-details-container-label">
                                {translate({ key: 'label.site' })}: <strong>{keysDetails.siteName}</strong>
                            </h3>
                            <h3 className="keys-details-container-label">
                                {translate({ key: 'label.lock' })}: <strong>{keysDetails.lockName}</strong>
                            </h3>
                            <h3 className="keys-details-container-label">
                                {translate({ key: 'label.state' })}:{' '}
                                {keysDetails?.state === 0 ? (
                                    <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                        {translate({ key: 'general.invalid' })}
                                    </Tag>
                                ) : keysDetails?.state === 1 ? (
                                    <Tag color="success" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                        {translate({ key: 'general.valid' })}
                                    </Tag>
                                ) : (
                                    keysDetails?.state === 2 && (
                                        <Tag color="default" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                            {translate({ key: 'general.inactive' })}
                                        </Tag>
                                    )
                                )}
                            </h3>
                            <h3 className="keys-details-container-label">
                                {translate({ key: 'label.creator' })}: <strong>{keysDetails.creatorName}</strong>
                            </h3>
                            <h3 className="keys-details-container-label">
                                {translate({ key: 'label.created-at' })}: <strong>{keysDetails?.createdAt}</strong>
                            </h3>
                        </Space>

                        <Divider style={{ margin: '1rem 0' }} />

                        <Row justify="space-between">
                            {keysDetails.keySchedules.map((keyOption) => {
                                return (
                                    <Col
                                        xs={3}
                                        key={keyOption.key}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Tag
                                            className="keys-details-schedule-tag"
                                            style={{
                                                color: keyOption.selected ? '#777' : '#aaa',
                                                backgroundColor: keyOption.selected
                                                    ? 'rgba(159, 151, 189, 0.2)'
                                                    : '#eeea',
                                            }}
                                            key={keyOption.key}
                                        >
                                            {keyOption.label}
                                        </Tag>
                                    </Col>
                                );
                            })}
                        </Row>
                        <div className="keys-details-container-tags">
                            <Tag
                                icon={<ClockCircleOutlined />}
                                className="keys-details-tag"
                            >{`${keysDetails.validity.fromHour} > ${keysDetails.validity.toHour} (${keysDetails.validity.timeZone})`}</Tag>
                            <Tag
                                icon={<CalendarOutlined />}
                                className="keys-details-tag"
                            >{`${keysDetails.validity.from} > ${keysDetails.validity.to}`}</Tag>
                        </div>
                        <div style={{ paddingTop: '1rem', display: 'flex', gap: '1rem' }}>
                            <Button
                                disabled={keysDetails?.state === 0}
                                className="keys-details-container-button"
                                style={{ color: keysDetails?.state !== 0 ? '#f23d4f' : '#d9d9d9' }}
                                onClick={() => showConfirmDeleteModal(keysDetails.id)}
                            >
                                {translate({ key: 'general.delete' })}
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {isLoadingDetails && (
                <div className="loading-spiner">
                    <Spin />
                </div>
            )}
            {errorDetails && <ErrorComponent />}
        </div>
    );
};
