import React, { useEffect, useState } from 'react';
import 'components/list-header-search-and-sort/list-header-search-and-sort.scss';
import { Button, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SortIcon from 'assets/icons/sort-icon';
import AddKeyIcon from 'assets/icons/add-key-icon';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Sort } from 'global/interfaces';

const { Option } = Select;
const { Search } = Input;

export interface OrderOption {
    id: number;
    value: string;
    sort: Sort;
}

export interface ListHeaderSearchAndSortProps {
    onSearchChange?: (value: string) => void;
    onSort?: (sortData: Sort) => void;
    onLoaderSearch?: (value: boolean) => void;
    onAddButtonPressed?: () => void;
    giveSortFocus?: (value: boolean) => void;
    orderOptions: OrderOption[];
    icon?: React.ReactNode;
}

const ListHeaderSearchAndSort: React.FC<ListHeaderSearchAndSortProps> = (props) => {
    const { translate } = useTranslator();

    // State
    const [sortFocused, setSortFocused] = useState<boolean>(false);
    const [selectSortId, setSelectSortId] = useState<number>(0);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if (!!props.giveSortFocus) {
            props.giveSortFocus(sortFocused);
        }
    }, [sortFocused]);

    const onSortSelect = (value: number) => {
        setSelectSortId(value);
        setSortFocused(false);
        const sort: Sort = getSortValueByOptionId(value);
        props.onSort && props.onSort(sort);
    };

    const debounceSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 2) {
            props.onLoaderSearch && props.onLoaderSearch(true);
            processChange(e.target.value);
        } else {
            if (search.length > 2) {
                props.onLoaderSearch && props.onLoaderSearch(true);
                processChange('');
            }
            setSearch(e.target.value);
        }
    };

    const onSortFocused = () => {
        setSortFocused(!sortFocused);
    };

    const onAddPressed = () => {
        props.onAddButtonPressed && props.onAddButtonPressed();
    };

    // private functions
    const saveInput = (str: string) => {
        setSearch(str);
        props.onSearchChange && props.onSearchChange(str);
    };

    const debounce = (func: (str: string) => void, timeout = 500) => {
        let timer: NodeJS.Timeout;
        return (...args: [string]) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, timeout);
        };
    };

    const processChange = debounce(saveInput);

    const getSortValueByOptionId = (id: number): Sort => {
        const defaultSort: Sort = {
            order: 'descend',
            orderBy: 'date',
        };
        const findedSort = props.orderOptions.find((o) => o.id === id);
        return findedSort?.sort || defaultSort;
    };

    return (
        <div className={'list-header-search-and-sort'}>
            <div className={'list-header-actions'}>
                {sortFocused ? (
                    <div className={'list-header-actions-select'}>
                        <Button
                            className={'list-header-actions-sortbtn'}
                            icon={<SearchOutlined style={{ color: '#a9a9a9' }} />}
                        />
                    </div>
                ) : (
                    <Search
                        className={'list-header-actions-search'}
                        placeholder={
                            translate({ key: 'form.placeholder.search' }) +
                            ' ' +
                            translate({ key: 'form.placeholder.min3letters' })
                        }
                        suffix={
                            <SearchOutlined
                                style={{
                                    color: '#d9d9d9',
                                }}
                            />
                        }
                        onChange={debounceSearch}
                    />
                )}
                {sortFocused ? (
                    <Select
                        defaultValue={0}
                        className={'list-header-actions-select'}
                        onChange={onSortSelect}
                        value={selectSortId}
                        onBlur={onSortFocused}
                        autoFocus={true}
                        open={true}
                    >
                        {props.orderOptions?.map((item) => (
                            <Option key={item.id} value={item.id}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {item.value}
                                </div>
                            </Option>
                        ))}
                    </Select>
                ) : (
                    <div className={'list-header-actions-select'}>
                        <Button
                            onClick={onSortFocused}
                            className={'list-header-actions-sortbtn'}
                            icon={<SortIcon style={{ height: '30px' }} />}
                        />
                    </div>
                )}

                <Button
                    type="primary"
                    className={'list-header-actions-givekeybtn'}
                    onClick={onAddPressed}
                    icon={
                        props.icon || (
                            <AddKeyIcon
                                style={{
                                    width: '100%',
                                    height: '30px',
                                    color: '#fff',
                                }}
                            />
                        )
                    }
                ></Button>
            </div>
        </div>
    );
};

export default ListHeaderSearchAndSort;
