/* eslint-disable @typescript-eslint/ban-types */
import { MobileDataInterface } from 'mobile';
import { OnAddCardInput, OnUpdateCardInput } from './rfid-interfaces';

export const onCardIntroductionUnderstood = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onCardIntroductionUnderstood.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onCardIntroductionUnderstood(JSON.stringify(object));
};
export const onAddCard = (object: MobileDataInterface<OnAddCardInput>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onAddCard.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onAddCard(JSON.stringify(object));
};
export const onAddCardButtonPressed = (object: MobileDataInterface<{ device_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onAddCardButtonPressed.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onAddCardButtonPressed(JSON.stringify(object));
};
export const onUpdateCard = (object: MobileDataInterface<OnUpdateCardInput>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onUpdateCard.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onUpdateCard(JSON.stringify(object));
};
export const onAddAnotherCard = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onAddAnotherCard.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onAddAnotherCard(JSON.stringify(object));
};
export const onCancelAddCard = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onCancelAddCard.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onCancelAddCard(JSON.stringify(object));
};
export const onAddCardReady = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onAddCardReady.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onAddCardReady(JSON.stringify(object));
};
export const onRetryCardConfig = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onRetryCardConfig.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onRetryCardConfig(JSON.stringify(object));
};
export const onUpdateCardRetry = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onUpdateCardRetry.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onUpdateCardRetry(JSON.stringify(object));
};
export const onDeleteCard = (object: MobileDataInterface<{ card_device_id: string; card_number: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onDeleteCard.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onDeleteCard(JSON.stringify(object));
};
export const onDeleteCardRetry = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onDeleteCardRetry.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onDeleteCardRetry(JSON.stringify(object));
};
export const onConfirmCardRetry = (object: MobileDataInterface<{}>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onConfirmCardRetry.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onConfirmCardRetry(JSON.stringify(object));
};
export const onViewCardDetail = (object: MobileDataInterface<{ card_id: string }>) => {
    // For IOS
    window.webkit && window.webkit.messageHandlers.onViewCardDetail.postMessage(object);
    // For Android
    window.WebAppInterface && window.WebAppInterface?.onViewCardDetail(JSON.stringify(object));
};
