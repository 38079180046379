import React, { useState } from 'react';
import { SiteDtoSimple } from 'services/sites/sites.service';
import { SitesContext } from './sites.context';

type Props = {
    children?: React.ReactNode;
};

const SitesProvider: React.FC<Props> = (props) => {
    const [accessToken, setAccessToken] = useState<string>('');
    const [selectedSite, setSelectedSite] = useState<SiteDtoSimple>({ id: '', name: '...', time_zone: '' });

    return (
        <SitesContext.Provider
            value={{
                accessToken,
                selectedSite,
                setAccessToken,
                setSelectedSite,
            }}
        >
            {props.children}
        </SitesContext.Provider>
    );
};

export default SitesProvider;
