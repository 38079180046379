import { onInvalidToken } from 'mobile/common-functions';
import { useNavigate } from 'react-router-dom';
import { ErrorDto } from 'services/dtos/errors-dto';

export const useErrorHandler = () => {
    const navigate = useNavigate();
    const errorHandler = (err: any) => {
        if (err.code == 'ERR_NETWORK') navigate('/mobile/500');
        if (err.code == 'ERR_BAD_RESPONSE') navigate('/mobile/500');
        const error = err as ErrorDto;

        if (err.code == 'ERR_BAD_REQUEST') {
            if (error.response.data.code == 401) {
                onInvalidToken({ v: 1, data: {} });
            }
            error.response.data.code == 433 && navigate('/mobile/invalid-session');
            error.response.data.code == 434 && navigate('/mobile/invalid-session');
            err.response.data.status == 404 && navigate('/mobile/500');
            err.response.data.status == 400 && navigate('/mobile/500');
        }
    };
    return errorHandler;
};
