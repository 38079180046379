import {
    CreateKeyInput,
    // KeyDto,
    Keys,
    // PaginatedKeysResponse,
    OverwriteSmartTokenRecordRequest,
    CreateKeySummaryDto,
    KeysListInput,
    PaginatedKeysResponse,
    KeyDto,
    GetKeyDetail,
    BtKeysDeviceDto,
} from 'services/keys/keys.service';
import axios from 'axios';
// import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useErrorHandler } from 'tools/error-handler';

export const useAPIKeys = (): Keys => {
    // const mobileURL = 'https://apitest.accefy.io/api/v6';
    // const [session] = useLocalSession();
    const errorHandler = useErrorHandler();

    const createKey = async (accessToken: string, input: CreateKeyInput) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_WEB_BASE_URL + `/user/smarttokenrecord`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                status: response.status,
                data: { ...response.data, id: response.data.id.toLowerCase() } as CreateKeySummaryDto,
            };
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error;
        }
    };

    const listKeys = async ({
        accessToken,
        sort,
        search,
        pagination,
    }: KeysListInput): Promise<PaginatedKeysResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL +
                    `/user/locks/${search.lockId}/smarttokenrecords?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&order=${sort.order}&orderBy=${sort.orderBy}&search=${search.input !== '' ? search.input : ''}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );

            return {
                total: response.data.total,
                keys: response.data.keys.map((key: KeyDto) => ({ ...key, id: key.id.toLowerCase() })),
                search: search.input,
            };
        } catch (error) {
            errorHandler(error);
            throw error;
        }
    };

    const getKeyDetail = async ({ accessToken, keyId }: GetKeyDetail): Promise<KeyDto> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL + `/user/smarttokenrecords/${keyId}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { ...response.data, id: response.data.id.toLowerCase() };
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error;
        }
    };

    const deleteKey = async (accessToken: string, keyId: string): Promise<string> => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_WEB_BASE_URL + `/user/smarttokenrecords/${keyId}/delete`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { data: response.data.message } as unknown as string;
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error;
        }
    };

    const overwriteKeys = async (accessToken: string, input: OverwriteSmartTokenRecordRequest) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_WEB_BASE_URL + `/user/smarttokenrecord/force`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                status: response.status,
                data: { ...response.data, id: response.data.id.toLowerCase() } as CreateKeySummaryDto,
            };
        } catch (error) {
            errorHandler(error);
            throw error;
        }
    };

    const listDevicesCreateBtKeyView = async (accessToken: string): Promise<BtKeysDeviceDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_DEVICES_BASE_URL + `/bt-keys/v1/create-bt-keys-form-selec`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return response.data as BtKeysDeviceDto[];
        } catch (err: unknown) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    return {
        listKeys,
        getKeyDetail,
        overwriteKeys,
        createKey,
        deleteKey,
        listDevicesCreateBtKeyView,
    };
};
