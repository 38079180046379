import {
    Sites,
    CreateSiteInput,
    SiteDto,
    PaginatedSitesWithDtoSimple,
    PaginatedTimeZones,
    SiteDtoSimple,
} from 'services/sites/sites.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors-dto';
import { PaginationRequest } from 'global/interfaces';

export const useAPISites = (): Sites => {
    const [session] = useLocalSession();
    const listSites = async (
        accessToken: string,
        search: string,
        pagination: PaginationRequest,
    ): Promise<PaginatedSitesWithDtoSimple> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL +
                    `/user/sites?input=${search}&page=${pagination.page}&pageSize=${pagination.pageSize}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                ...response.data,
                sites: response.data.sites.map((site: SiteDtoSimple) => ({ ...site, id: site.id.toLowerCase() })),
            };
        } catch (error) {
            throw error;
        }
    };

    const createSite = async (input: CreateSiteInput): Promise<SiteDto> => {
        try {
            const timeZoneString = input.time_zone.toString();
            const response = await axios.post(
                process.env.REACT_APP_API_MOBILE_BASE_URL + '/user/site',
                { ...input, time_zone: timeZoneString },
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return { ...response.data, id: response.data.id.toLowerCase() } as SiteDto;
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const timeZones = async (): Promise<PaginatedTimeZones> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_MOBILE_BASE_URL + '/user/timezones', {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as PaginatedTimeZones;
        } catch (error) {
            throw error;
        }
    };

    return {
        listSites,
        createSite,
        timeZones,
    };
};
