import React, { useState } from 'react';
import 'components/page-404/page-404.scss';
import { Button, Result } from 'antd';

// export interface Page404Props {}

const Page404: React.FC = () => {
    // State

    return (
        <div className={'page-404'}>
            <Result
                status="404"
                title="404"
                subTitle="Ups, esta página no existe"
                // extra={<Button type="primary">Volver</Button>}
            />
        </div>
    );
};

export default Page404;
