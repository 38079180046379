import { UserProfileDto } from 'auth/services/auth/auth.service';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export interface Session {
    token?: string;
    user_profile?: UserProfileDto;
    selectedSiteId?: string;
}

export const isTokenExpired = (token: string): boolean => {
    const decoded = jwtDecode<JwtPayload>(token);
    const expirationTimestamp = decoded.exp;
    if (!expirationTimestamp) {
        return false;
    }
    const todayTimestamp = Math.floor(Date.now() / 1000);
    return expirationTimestamp < todayTimestamp;
};
