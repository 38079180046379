import React, { useContext } from 'react';

export interface LockAdministrationContextInterface {
    accessToken: string;
    lockId: string;
    setLockId: React.Dispatch<React.SetStateAction<string>>;
    setAccessToken: React.Dispatch<React.SetStateAction<string>>;
}

export const LockAdministrationContext = React.createContext<LockAdministrationContextInterface>({
    lockId: '',
    accessToken: '',
    setAccessToken: () => {
        /* Nothing to do here*/
    },
    setLockId: () => {
        /* Nothing to do here*/
    },
});

export const useLockAdministrationContext = (): LockAdministrationContextInterface => {
    const store = useContext(LockAdministrationContext);
    return store;
};
