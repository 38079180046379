import axios from 'axios';
// import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useErrorHandler } from 'tools/error-handler';

import {
    CheckDuplicatedRfidCard,
    CreateRfidCardBody,
    CreateRfidCardInput,
    CreateRfidCardResponse,
    GetRfidCardDetail,
    PaginatedRfidCardsResponse,
    RfidCardDto,
    RfidCardListDto,
    RfidCards,
    RfidCardsListInput,
    SiteRfidCardDetailResponse,
} from './rfidcards.service';

export const useAPIRfidcard = (): RfidCards => {
    // const [session] = useLocalSession();
    const errorHandler = useErrorHandler();

    const listRfidCards = async ({
        accessToken,
        search,
        pagination,
        sort,
    }: RfidCardsListInput): Promise<PaginatedRfidCardsResponse> => {
        const { deviceId } = search;
        sort = sort || { order: 'descend', orderBy: 'since' }; // since || state || label ??
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL +
                    `/user/devices/${deviceId}/rfid-cards?page=${pagination.page}&pageSize=${pagination.pageSize}&order=${sort.order}&orderBy=${sort.orderBy}&label=${search.input}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                total: response.data.total,
                rfid_cards: response.data.rfid_cards.map((rc: RfidCardListDto) => ({
                    ...rc,
                    id: rc.id.toLowerCase(),
                    device_id: rc.device_id.toLowerCase(),
                })),
                search: search.input,
            } as PaginatedRfidCardsResponse;
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error;
        }
    };

    const getRfidCardDetail = async ({ accessToken, rfidCardId }: GetRfidCardDetail): Promise<RfidCardDto> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL + `/user/rfid-card-device/${rfidCardId}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { ...response.data, id: response.data.id.toLowerCase() };
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const deleteRfidCard = async (accessToken: string, cardId: string): Promise<string> => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL + `/user/rfid-card/${cardId}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { data: response.data.message } as unknown as string;
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error;
        }
    };

    const checkDuplicatedRfidCard = async ({
        deviceId,
        label,
        accessToken,
    }: CheckDuplicatedRfidCard): Promise<void> => {
        try {
            await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL +
                    `/user/devices/${deviceId}/rfid-cards/check?label=${label}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
        } catch (err: any) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getSiteRfidCardDetail = async (
        accessToken: string,
        deviceId: string,
        siteCardId: string,
    ): Promise<SiteRfidCardDetailResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_RFID_BASE_URL + `/user/devices/${deviceId}/rfid-cards/${siteCardId}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return response.data as unknown as SiteRfidCardDetailResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        listRfidCards,
        getRfidCardDetail,
        checkDuplicatedRfidCard,
        deleteRfidCard,
        getSiteRfidCardDetail,
    };
};
