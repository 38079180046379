import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const KeySvg2 = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.90909 5.24316C8.04455 5.24316 9.85681 6.62089 10.5318 8.54315H19V11.8432H17.3636V15.1432H14.0909V11.8432H10.5318C9.85681 13.7654 8.04455 15.1432 5.90909 15.1432C3.19682 15.1432 1 12.928 1 10.1932C1 7.45829 3.19682 5.24316 5.90909 5.24316ZM4.27272 10.1931C4.27272 11.1048 5.00502 11.8431 5.90909 11.8431C6.81317 11.8431 7.54547 11.1048 7.54547 10.1932C7.54547 9.28151 6.81317 8.54315 5.90909 8.54315C5.00502 8.54315 4.27272 9.28147 4.27272 10.1931Z"
            fill="#EAE8ED"
        />
    </svg>
);
const KeyIcon2 = (props: Partial<CustomIconComponentProps>) => <Icon component={KeySvg2} {...props} />;
export default KeyIcon2;
