import React, { useEffect, useState } from 'react';
import 'components/search-header/search-header.scss';
import { Button, Col, Form, Input, Row } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

const { Search } = Input;

export interface SearchHeaderProps {
    onSearch?: (value: string) => void;
    onFocus?: (value: boolean) => void;
    onLoading?: (value: boolean) => void;
}

const SearchHeader: React.FC<SearchHeaderProps> = (props) => {
    const { translate } = useTranslator();
    const [form] = useForm();
    // State
    const [search, setSearch] = useState<string>('');
    const [focus, setFocus] = useState<boolean>();

    // listeners
    useEffect(() => {
        setSearch('');
    }, [focus]);

    // event functions
    const debounceSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 2) {
            props.onLoading && props.onLoading(true);
            processChange(e.target.value);
        } else {
            if (search.length > 2) {
                props.onLoading && props.onLoading(true);
                processChange('');
            }
            setSearch(e.target.value);
        }
    };

    const onFocusChanged = (value: boolean) => {
        if (focus == value) return;
        setFocus(value);
        props.onFocus && props.onFocus(value);
        if (!value) {
            console.log('reset form');
            form.resetFields();
        }
    };

    // private functions
    const saveInput = (str: string) => {
        setSearch(str);
        props.onSearch && props.onSearch(str);
    };

    const debounce = (func: (str: string) => void, timeout = 1000) => {
        let timer: NodeJS.Timeout;
        return (...args: [string]) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, timeout);
        };
    };

    const processChange = debounce(saveInput);

    return (
        <div className={'search-header'}>
            <Row justify="space-between" gutter={1}>
                {focus && (
                    <Col span={1}>
                        <Button
                            type="text"
                            className={'search-header-back'}
                            icon={<ArrowLeftOutlined style={{ fontSize: '1rem' }} />}
                            onClick={() => {
                                onFocusChanged(false);
                            }}
                        />
                    </Col>
                )}
                <Col span={focus ? 22 : 24}>
                    <Form form={form}>
                        <Form.Item name="search" noStyle>
                            <Search
                                className={'search-header-search'}
                                placeholder={
                                    translate({ key: 'form.placeholder.search' }) +
                                    ' ' +
                                    translate({ key: 'form.placeholder.min3letters' })
                                }
                                suffix={
                                    <SearchOutlined
                                        style={{
                                            color: '#d9d9d9',
                                        }}
                                    />
                                }
                                onChange={debounceSearch}
                                onFocus={() => {
                                    onFocusChanged(true);
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default SearchHeader;
