import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-creation-result-info/rfid-card-creation-result-info.scss';
import { RfidCardCreationResultInfoFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-creation-result-info/interfaces';
import { useRfidCardCreationResultInfoController } from 'fragments/rfidcards/fragments/rfid-card-creation-result-info/rfid-card-creation-result-info.controller';
import { Button, Col, Result, Row, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import InfoIcon from 'assets/icons/info-icon';
const { Title, Text } = Typography;

export const RfidCardCreationResultInfoFragment: React.FC<RfidCardCreationResultInfoFragmentProps> = (props) => {
    const { useController = useRfidCardCreationResultInfoController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'rfid-card-creation-result-info'}>
            <Result
                icon={<InfoIcon style={{ width: '80px', height: '80px' }} />}
                title={
                    <Title className={'rfid-card-creation-result-info-title'}>
                        {translate({ key: 'rfid-card.summary-title-create-info' })}
                    </Title>
                }
                subTitle={
                    <Text className={'rfid-card-creation-result-info-subtitle'}>
                        {translate({ key: 'rfid-card.summary-text-create-info' })}
                    </Text>
                }
                extra={
                    <Row justify={'center'} style={{ width: '100%' }}>
                        <Col span={11}>
                            <Button
                                className={'rfid-card-creation-result-info-btns-item'}
                                type="primary"
                                key="console"
                                onClick={controller.onRetryButtonPressed}
                            >
                                {translate({ key: 'general.retry' })}
                            </Button>
                        </Col>
                    </Row>
                }
            />
        </div>
    );
};
