import React from 'react';
import 'fragments/keys/fragments/keys-list/keys-list.scss';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { List, Skeleton, Tag, Typography } from 'antd';
import { InfoCircleFilled, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { KeysListFragmentProps } from './interfaces';
import { useKeysListController } from './keys-list.controller';
import KeyIcon2 from 'assets/icons/key-icon-2';
import ListHeaderSearchAndSort from 'components/list-header-search-and-sort/list-header-search-and-sort.component';

const { Text } = Typography;

export const KeysListFragment: React.FC<KeysListFragmentProps> = (props) => {
    const { useController = useKeysListController } = props;
    const { translate } = useTranslator();
    const controller = useController();

    // Render
    return (
        <div className={'keys-list'} style={{ minHeight: controller.isFullSize ? '100vh' : undefined }}>
            <ListHeaderSearchAndSort
                onSearchChange={controller.onSearch}
                onSort={controller.onSortSelect}
                onLoaderSearch={controller.changeLoadingList}
                onAddButtonPressed={controller.onGiveKeyButtonPressed}
                giveSortFocus={controller.giveSortFocus}
                orderOptions={controller.orderOptions}
            />
            <div
                id="scrollableDiv"
                className={'keys-list-scrollcontainer'}
                style={{
                    height: controller.isFullSize ? 'calc(100vh - 65px)' : 'calc(100vh - 193px)',
                }}
            >
                <InfiniteScroll
                    dataLength={controller.keysList.length}
                    next={controller.loadMoreData}
                    hasMore={controller.keysList.length < controller.totalKeys}
                    loader={<Skeleton style={{ paddingLeft: '2.4rem' }} paragraph={{ rows: 1 }} active />}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        loading={{
                            spinning: controller.isLoadingList,
                        }}
                        itemLayout="horizontal"
                        dataSource={controller.keysList}
                        renderItem={(item) => (
                            <List.Item
                                onClick={() => {
                                    controller.onKeyItemPressed(item.id);
                                }}
                                actions={[
                                    item.state.id === 2 ? (
                                        <CheckCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ) : item.state.id === 1 ? (
                                        <InfoCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ) : (
                                        <CloseCircleFilled
                                            key="status"
                                            style={{
                                                color: item.state.color,
                                                fontSize: '25px',
                                            }}
                                        />
                                    ),
                                ]}
                            >
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    avatar={
                                        <KeyIcon2
                                            style={{
                                                marginLeft: '3px',
                                                width: '20px',
                                                height: '20px',
                                                color: '#888',
                                            }}
                                        />
                                    }
                                    title={
                                        <Text strong style={{ fontSize: '1rem' }}>
                                            {item.label}
                                        </Text>
                                    }
                                    description={
                                        <div style={{ width: 'fit-content' }}>
                                            <Tag className={'keys-list-scrollcontainer-tag'}>
                                                {item.validity.daysTag}
                                            </Tag>
                                            <Tag className={'keys-list-scrollcontainer-tag'}>
                                                {`${item.validity.fromHour} - ${item.validity.toHour} (${item.validity.timeZone})`}
                                            </Tag>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
