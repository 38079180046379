import React, { useContext } from 'react';
import { SiteDtoSimple } from 'services/sites/sites.service';

export interface SitesContextInterface {
    selectedSite: SiteDtoSimple;
    accessToken: string;
    setSelectedSite: React.Dispatch<React.SetStateAction<SiteDtoSimple>>;
    setAccessToken: React.Dispatch<React.SetStateAction<string>>;
}

export const SitesContext = React.createContext<SitesContextInterface>({
    selectedSite: { id: '', name: '...', time_zone: '' },
    accessToken: '',
    setAccessToken: () => {
        /* Nothing to do here*/
    },
    setSelectedSite: () => {
        /* Nothing to do here*/
    },
});

export const useSitesContext = (): SitesContextInterface => {
    const store = useContext(SitesContext);
    return store;
};
