import React from 'react';
import 'fragments/sites/fragments/lock-list/lock-list.scss';
import { LockListFragmentProps } from 'fragments/sites/fragments/lock-list/interfaces';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, List, Row, Skeleton, Typography } from 'antd';
import { useLockListController } from './lock-list.controller';
import CustomHeader from 'components/custom-header/custom-header.component';
import { CaretRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const LockListFragment: React.FC<LockListFragmentProps> = (props) => {
    const { useController = useLockListController } = props;
    const controller = useController();

    // Render
    return (
        <div className={'lock-list'}>
            {!controller.isLoadingLocks && (
                <CustomHeader>
                    {controller.siteName.length ? (
                        controller.siteName
                    ) : (
                        <Skeleton paragraph={false} style={{ height: '2rem' }} active />
                    )}
                </CustomHeader>
            )}
            <div id="scrollableResult" className={'lock-list-scrollcontainer'}>
                <InfiniteScroll
                    dataLength={controller.locks.list.length}
                    next={controller.loadMoreData}
                    hasMore={controller.locks.list.length < controller.locks.totalItems}
                    loader={controller.isLoadingLocks ? <Skeleton title={true} paragraph={false} active /> : null}
                    scrollableTarget="scrollableResult"
                >
                    <List
                        loading={{
                            spinning: controller.isLoadingLocks,
                            style: { position: 'fixed', top: '80px' },
                        }}
                        itemLayout="horizontal"
                        dataSource={controller.locks.list}
                        renderItem={(item) => (
                            <List.Item className={'lock-list-card-listitem'}>
                                <Card
                                    className={'lock-list-card'}
                                    onClick={() => {
                                        controller.onLockItemPressed(item.lockId);
                                    }}
                                >
                                    <Row justify="space-between" align="middle">
                                        <Text className={'lock-list-card-text'} style={{ fontSize: '1rem' }}>
                                            {item.lockName}
                                        </Text>
                                        <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
